import React from 'react'
import {useTranslation} from 'react-i18next'
import ReactModal from 'react-modal'

import './styles.scss'

interface ITyresHelpProps {
    isModalOpen: boolean,
    setIsModalOpen: (isModalOpen: boolean) => void,
}

const TyresHelpModal: React.FC<ITyresHelpProps> = ({isModalOpen, setIsModalOpen}) => {
  const {t} = useTranslation()
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: 'auto',
      border: 'none',
      padding: '0',
      overflow: 'hidden',
    },
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isModalOpen}
      style={customStyles} 
      overlayClassName="tyres-modal-overlay"
    >
      <div className="tyres-modal">
        <div className="tyres-left-container">
          <div className="tyres-modal-header">
            <h3 className="tyres-modal-title">{t('HOW_TO_READ_TYRE')}</h3>
        
          </div>
          <div className="tyres-modal-body">
            <div className="tyres-modal-body-content">
              <div className="tyre-stat-item">
                <h5 className="tyre-stat">W</h5>
                <p className="tyre-stat-description">
                  the tyre&#39;s speed rating
                </p>
              </div>
              <div className="tyre-stat-item">
                <h5 className="tyre-stat">19</h5>
                <p className="tyre-stat-description">
                  the tyre&#39;s load index
                </p>
              </div>
              <div className="tyre-stat-item">
                <h5 className="tyre-stat">18</h5>
                <p className="tyre-stat-description">
                  the tyre&#39;s diameter
                </p>
              </div>
              <div className="tyre-stat-item">
                <h5 className="tyre-stat">55</h5>
                <p className="tyre-stat-description">
                  the tyre&#39;s height
                </p>
              </div>
              <div className="tyre-stat-item">
                <h5 className="tyre-stat">205</h5>
                <p className="tyre-stat-description">
                  the tyre&#39;s width
                </p>
              </div>
              <div className="tyres-modal-footer">
                {t('DIFFERENT_TYRES_NOTE')}
              </div>
            </div>
          </div>
        </div>
        <div className="tyres-right-container">
            
          <img className="tyres-help-image" src="/images/tyres-help.png" alt="tyre" />
          <button type="button" className="tyres-modal-close" onClick={() => setIsModalOpen(false)}>
            <i className="fas fa-times" />
          </button>
          <img src="/images/tyres-help-arrow-1.svg" alt="arrow" className="tyres-stats-arrow-1" />
          <img src="/images/tyres-help-arrow-2.svg" alt="arrow" className="tyres-stats-arrow-2" />
          <img src="/images/tyres-help-arrow-3.svg" alt="arrow" className="tyres-stats-arrow-3" />
          <img src="/images/tyres-help-arrow-4.svg" alt="arrow" className="tyres-stats-arrow-4" />
          <img src="/images/tyres-help-arrow-5.svg" alt="arrow" className="tyres-stats-arrow-5" />
          <svg viewBox="0 0 385 385" className="tyres-curved-stats">
            <path id="curve" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
            <text width="500">
              <textPath xlinkHref="#curve">
                205 55 18 19W
              </textPath>
            </text>
          </svg>
        </div>

      </div>
    </ReactModal>
  )
}

export default TyresHelpModal
