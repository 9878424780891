import React from 'react'
import ProgressBar from './components/bar'
import ProgressCircular from './components/circular'
import './style.scss'

interface ProgressProps {
  percentage: number;
  type: 'circular' | 'bar';
}

const Progress: React.FC<ProgressProps> = ({percentage, type}: ProgressProps): JSX.Element => {
  const percentageToInt: number = Math.floor(Math.max(0, Math.min(Number(percentage), 100)))

  return (
    <React.Fragment>
      {
        type === 'bar' && <ProgressBar percentage={percentageToInt} />
      }
      {
        type === 'circular' && <ProgressCircular percentage={percentageToInt} />
      }
    </React.Fragment>
  )
}

export default Progress
