import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './styles.scss'
import axios, {Canceler} from 'axios'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {fetchResetOilFilters} from 'store/actions/reliable/oilSearchActions'
import {useOnClickOutside} from '../../../../utils/hooks'
import Spinner from '../../../../components/Spinner'
import {fetchSearchReliableOils} from '../../../../api/reliable/search'

import {IOilSearch} from '../../../../store/types/oilTypes'
import {converObjectToParams, getCurrentDomain} from '../../../../utils/getData'
import {handleOilFullSearch} from '../../../../utils/oils'

interface ISearch {
    activeButtonIndex: string;
}

interface IOil {
    brand_id: number,
    brand_name: string,
    category_id: number,
    category_name: string,
    family: string,
    quantity: number,
    reference: number,
    unity: string,
    viscosity: string,
    oil_id: number,
    image: string
}

const CancelToken = axios.CancelToken
let cancel: Canceler | undefined

const OilSearch: React.FC<ISearch> = ({activeButtonIndex}): JSX.Element => {
  const [carReference, setCarReference] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const refReference = useRef() as React.MutableRefObject<HTMLInputElement>
  const {t} = useTranslation()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const [suggestions, setSuggestions] = useState<IOil[]>([])

  useOnClickOutside(refReference, () => setShowSuggestions(false))

  const submitHandler = (e: any) => {
    e.preventDefault()
    getOils({q: inputRef?.current?.value})
  }
  const clickReferenceHandler = (oil: IOil) => {
    const data: IOilSearch = {
      q: inputRef?.current?.value,
      'category_ids[]': [String(oil.category_id)],
      'viscosity[]': [],
      'family[]': [],
      'quantity[]': [],
      'brand_id[]': [],
    }

    if (oil.quantity) {
      data['quantity[]']?.push(String(oil.quantity))
    } else {
      delete data['quantity[]']
    }

    if (oil.brand_id) {
      data['brand_id[]']?.push(String(oil.brand_id))
    } else {
      delete data['brand_id[]']
    }

    if (oil.family) {
      data['family[]']?.push(oil.family)
    } else {
      delete data['family[]']
    }

    if (oil.viscosity) {
      data['viscosity[]']?.push(oil.viscosity)
    } else {
      delete data['viscosity[]']
    }
    dispatch(fetchResetOilFilters())
    handleOilFullSearch(data, oil, dispatch)

    history.push({
      pathname: '/reliable/oil/search',
      search: `?${converObjectToParams(data)}`,
      key: 'SearchReference',
    })
    setShowSuggestions(false)
    setCarReference('')
  }

  const clickCategoryHandler = (oil: IOil) => {
    const data: IOilSearch = {
      q: inputRef?.current?.value,
      'category_ids[]': [String(oil.category_id)],
    }

    // if (oil.category_id) {
    //   data['category_id[]']?.push(String(oil.category_id))
    // } else {
    //   delete data['category_id[]']
    // }

    handleOilFullSearch(data, null, dispatch)

    history.push({
      pathname: '/reliable/oil/search',
      search: `?${converObjectToParams(data)}`,
      key: 'SearchReference',
    })
    setShowSuggestions(false)
    setCarReference('')
  }

  const getOils = (listQuery: any) => {
    setShowSuggestions(true)
    setShowSpinner(true)
    if (cancel !== undefined) {
      cancel()
    }

    fetchSearchReliableOils(listQuery, {
      cancelToken: new CancelToken((c => {
        cancel = c
      })),
    })
      .then(({data}) => {
        setSuggestions(data)
        setShowSpinner(false)
      }).catch(() => {
        console.log('cancel')
      })
  }

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim()
    setCarReference(inputData)
    if (inputData.length >= 6) {
      getOils({q: inputData})
    } else {
      setShowSpinner(false)
      setShowSuggestions(false)
      setSuggestions([])
    }
  }
  const clearCarReference = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setCarReference('')
    setShowSuggestions(false)
    setSuggestions([])
  }

  return (
    <div
      ref={refReference}
      className={activeButtonIndex !== 'oil' ? 'd-none' : 'oil-reference-container'}
    >
      <div className="oil-search">
        <form
          className="search-form custom-search-form"
          onSubmit={e => submitHandler(e)}
        >

          <div
            className="inner-search-container"
          >
            <div
              className="search-container"
            >
              <input
                ref={inputRef}
                className="search-header form-control"
                type="text"
                placeholder={t('SEARCH_BY_OIL')}
                aria-label="Search"
                value={carReference}
                onChange={updateSearchValue}
              />
              {carReference && (
                <button
                  className="close-icon"
                  aria-label="Reset"
                  type="reset"
                  onClick={clearCarReference}
                />
              )}

            </div>
            <button
              className={`button-style search-toolbar-button desktop ${carReference ? 'button-style-hover' : 'button-disabled-style'}`}
              type="submit"
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </form>
      </div>
      <div
        className={`result-search  ${suggestions.length ? '' : 'no-result'} ${showSuggestions ? 'block' : 'hidden'}`}
      >

        <div className="wrapper">
          <div className="suggestion-title">{t('SEARCH_OIL_RESULTS')}</div>
        </div>
        <ul className="suggestions">
          {showSpinner
            ? <Spinner type="skeleton-line" />
            : suggestions.length ? suggestions.map((item, index) => (
              <li
                key={index}
                className="suggestion-list-item"
              >

                <div className="suggestion-item-title">
                  <img
                    src={`${getCurrentDomain() ?? ''}${item.image}`}
                    style={{marginRight: '10px'}}
                    width="25"
                    height="25"
                    alt="oil"
                  />
                  <div
                    className="reference-oil-wrapper"
                  >
                    <a
                      href="#"
                      className="reference-oil"
                      onClick={() => clickReferenceHandler(item)}
                    >
                      {item.brand_name ?? item.category_name}
                      {' '}
                      {item.viscosity}
                      {' '}
                      {item.quantity}
                      {item.unity}
                    </a>

                  </div>

                  <div
                    className="category-oil-wrapper"
                  >
                    <span
                      onClick={() => clickCategoryHandler(item)}
                      className="category-oil"
                    >
                      {item.category_name}
                    </span>
                  </div>

                </div>
              </li>
            )) : <div className="no-suggestions">{`${t('NO_OIL_RESULTS')}!`}</div>}
        </ul>
      </div>
    </div>
  )
}

export default OilSearch
