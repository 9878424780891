import {ACTION_FETCH_MANUALLY_SEARCH, ACTION_TOGGLE_MANUALLY_SEARCH, ACTION_UPDATE_MANUALLY_SEARCH} from 'constants/actionsTypes'

export const initialState = {
  showManuallySearch: false,
  manuallySearch: null,
  typeSearch: '',
  loading: false,
}

export default function manufacturesReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_TOGGLE_MANUALLY_SEARCH:
      return {
        ...state,
        showManuallySearch: action.showManuallySearch,
      }

    case ACTION_FETCH_MANUALLY_SEARCH:
      return {
        ...state,
        loading: action.loading,
      }

    case ACTION_UPDATE_MANUALLY_SEARCH:
      return {
        ...state,
        manuallySearch: action.manuallySearch,
        typeSearch: action.typeSearch,
      }

    default:
      return state
  }
}
