import {ITyreActionTypes, ITyreFilter, ITyreResult, ITyreSearchFetchData} from '../../types/tyreTypes'
import {
  ACTION_FETCH_TYRE_FILTERS,
  ACTION_FETCH_TYRE_ITEM,
  ACTION_FETCH_TYRE_SEARCH,
  ACTION_LOADING_TYRE_FILTERS, ACTION_LOADING_TYRE_ITEM,
  ACTION_LOADING_TYRE_SEARCH,
  ACTION_RESET_TYRE_SEARCH,
} from '../../../constants/actionsTypes'

export function fetchTyreSearch(tyreData: ITyreSearchFetchData): ITyreActionTypes {
  return {
    type: ACTION_FETCH_TYRE_SEARCH,
    payload: tyreData,
  }
}

export function loadingTyreSearch(loading: boolean): ITyreActionTypes {
  return {
    type: ACTION_LOADING_TYRE_SEARCH,
    payload: loading,
  }
}

export function resetTyreSearch(): ITyreActionTypes {
  return {
    type: ACTION_RESET_TYRE_SEARCH,
    payload: null,
  }
}

export function fetchTyreFilters(filters: ITyreFilter): ITyreActionTypes {
  return {
    type: ACTION_FETCH_TYRE_FILTERS,
    payload: filters,
  }
}

export function loadingTyreFilters(loading: boolean): ITyreActionTypes {
  return {
    type: ACTION_LOADING_TYRE_FILTERS,
    payload: loading,
  }
}

export function fetchTyreItem(tyre: ITyreResult): ITyreActionTypes {
  return {
    type: ACTION_FETCH_TYRE_ITEM,
    payload: tyre,
  }
}

export function loadingTyreItem(loading: boolean): ITyreActionTypes {
  return {
    type: ACTION_LOADING_TYRE_ITEM,
    payload: loading,
  }
}
