import request from 'utils/request'

export function fetchUser() {
  return request({
    url: '/user/info',
    method: 'get',
  })
}

export function updateUser(data) {
  return request({
    url: '/user',
    method: 'put',
    data,
  })
}

export function autologinToEinaVTS() {
  return request({
    url: '/eina/authVTS',
    method: 'get',
  })
}
