import request from 'utils/request'

export function fetchFeedback() {
  return request({
    url: '/feedback',
    method: 'get',
  })
}

export function createFeedback(data) {
  return request({
    url: '/feedback',
    method: 'post',
    data,
  })
}
