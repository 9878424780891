import request from 'utils/request'

export function fetchReliableCarManufacturers(query) {
  return request({
    url: '/reliable/car-manufacturers',
    method: 'get',
    params: query,
  })
}

export function fetchReliableCarModels(query, {cancelToken}) {
  return request({
    url: '/reliable/car-models',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchReliableCarGeneration(query, {cancelToken}) {
  return request({
    url: '/reliable/car-generation',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchReliableCarFuelType(query, {cancelToken}) {
  return request({
    url: '/reliable/fuel-type',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchReliableCarVersion(query, {cancelToken}) {
  return request({
    url: '/reliable/car-version',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchTcdIdByPlkId(params) {
  return request({
    url: '/reliable/TcdIdByPlkId',
    method: 'get',
    params,
  })
}
