import {ACTION_UPDATE_CATALOG_DATA, ACTION_RESET_CATALOG_DATA, ACTION_UPDATE_LOADING_CATALOG, ACTION_UPDATE_LOADING_PAGE_CATALOG} from 'constants/actionsTypes'

export const initialState = {
  catalogData: {},
  pagination: null,
  loadingCatalog: false,
  loadingPageCatalog: false,
}

export default function catalogReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_CATALOG_DATA:
      return {
        ...state,
        catalogData: {
          data: action.catalogData.data,
          catalog_id: action.catalogData.catalog_id,
        },
        pagination: {
          total: action.catalogData.total_count,
          page: action.catalogData.page,
          per_page: action.catalogData.per_page,
          total_pages: action.catalogData.total_pages,
        },
      }

    case ACTION_RESET_CATALOG_DATA:
      return {
        ...state,
        catalogData: {},
        pagination: null,
        loadingCatalog: false,
        loadingPageCatalog: false,
      }

    case ACTION_UPDATE_LOADING_CATALOG:
      return {
        ...state,
        loadingCatalog: action.loadingCatalog,
      }

    case ACTION_UPDATE_LOADING_PAGE_CATALOG:
      return {
        ...state,
        loadingPageCatalog: action.loadingPageCatalog,
      }

    default:
      return state
  }
}
