import {
  ADMIN_ROLES,
  ALL_ROLES,
  CLIENT_ROLES,
  ROLE_ADV,
  ROLE_PROVIDER,
  ROLE_SELLER,
} from './roles'
import {MARKETPLACE_AUTOMOTOR} from './defaultConstants'

export const MENU_NAV_LINK_AF = [
  {
    liClassName: 'user',
    to: '',
    roles: ALL_ROLES,
    title: '',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'person',
  },
  {
    to: '/',
    roles: ALL_ROLES,
    title: 'HOME',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'home',
  },
  {
    to: '/favorite',
    roles: ALL_ROLES,
    title: 'SAVED_LISTS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'favorite',
  },
  {
    to: '/cart',
    roles: ALL_ROLES,
    marketplace_sensible: MARKETPLACE_AUTOMOTOR,
    title: 'SHOPPING_CART',
    iconClassName: 'material-icons',
    iconName: 'shopping_cart',
  },
]

export const TOOLBAR_MENU_NAV_AF = [
  {
    to: '/orders',
    roles: ALL_ROLES,
    title: 'ORDER_LIST',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons-outlined',
    iconName: 'ballot',
  },
  {
    to: '/settings',
    roles: ALL_ROLES,
    title: 'SETTINGS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'settings',
  },
  {
    to: '',
    rel: 'noopener noreferrer',
    roles: [...CLIENT_ROLES, ...ADMIN_ROLES, ROLE_SELLER, ROLE_PROVIDER, ROLE_ADV],
    title: 'LOG_OFF',
    callback_method: 'handlerLogout',
    iconClassName: 'material-icons',
    iconName: 'exit_to_app',
  },
]
