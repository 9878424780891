import {ACTION_UPDATE_VEHICLE_CATEGORIES, ACTION_UPDATE_VEHICLE_SUBCATEGORIES, ACTION_GET_VEHICLE_CATEGORIES_BY,
  ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED, ACTION_SET_VEHICLE_ID} from 'constants/actionsTypes'
import {fetchReliableVehicleCategories, fetchReliableVehicleSubcategories} from 'api/reliable/vehicle-categories'

export const fetchVehicleCategories = data => dispatch => {
  const {
    source,
    vehicle_id,
    vin,
    registration_plate,
    registration_country,
    vehicle_identifier,
  } = data

  dispatch({type: ACTION_GET_VEHICLE_CATEGORIES_BY})

  fetchReliableVehicleCategories(vehicle_id, {
    ...(source && {source}),
    ...(vin && {vin}),
    ...(registration_plate && {registration_plate}),
    ...(registration_country && {registration_country}),
    ...(vehicle_identifier && {vehicle_identifier}),
  })
    .then(response => {
      dispatch({
        type: ACTION_UPDATE_VEHICLE_CATEGORIES,
        vehicleCategories: response.data,
        vehicleId: vehicle_id,
      })
    }).catch(() => {
      dispatch({
        type: ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED,
      })
    })
}

export function resetVehicleCategories() {
  return {
    type: ACTION_UPDATE_VEHICLE_CATEGORIES,
    vehicleCategories: null,
  }
}

export function fetchVehicleSubcategories(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
  } = data

  return dispatch => {
    dispatch({type: ACTION_GET_VEHICLE_CATEGORIES_BY})

    fetchReliableVehicleSubcategories(vehicleId, {
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(source && {source}),
    })
      .then(response => {
        dispatch({
          type: ACTION_UPDATE_VEHICLE_SUBCATEGORIES,
          vehicleSubcategories: response.data.map(el => {
            if (el.data.length > 0) {
              el.itemDate = el.data[0]
              return el
            }
          }),
        })
      })
  }
}

export function resetVehicleSubcategories() {
  return {
    type: ACTION_UPDATE_VEHICLE_SUBCATEGORIES,
    vehicleSubcategories: null,
  }
}

export function setVehicleId(vehicleId) {
  return {
    type: ACTION_SET_VEHICLE_ID,
    vehicleId,
  }
}
