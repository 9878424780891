import i18n from 'i18next'
import axios from 'axios'
import Notification from 'components/Notification'
import {internalServerError,
  badRequestError,
  clearErrorStatus} from 'store/actions/errorActions'
import {logout} from 'store/actions/authActions'
import {getApi} from 'utils/getData'
import history from 'utils/history'
import store from 'store'
import {isHostPunchOut} from './index'
import {badGatewayError,
  unprocessableEntityError} from '../store/actions/errorActions'

const service = axios.create({
  baseURL: getApi(), // url = base url + request url
  timeout: 120000, // request timeout,
})

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    const {errorState} = store.getState()

    if (
      errorState.badRequest
      || errorState.serverError
      || errorState.badGateway
      || errorState.unprocessableEntity
    ) {
      store.dispatch(clearErrorStatus())
    }

    if (config.url.includes('login')) {
      config.baseURL = `${config.baseURL.slice(0, -10)}/api`
    } else {
      config.baseURL = getApi()
    }
    config.headers['Access-Control-Allow-Origin'] = '*'

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  },
)

service.interceptors.response.use(response => {
  if (!response.headers['x-version']) return response
  if (response.headers['x-version'] && !localStorage.getItem('x-version')) {
    localStorage.setItem('x-version', response.headers['x-version'])
  }
  if (localStorage.getItem('x-version') && response.headers['x-version'] !== localStorage.getItem('x-version')) {
    localStorage.setItem('x-version', response.headers['x-version'])
    window.location.reload(true)
    window.location.replace(window.location.origin)
  }
  return response
})

service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error?.response?.status === 401) {
      if (isHostPunchOut()) {
        store.dispatch(logout())
        history.push('/platform-unauthorized')
      } else {
        store.dispatch(logout())

        Notification({
          container: 'top-center',
          message: error.response.data.message,
          type: 'danger',
          title: i18n.t('ERROR'),
          duration: 5000,
        })
      }
    }

    if (error?.response?.status >= 500 && error?.response?.status !== 502) {
      store.dispatch(internalServerError())
    }

    if (
      error?.response?.status === 400
      || (error?.response?.status > 422 && error?.response?.status < 500)
    ) {
      store.dispatch(badRequestError(error.response))
    }

    if (error?.response?.status === 422) {
      store.dispatch(unprocessableEntityError(error?.response?.data))
    }

    if (error?.response?.status === 502) {
      store.dispatch(badGatewayError(error?.response?.data))
    }

    return Promise.reject(error)
  },
)

export default service
