import {AxiosPromise} from 'axios'
import request from '../../utils/request'
import {converObjectToParams} from '../../utils/getData'

export function fetchReliableConsumables(query: any) : AxiosPromise<any> {
  return request({
    url: `/consumable/products?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchConsumableItemView(consumable_id: string) : AxiosPromise<any> {
  return request({
    url: `/consumable/products/${consumable_id}`,
    method: 'get',
  })
}

export function fetchConsumableCategories(vehicleID: string, source: string) : AxiosPromise<any> {
  return request({
    url: `/consumable/vehicle-categories/?vehicle_id=${vehicleID}&source=${source}`,
    method: 'get',
  })
}

export function fetchVehicleListFilters(vehicleID: string, source: string) : AxiosPromise<any> {
  return request({
    url: `/consumable/vehicle-list-filters/?vehicle_id=${vehicleID}&source=${source}`,
    method: 'get',
  })
}

export function fetchReliableConsumableFilter(query: any) : AxiosPromise<any> {
  return request({
    url: `/consumable/filters?${converObjectToParams(query)}`,
    method: 'get',
  })
}
