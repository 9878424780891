import React, {Suspense, lazy, memo} from 'react'
import {Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom'
import {retryPromise as retry} from 'utils/retry_promise'
import Spinner from 'components/Spinner'
import {useTranslation} from 'react-i18next'
import {getURLParams, converObjectToParams} from '../../utils/getData'

const Error404 = lazy(() => retry(() => import('pages/Error/404')))

const ReliableContent = lazy(() => retry(() => import('pages/Reliable/ReliableContent')))

const PrivateRoutesRMIIframe = memo(() => {
  const location = useLocation()
  const urlParams = getURLParams(location.search)
  const {i18n} = useTranslation()
  const history = useHistory()

  if (urlParams?.languageCode) {
    const lang = urlParams?.languageCode
    delete urlParams.languageCode
    history.push({
      search: `?${converObjectToParams(urlParams)}`,
    })
    localStorage.setItem('language', `${lang}-${i18n.language.split('-')[1]}`)
    i18n.changeLanguage(`${lang}-${i18n.language.split('-')[1]}`)
  }

  const checkPathname = () => {
    let router = <Route component={Error404} />

    if (location.pathname === '/' || location.pathname.includes('login')) {
      router = <Redirect to="/" />
    }
    return router
  }

  return (
    <Suspense fallback={<Spinner class="small" />}>
      <Switch>
        <Route path="/reliable/rmi/:vehicle_id/:tab_name" render={() => <ReliableContent isBreadcrumbAvailable={false} />} />
        {checkPathname()}
      </Switch>
    </Suspense>
  )
})

export default PrivateRoutesRMIIframe
