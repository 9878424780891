import {IOilActionTypes, IOilFilter, IOilResult, IOilSearchFetchData} from '../../types/oilTypes'
import {
  ACTION_FETCH_OIL_FILTERS,
  ACTION_FETCH_OIL_ITEM,
  ACTION_FETCH_OIL_SEARCH,
  ACTION_LOADING_OIL_FILTERS, ACTION_LOADING_OIL_ITEM,
  ACTION_LOADING_OIL_SEARCH,
  ACTION_RESET_OIL_FILTERS,
  ACTION_RESET_OIL_SEARCH,
} from '../../../constants/actionsTypes'

export function fetchOilSearch(oilData: IOilSearchFetchData): IOilActionTypes {
  return {
    type: ACTION_FETCH_OIL_SEARCH,
    payload: oilData,
  }
}

export function loadingOilSearch(loading: boolean): IOilActionTypes {
  return {
    type: ACTION_LOADING_OIL_SEARCH,
    payload: loading,
  }
}

export function resetOilSearch(): IOilActionTypes {
  return {
    type: ACTION_RESET_OIL_SEARCH,
    payload: null,
  }
}

export function fetchOilFilters(filters: IOilFilter): IOilActionTypes {
  return {
    type: ACTION_FETCH_OIL_FILTERS,
    payload: filters,
  }
}

export function fetchResetOilFilters(): IOilActionTypes {
  return {
    type: ACTION_RESET_OIL_FILTERS,
    payload: null,
  }
}

export function loadingOilFilters(loading: boolean): IOilActionTypes {
  return {
    type: ACTION_LOADING_OIL_FILTERS,
    payload: loading,
  }
}

export function fetchOilItem(oil: IOilResult): IOilActionTypes {
  return {
    type: ACTION_FETCH_OIL_ITEM,
    payload: oil,
  }
}

export function loadingOilItem(loading: boolean): IOilActionTypes {
  return {
    type: ACTION_LOADING_OIL_ITEM,
    payload: loading,
  }
}
