import {AxiosResponse} from 'axios'
import {Dispatch} from 'redux'
import {ITyre, ITyreFilter, ITyreSearch, IPagination} from '../store/types/tyreTypes'
import {fetchTyreFilters,
  fetchTyreSearch,
  loadingTyreFilters,
  loadingTyreSearch} from '../store/actions/reliable/tyreSearchActions'
import {fetchReliableTyreFilter, fetchReliableTyres} from '../api/reliable/search'
import {converObjectToParams, getURLParamsArray} from './getData'

export const handleTyresFullSearch = (data: ITyreSearch, tyre: ITyre | null, dispatch:Dispatch<any>) => {
  handleTyresDataSearch(data, tyre, dispatch)
  handleTyresFilterSearch(data, tyre, dispatch)
}

export const handleTyresDataSearch = (data: ITyreSearch, tyre: ITyre | null, dispatch:Dispatch<any>) => {
  dispatch(loadingTyreSearch(true))
  fetchReliableTyres(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<IPagination[]>) => {
      dispatch(fetchTyreSearch({tyre, data}))
      dispatch(loadingTyreSearch(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingTyreSearch(false))
    })
}

export const handleTyresFilterSearch = (data: ITyreSearch, tyre: ITyre | null, dispatch:Dispatch<any>) => {
  dispatch(loadingTyreFilters(true))
  fetchReliableTyreFilter(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<ITyreFilter>) => {
      dispatch(fetchTyreFilters(data))
      dispatch(loadingTyreFilters(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingTyreFilters(false))
    })
}
