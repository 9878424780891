import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './styles.scss'
import axios, {Canceler} from 'axios'
import {NavLink, useHistory} from 'react-router-dom'
import {fetchSearchReliableBattery} from 'api/reliable/search'
import {useOnClickOutside} from 'utils/hooks'
import Spinner from 'components/Spinner'

import {converObjectToParams, getCurrentDomain} from 'utils/getData'
import {IBatterySearch} from 'store/types/battery'

interface ISearch {
    activeButtonIndex: string
}

interface IBattery {
    reference: string;
    description: string;
    size: string;
    battery_id:number;
    brand_image: string;
    image: string;
    brand_id: number;
    brand_name:string;
    ah:string;
    amp:string;
    category_name:string;
    category_id:number;
    category_image:string;
}

const CancelToken = axios.CancelToken
let cancel: Canceler | undefined

const BatterySearch: React.FC<ISearch> = ({activeButtonIndex}): JSX.Element => {
  const [carReference, setCarReference] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const refReference = useRef() as React.MutableRefObject<HTMLInputElement>
  const {t} = useTranslation()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const history = useHistory()
  const [suggestions, setSuggestions] = useState<IBattery[]>([])

  useOnClickOutside(refReference, () => setShowSuggestions(false))

  const submitHandler = (e: any) => {
    e.preventDefault()
    getBattery({q: inputRef?.current?.value})
  }
  const clickReferenceHandler = (battery: IBattery) => {
    const data: IBatterySearch = {
      q: inputRef?.current?.value,
      battery_id: String(battery.battery_id),
      'brand_ids[]': [String(battery.brand_id)],
      'ah[]': [String(battery.ah)],
      'amp[]': [String(battery.amp)],
      'category_ids[]': [String(battery.category_id)],
    }

    history.push(`/reliable/battery/search?tab=battery${converObjectToParams(data)}`)
    setShowSuggestions(false)
    setCarReference('')
  }

  const generateReferenceSearchLink = (battery:IBattery) => {
    const data: IBatterySearch = {
      q: inputRef?.current?.value,
      battery_id: String(battery.battery_id),
      'brand_ids[]': [String(battery.brand_id)],
      'ah[]': [String(battery.ah)],
      'amp[]': [String(battery.amp)],
      'category_ids[]': [String(battery.category_id)],
    }
    return `/reliable/battery/search?tab=battery&${converObjectToParams(data)}`
  }

  const clickBrandHandler = (battery: IBattery) => {
    const data: IBatterySearch = {
      q: inputRef?.current?.value,
      'category_ids[]': [String(battery.category_id)],
    }

    history.push(`/reliable/battery/search?tab=battery${converObjectToParams(data)}`)
    setShowSuggestions(false)
    setCarReference('')
  }

  const getBattery = (listQuery: any) => {
    setShowSuggestions(true)
    setShowSpinner(true)
    if (cancel !== undefined) {
      cancel()
    }

    fetchSearchReliableBattery(listQuery, {
      cancelToken: new CancelToken((c => {
        cancel = c
      })),
    })
      .then(({data}) => {
        setSuggestions(data)
        setShowSpinner(false)
      }).catch(() => {
        console.log('cancel')
      })
  }

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim()
    setCarReference(inputData)
    if (inputData.length >= 6) {
      getBattery({q: inputData})
    } else {
      setShowSpinner(false)
      setShowSuggestions(false)
      setSuggestions([])
    }
  }
  const clearCarReference = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setCarReference('')
    setShowSuggestions(false)
    setSuggestions([])
  }

  return (
    <div
      ref={refReference}
      className={activeButtonIndex !== 'battery' ? 'd-none' : 'battery-reference-container'}
    >
      <div className="battery-search">
        <form
          className="search-form custom-search-form"
          onSubmit={e => submitHandler(e)}
        >

          <div
            className="inner-search-container"
          >
            <div
              className="search-container"
            >
              <input
                ref={inputRef}
                className="search-header form-control"
                type="text"
                placeholder={t('SEARCH_BY_BATTERY')}
                aria-label="Search"
                value={carReference}
                onChange={updateSearchValue}
              />
              {carReference && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearCarReference} />}

            </div>
            <button
              className={`button-style search-toolbar-button desktop ${carReference ? 'button-style-hover' : 'button-disabled-style'}`}
              type="submit"
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </form>
      </div>
      <div
        className={`result-search  ${suggestions.length ? '' : 'no-result'} ${showSuggestions ? 'block' : 'hidden'}`}
      >

        <div className="wrapper">
          <div className="suggestion-title">{t('SEARCH_BATTERY_RESULTS')}</div>
        </div>
        <ul className="suggestions">
          {showSpinner
            ? <Spinner type="skeleton-line" />
            : suggestions.length ? suggestions.map((item, index) => (
              <li
                key={index}
                className="suggestion-list-item"
              >

                <div className="suggestion-item-title">

                  <img
                    src={`${getCurrentDomain() ?? ''}${item.image != 'null' ? item.image : item.category_image}`}
                    style={{marginRight: '10px'}}
                    width="25"
                    height="25" 
                    alt={`${item.image != 'null' ? 'Battery' : 'Category'}`}
                  />

                  <div
                    className="reference-battery-wrapper"
                  >
                    {item.battery_id ? (
                      <NavLink
                        to={generateReferenceSearchLink(item)}
                        onClick={() => clickReferenceHandler(item)}
                      >

                        {item.brand_name}
                        {' '}
                        {item.reference}
                        {' '}
                        {item.ah}
                        AH
                        {' '}
                        {item.amp}
                        Amp
                        {' '}
                        {item.size}
                        
                      </NavLink>
                    ) : null}
                  </div>

                  <div
                    className="category-battery-wrapper"
                  >
                    <span
                      onClick={() => clickBrandHandler(item)}
                      className="category-battery"
                    >
                      {item.category_name}
                    </span>
                  </div>

                </div>
              </li>
            )) : <div className="no-suggestions">{`${t('NO_BATTERY_RESULTS')}!`}</div>}
        </ul>
      </div>
    </div>
  )
}

export default BatterySearch
