import React from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import CatalogFilters from 'pages/Catalog/CatalogFilters'
import Categories from 'pages/Catalog/Categories'
import {getURLParams} from 'utils/getData'

import './style.scss'

const CatalogFiltersSideDrawer = props => {
  const {t} = useTranslation()
  const {catalogInfo} = useSelector(state => state.catalogInfoState)
  const location = useLocation()
  const urlParams = getURLParams(location.search)

  const closeFiltersSideDrawerClickHandler = () => {
    props.backdropClickHandler(false)
  }

  return (
    catalogInfo && catalogInfo?.id === urlParams?.catalog_id ? (
      <div className="catalog-filters-side-drawer open-drawer">
        <div className="filters-wrapper">
          <div className="filters-title">{t('FILTERS')}</div>
          <div className="filters-close-drawer">
            <i
              className="material-icons"
              onClick={closeFiltersSideDrawerClickHandler}
            >
              close
            </i>
          </div>
        </div>

        <div className="filters-container">
          <CatalogFilters closePanel={props.backdropClickHandler} fromSideDrawerMenu={true} />
          <Categories closePanel={props.backdropClickHandler} fromSideDrawerMenu={true} />
        </div>
      </div>
    ) : null
  )
}

export default CatalogFiltersSideDrawer
