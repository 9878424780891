import {getQuickFilters} from 'api/tyres'
import Spinner from 'components/Spinner'
import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useOnClickOutside} from 'utils/hooks'
import './styles.scss'

interface IBrands{
    brand_id: string,
    brand_name: string,
    brand_image: string,
}
interface IQuickFiltersProps{
    brandIds: Array<string>,
    setBrandIds: (brandIds: Array<string>) => void,
    seasonIds: Array<string>,
    setSeasonIds: (seasonIds: Array<string>) => void,
    vehicleTypeIds: Array<string>,
    setVehicleTypeIds: (vehicleTypeIds: Array<string>) => void,
    disabled: boolean,
    activeButtonIndex: string,
}
const TyresQuickFilters = ({brandIds, setBrandIds, seasonIds, setSeasonIds, vehicleTypeIds, setVehicleTypeIds, disabled}:IQuickFiltersProps) => {
  const [tyresSearchData, setTyresSearchData] = useState<any>([])

  const [showMorePremium, setShowMorePremium] = useState(false)
  const [showMoreQuality, setShowMoreQuality] = useState(false)
  const [showMoreBudget, setShowMoreBudget] = useState(false)
  const [quickFiltersloading, setQuickFiltersLoading] = useState(false)

  const PREMIUM_BRANDS_LENGTH = tyresSearchData.qualities?.PREMIUM?.length
  const QUALITY_BRANDS_LENGTH = tyresSearchData.qualities?.QUALITY?.length
  const BUDGET_BRANDS_LENGTH = tyresSearchData.qualities?.BUDGET?.length

  const premiumRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const qualityRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const budgetRef = useRef() as React.MutableRefObject<HTMLInputElement>

  useOnClickOutside(premiumRef, () => setShowMorePremium(false))
  useOnClickOutside(qualityRef, () => setShowMoreQuality(false))
  useOnClickOutside(budgetRef, () => setShowMoreBudget(false))
  
  const {t} = useTranslation()
  let isMounted = true
  
  const getQuickFiltersData = () => {
    setQuickFiltersLoading(true)
    getQuickFilters().then(({data}:any) => {
      isMounted && setTyresSearchData(data)
    }).finally(() => {
      setQuickFiltersLoading(false)
    })
  }
  
  const setParamId = (param_id: string, state:string[], setState: (arg: any) => any) => {
    if (state.includes(param_id.toString())) {
      setState(state.filter(id => id !== param_id.toString()))
    } else {
      setState([...state, param_id.toString()])
    }
  }

  useEffect(() => {
    getQuickFiltersData()

    return () => {
      isMounted = false
    }
  }, [])

  const getSelectedBrandsCountByQuality = (quality: IBrands[]) => {
    return (quality || []).filter(({brand_id}:IBrands) => brandIds.includes(brand_id.toString())).length
  }

  const SELECTED_PREMIUM_BRANDS = getSelectedBrandsCountByQuality(tyresSearchData.qualities?.PREMIUM)
  const SELECTED_QUALITY_BRANDS = getSelectedBrandsCountByQuality(tyresSearchData.qualities?.QUALITY)
  const SELECTED_BUDGET_BRANDS = getSelectedBrandsCountByQuality(tyresSearchData.qualities?.BUDGET)

  return (
    <div className="tab-list">
      <div className="tab">
        {quickFiltersloading ? <Spinner class="small" /> : (
          <>
            <div className="tyres-tab-top">

              <h6>{t('PREMIUM_BRAND')}</h6>
              <div className="tab-container">
                {tyresSearchData.qualities?.PREMIUM?.length ? tyresSearchData.qualities.PREMIUM.slice(0, 4).map(({brand_id, brand_image, brand_name}:IBrands) => (
                  <div className="brand-item" key={brand_id}>
                    <input
                      type="checkbox"
                      disabled={disabled}
                      checked={brandIds.includes(brand_id.toString())} 
                      onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                    /> 
                    <img src={`${brand_image}`} alt={brand_name} className="tyre-img-logo" />
                  </div>
                )) : <div className="no-data">{t('NO_DATA')}</div>}
              </div>
            
            </div>

            {PREMIUM_BRANDS_LENGTH > 4 && (
              <>
                <div className={`show-more-btn ${showMorePremium ? 'show-less' : ''}`} onClick={() => setShowMorePremium(true)}>
                  {showMorePremium ? (
                    <div className="show-more-btn-text ">
                      {t('SHOW_LESS')}
                      <i className="fa fa-angle-up" />
                    </div>
                  ) : (
                    <div className="show-more-btn-text">
                      {t('SHOW_MORE')}
                      <i className="fa fa-angle-down" />
                    </div>
                  )}
            
                </div>
            
                {SELECTED_PREMIUM_BRANDS > 0 && (
                  <div className="selected-tyres-brands">
                    {t('SELECTED')}
                    :
                    <span>
                      {SELECTED_PREMIUM_BRANDS}
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        )}
        
        <div ref={premiumRef} className={`show-more-dropdown ${showMorePremium ? 'show' : 'hide'}`}>
          {tyresSearchData.qualities?.PREMIUM?.length && tyresSearchData.qualities.PREMIUM.slice(4, PREMIUM_BRANDS_LENGTH).map(({brand_id, brand_name}:IBrands) => {
            return (
              <div className="show-more-brand-item" key={brand_id}>
                <input
                  type="checkbox"
                  disabled={disabled}
                  checked={brandIds.includes(brand_id.toString())} 
                  onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                />
                <div>{brand_name}</div>
              </div>
            )
          })}
        </div>

      </div>
      { false && (
        <div className="tab">
          {quickFiltersloading ? <Spinner class="small" /> : (
            <>
              <div className="tyres-tab-top">

                <h6>{t('QUALITY_BRAND')}</h6>
                <div className="tab-container ">
                  {tyresSearchData.qualities?.QUALITY?.length ? tyresSearchData.qualities.QUALITY.slice(0, 4).map(({brand_id, brand_image, brand_name}:IBrands) => (
                    <div className="brand-item" key={brand_id}>
                      <input
                        type="checkbox"
                        disabled={disabled}
                        checked={brandIds.includes(brand_id.toString())} 
                        onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                      /> 
                      <img src={`${brand_image}`} alt={brand_name} className="tyre-img-logo" />
                    </div>
                  )) : <div className="no-data">{t('NO_DATA')}</div>}
                </div>
              </div>
              {QUALITY_BRANDS_LENGTH > 4 && (
                <>
                  <div className={`show-more-btn ${showMoreQuality ? 'show-less' : ''}`} onClick={() => setShowMoreQuality(true)}>
                    {showMoreQuality ? (
                      <div className="show-more-btn-text">
                        {t('SHOW_LESS')}
                        <i className="fa fa-angle-up" />
                      </div>
                    ) : (
                      <div className="show-more-btn-text">
                        {t('SHOW_MORE')}
                        <i className="fa fa-angle-down" />
                      </div>
                    )}
            
                  </div>
                  {SELECTED_QUALITY_BRANDS > 0 && (

                    <div className="selected-tyres-brands">
                      {t('SELECTED')}
                      :
                      <span>
                        {SELECTED_QUALITY_BRANDS}
                      </span>
                    </div>

                  )}
                </>
              )}
            </>
          )}

          <div ref={qualityRef} className={`show-more-dropdown ${showMoreQuality ? 'show' : 'hide'}`}>
            {tyresSearchData.qualities?.QUALITY?.length && tyresSearchData.qualities.QUALITY.slice(4, QUALITY_BRANDS_LENGTH).map(({brand_id, brand_name}:IBrands) => {
              return (
                <div className="show-more-brand-item" key={brand_id}>
                  <input
                    type="checkbox"
                    disabled={disabled}
                    checked={brandIds.includes(brand_id.toString())} 
                    onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                  />
                  <div>{brand_name}</div>
                </div>
              
              )
            })}
          </div>
        
        </div>
      ) }
      <div className="tab">
        {quickFiltersloading ? <Spinner class="small" /> : (
          <>
            <div className="tyres-tab-top">
              <h6>{t('BUDGET_BRAND')}</h6>
              <div className="tab-container">
                {tyresSearchData.qualities?.BUDGET?.length ? tyresSearchData.qualities.BUDGET.slice(0, 4).map(({brand_id, brand_image, brand_name}:IBrands) => (
                  <div className="brand-item" key={brand_id}>
                    <input
                      type="checkbox"
                      disabled={disabled}
                      checked={brandIds.includes(brand_id.toString())} 
                      onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                    /> 
                    <img src={`${brand_image}`} alt={brand_name} className="tyre-img-logo" />
                  </div>
                )) : <div className="no-data">{t('NO_DATA')}</div>}
              </div>
            </div>
            {BUDGET_BRANDS_LENGTH > 4 && (
              <>
                <div className={`show-more-btn ${showMoreBudget ? 'show-less' : ''}`} onClick={() => setShowMoreBudget(true)}>
                  {showMoreBudget ? (
                    <div className="show-more-btn-text">
                      {t('SHOW_LESS')}
                      <i className="fa fa-angle-up" />
                    </div>
                  ) : (
                    <div className="show-more-btn-text">
                      {t('SHOW_MORE')}
                      <i className="fa fa-angle-down" />
                    </div>
                  )}
            
                </div>
                {SELECTED_BUDGET_BRANDS > 0 && (
              
                  <div className="selected-tyres-brands">
                    {t('SELECTED')}
                    :
                    <span>
                      {SELECTED_BUDGET_BRANDS}
                    </span>
                  </div>

                )}
              </>
            )}
          </>
        )}
        
        {showMoreBudget && (
          <div ref={budgetRef} className={`show-more-dropdown ${showMoreBudget ? 'show' : 'hide'}`}>
            {tyresSearchData.qualities?.BUDGET?.length && tyresSearchData.qualities.BUDGET.slice(4, BUDGET_BRANDS_LENGTH).map(({brand_id, brand_name}:IBrands) => {
              return (
                <div className="show-more-brand-item" key={brand_id}>
                  <input
                    type="checkbox"
                    disabled={disabled}
                    checked={brandIds.includes(brand_id.toString())} 
                    onChange={() => setParamId(brand_id, brandIds, setBrandIds)}
                  />
                  <div>{brand_name}</div>
                </div>
              
              )
            })}
          </div>
        )}
      </div>
      {false && (
        <div className="tab">
          {quickFiltersloading ? <Spinner class="small" /> : (
            <div className="tyres-tab-top">

              <h6>{t('VEHICLE_TYPE')}</h6>
              <div className="items-container">
                {tyresSearchData.vehicle_types?.length && tyresSearchData.vehicle_types.map((type: any) => {
                  return (
                    <div className="brand-item" key={type.id}>
                      <input
                        type="checkbox"
                        disabled={disabled}
                        checked={vehicleTypeIds.includes(type.id.toString())} 
                        onChange={() => setParamId(type.id, vehicleTypeIds, setVehicleTypeIds)}
                      /> 
                      <div>{type.name}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="tab">
        {quickFiltersloading ? <Spinner class="small" /> : (
          <div className="tyres-tab-top">

            <h6>{t('SEASON')}</h6>
            <div className="items-container">
              { tyresSearchData.seasons?.length && tyresSearchData.seasons.map((season: any) => {
                return (
                  <div className="brand-item" key={season.id}>
                    <input
                      type="checkbox"
                      disabled={disabled}
                      checked={seasonIds.includes(season.id.toString())} 
                      onChange={() => setParamId(season.id, seasonIds, setSeasonIds)}
                    /> 
                    <div>{t((season.name).toUpperCase().replace(' ', '_'))}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TyresQuickFilters
