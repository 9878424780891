export const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fr',
    name: 'Français',
  },
  {
    code: 'ru',
    name: 'Русский',
  },
  {
    code: 'es',
    name: 'Español',
  },
  {
    code: 'qa',
    name: 'English (USA)',
  },
  {
    code: 'it',
    name: 'Italiano',
  },
  {
    code: 'tr',
    name: 'Türkçe',
  },
  {
    code: 'ar',
    name: 'العربية',
  },
  {
    code: 'zh',
    name: '中文',
  },
  {
    code: 'ko',
    name: '한국어',
  },
  
]

export const countries = [
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'IE',
    name: 'Ireland',
  },
  {
    code: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'CO',
    name: 'Colombia',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'KR',
    name: 'Korea',
  },
  {
    code: 'EG',
    name: 'Egypt',
  },
  {
    code: 'TR',
    name: 'Turkey',
  },
  {
    code: 'IR',
    name: 'Iran',
  },
  {
    code: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'US',
    name: 'United States of America',
  },

]

export const immatCountry = [
  {
    name: 'F',
    value: 'fr',
  },
  {
    name: 'E',
    value: 'es',
  },
  {
    name: 'D',
    value: 'de',
  },
  {
    name: 'P',
    value: 'pl',
  },
  {
    name: 'I',
    value: 'it',
  },
  {
    name: 'N',
    value: 'nl',
  },
]
