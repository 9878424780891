import React, {useState, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {converObjectToParams} from 'utils/getData'
import {getCarDescription} from 'utils/getCarDescription'
import {getTruckDescription} from 'utils/getTruckDescription'
import Spinner from 'components/Spinner'

import {CAR_IDENTIFIER, SOURCE_TECDOC, TRUCK_IDENTIFIER} from 'constants/defaultConstants'
import {setVehicleId} from 'store/actions/reliable/vehicleCategoriesActions'
import {fetchReliablePlateNumber, updateReliablePlateNumber} from 'api/reliable/search'
import './style.scss'

const SearchPlateNumber = props => {
  const [countryCode, setCountryCode] = useState('fr')
  const [plateNumber, setPlateNumber] = useState('')
  const [errorStatus, setErrorStatus] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [isCar, setIsCar] = useState(false)
  const [isTruck, setIsTruck] = useState(false)
  const [viewAllModels, setViewAllModels] = useState(false)
  const inputRef = useRef(null)
  const {t} = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.activeButtonIndex !== 'plate') {
      setPlateNumber('')
      setCountryCode('fr')
      setShowSuggestions(false)
      setSuggestions([])
      setErrorStatus(false)
    }
  }, [props.activeButtonIndex])

  const submitHandler = event => {
    event.preventDefault()

    if (/^.{5,10}$/.test(plateNumber)) {
      getPlateNumber({
        registration_plate: plateNumber,
        registration_country: countryCode,
      })
    } else {
      setErrorStatus(true)
    }
  }

  const getPlateNumber = listQuery => {
    setShowSuggestions(true)
    setShowSpinner(true)
    setSuggestions([])

    fetchReliablePlateNumber(listQuery)
      .then(({data}) => {
        setSuggestions([])
        if (data.cars) {
          setIsCar(true)
          setIsTruck(false)
          setSuggestions(data.cars)
        }

        if (data.trucks) {
          setIsTruck(true)
          setIsCar(false)
          setSuggestions(data.trucks)
        }
        setShowSpinner(false)
      })
      .catch(() => {
        setSuggestions([])
        setShowSpinner(false)
      })
  }

  const updateSearchValue = event => {
    setPlateNumber(event.target.value.trim())
    setShowSuggestions(false)
    setErrorStatus(false)
  }

  const clearPlateNumber = event => {
    event.preventDefault()
    inputRef.current.focus()
    setPlateNumber('')
    setSuggestions([])
    setShowSuggestions(false)
    setErrorStatus(false)
  }

  const handlerSelectedItem = item => {
    updateReliablePlateNumber({
      registration_plate: plateNumber,
      ktypnr: item.id,
    })
  }

  const clickPlateNumberHandler = item => {
    const {id, source} = item
    const vehicle_identifier = isCar ? CAR_IDENTIFIER : TRUCK_IDENTIFIER

    const data = {
      source: source || SOURCE_TECDOC,
      registration_plate: plateNumber,
      registration_country: countryCode,
      vehicle_identifier,
    }

    handlerSelectedItem(item)
    setShowSuggestions(false)
    setSuggestions([])

    dispatch(setVehicleId(Number(id)))
    props.history.push({
      pathname: `/reliable/rmi/${id}/categories`,
      search: `?${converObjectToParams(data)}`,
    })
  }
  const plateNumberListComponent = plateClass => {
    const isSelected = suggestions.some(item => item.is_selected)
    const hasMultipleVariants = suggestions.length > 1
    if (showSuggestions && plateNumber) {
      return (
        <div className={`result-search ${suggestions.length ? '' : 'no-result'} ${plateClass}`}>
          <div className="wrapper">
            <div className="suggestion-title">{t('RESULTS_PLATE_NUMBER')}</div>
          </div>
          <ul className={`suggestions ${suggestions.length >= 10 ? 'scroll' : ''}`}>
            {showSpinner
              ? <Spinner type="skeleton-line" />
              : suggestions.length ? suggestions.map((item, index) => {
                if (!viewAllModels && (isSelected && !item.is_selected)) return null
                return (
                  <div key={index}>
                    {item.is_selected ? (
                      <li key={index} onClick={() => clickPlateNumberHandler(item)} style={{color: item.is_selected ? 'purple' : '#212529'}}>
                        {isCar ? getCarDescription(item) : null}
                        {isTruck ? getTruckDescription(item) : null}
                      </li>
                    ) : (
                      <li
                        key={index}
                        onClick={() => clickPlateNumberHandler(item)}
                      >
                        {getCarDescription(item)}
                      </li>
                    )}
                    {
                      isSelected
                   && item.is_selected
                   && !viewAllModels
                   && hasMultipleVariants
                   && <button className="change-model-btn" type="button" onClick={() => setViewAllModels(true)}>Select another car variant</button>
                    }
                  </div>
                )
              }) : <div className="no-suggestions">{`${t('NO_DATA')}!`}</div>}
          </ul>
        </div>
      )
    }

    return null
  }

  return (
    <div className={`${props.activeButtonIndex !== 'plate' ? 'd-none' : 'inner-search-wrapper'}`}>
      <form
        className="search-form custom-search-form  plate-number"
        onSubmit={submitHandler}
      >
        <div className="inner-search-container">
          <div className="country-code">F</div>
          <div className="search-container">
            <input
              ref={inputRef}
              className={`search-header form-control ${errorStatus ? 'input-error' : ''}`}
              type="text"
              placeholder={t('SEARCH_BY_PLATE')}
              aria-label="Search"
              value={plateNumber}
              onChange={updateSearchValue}
            />
            {plateNumber && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearPlateNumber} />}
            {plateNumberListComponent('desktop')}
            <div className={`${errorStatus ? 'error-container' : 'd-none'}`}>
              {t('INVALID_SEARCH_BY_PLATE')}
            </div>
          </div>

          <button
            className={`button-style search-toolbar-button ${plateNumber ? 'button-style-hover' : 'button-disabled-style'}`}
            type="submit"
            onClick={() => setViewAllModels(false)}
          >
            <i className="material-icons">search</i>
          </button>
        </div>
        {plateNumberListComponent('mobile')}
      </form>
    </div>
  )
}

export default withRouter(SearchPlateNumber)
