import {batch} from 'react-redux'
import {
  ACTION_UPDATE_CART_QUANTITY_TOOLBAR,
  ACTION_UPDATE_USER,
  RESET_ACCOUNT_SETTINGS,
  ACTION_FETCH_USER_INFO,
  ACTION_UPDATE_ACCOUNT_SETTINGS,
  RUN_UPDATE_SETTINGS,
  FAILED_UPDATE_SETTINGS,
  ACTION_FETCH_USER_INFO_FAIL,
} from 'constants/actionsTypes'
import {fetchUser, updateUser} from 'api/user'
import {getCurrentMarketplace} from 'utils/getData'
import i18n from '../../i18n'

export const fetchUserInfo = (userInfoLogin = null) => dispatch => {
  dispatch({
    type: ACTION_FETCH_USER_INFO,
  })

  if (window.localStorage.hasOwnProperty('userState') || userInfoLogin) {
    const userState = userInfoLogin ? userInfoLogin : JSON.parse(window.localStorage.getItem('userState'))
    return Promise.resolve(userState).then(userState => {
      batch(() => {
        dispatch({
          type: ACTION_UPDATE_USER,
          user: userState,
        })

        dispatch({
          type: ACTION_UPDATE_CART_QUANTITY_TOOLBAR,
          quantityToolbar: userState.cart_quantity,
        })
      })

      let language
      if (getCurrentMarketplace() === 'public') {
        const urlParams = new URLSearchParams(location.search)
        if (urlParams.get('language')) {
          localStorage.setItem('language', `${urlParams.get('language')}-${urlParams.get('country')}`)
          history.replaceState({}, document.title, '/')
        }
      }
      if (localStorage.getItem('language')) {
        language = localStorage.getItem('language')
      } else {
        let lang = 'en'

        if (userState?.language) {
          lang = userState.language
        }

        language = `${lang}-${userState.client_country_code}`
      }
      window.localStorage.setItem('language', language)
      i18n.changeLanguage(language)
      if (userInfoLogin) {
        window.localStorage.setItem('userState', JSON.stringify(userInfoLogin))
      }
    })
  }
  return fetchUser()
    .then(response => {
      batch(() => {
        dispatch({
          type: ACTION_UPDATE_USER,
          user: response.data,
        })

        dispatch({
          type: ACTION_UPDATE_CART_QUANTITY_TOOLBAR,
          quantityToolbar: response.data.cart_quantity,
        })
      })

      let language

      if (localStorage.getItem('language')) {
        language = localStorage.getItem('language')
      } else {
        let lang = 'en'

        if (response?.data?.language) {
          lang = response.data.language
        }

        language = `${lang}-${response.data.client_country_code}`
      }

      window.localStorage.setItem('language', language)

      window.localStorage.setItem('userState', JSON.stringify(response.data))
      i18n.changeLanguage(language)
    }).catch(() => {
      dispatch({
        type: ACTION_FETCH_USER_INFO_FAIL,
      })
    })
}

export const updateUserInfo = userInfo => dispatch => {
  dispatch({
    type: ACTION_UPDATE_USER,
    user: userInfo,
  })
}

export const resetAccountSettings = () => dispatch => {
  dispatch({
    type: RESET_ACCOUNT_SETTINGS,
  })
}

export function updateAccountSettings(data, from = true) {
  return dispatch => {
    if (from) {
      dispatch({
        type: RUN_UPDATE_SETTINGS,
      })
    }

    return updateUser(data)
      .then(response => {
        dispatch({
          type: ACTION_UPDATE_ACCOUNT_SETTINGS,
          settings: response.data,
        })
        return response
      })
      .catch(error => {
        dispatch({
          type: FAILED_UPDATE_SETTINGS,
        })
        return error.response
      })
  }
}
