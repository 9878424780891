import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactNotification from 'react-notifications-component'
import {useLocation, useHistory} from 'react-router-dom'
import {getCurrentMarketplace, getURLParams} from 'utils/getData'
import {
  isHostIframeTCRMI,
  isHostPunchOut,
  isHostAF,
  checkClientIdFromLocalStorage,
  isHostRetailer,
  isHostPublic,
  isHostPublicTr,
} from 'utils'
import PrivateRoutesPunchOut from 'routes/PrivateRoutesPunchOut'
import FooterPunchOut from 'components/Footer/PunchOut'
import FooterMarketplace from 'components/Footer/Marketplace'
import HeaderMarketplace from 'components/Header/Marketplace'
import HeaderPunchOut from 'components/Header/PunchOut'
import HeaderAF from 'components/Header/CatalogAF'
import PrivateRoutesAF from 'routes/PrivateRoutesAF'
import PrivateRoutesRMIIframe from 'routes/PrivateRoutesRMIIframe'
import InternalServerError from 'pages/Error/InternalServerError'

import FooterAf from 'components/Footer/Af'
import {CATALOG_AUT} from 'constants/defaultConstants'
import HeaderRetailer from 'components/Header/Retailer'
import HeaderPublic from 'components/Header/Public'
import FooterPublic from 'components/Footer/Public'
import FeedBackButton from './components/Feedback'
import {fetchUserInfo} from './store/actions/userActions'
import Ebook from './pages/Catalog/Ebooks/Ebook'
import {useDocumentTitle, usePermission} from './utils/hooks'
import ModalRoot from './components/ModalRoot'
import PrivateRoutes from './routes/PrivateRoutes/index'
import {ROLE_ADV, ROLE_SELLER, ROLE_SUPPLIER_AMERIGO} from './constants/roles'

const App = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {user, error} = useSelector(state => state.userState)
  const marketplace = getCurrentMarketplace()
  const hasSellerPermission = usePermission([
    ROLE_SELLER,
    ROLE_ADV,
    ROLE_SUPPLIER_AMERIGO,
  ])
  const isPunchOut = isHostPunchOut()
  const isRetailer = isHostRetailer()
  const isPublic = isHostPublic()
  const isPublicTr = isHostPublicTr()
  const isIframeTCRMI = isHostIframeTCRMI()
  const isAF = isHostAF()
  const urlParams = getURLParams(location.search)
  useDocumentTitle(marketplace?.capitalize())

  useEffect(() => {
    if (!user && localStorage.getItem('token') && !error) {
      dispatch(fetchUserInfo())
    }

    // keep on restore-password-route
    if (user && user.force_change_password) {
      history.push({
        pathname: '/restore-password',
        state: {
          isForced: true,
        },
      })
    }
    // keep on select-client route
    if (
      location?.pathname !== '/select-client'
      && hasSellerPermission
      && !checkClientIdFromLocalStorage()
      && !user.force_change_password
    ) {
      history.push('/select-client')
    }
  }, [user])

  useEffect(() => {
    if (isAF && urlParams.catalog_id && urlParams.catalog_id != CATALOG_AUT) {
      history.push('/car-parts?catalog_id=AUT')
    }
  }, [urlParams.catalog_id])

  if (location.pathname.includes('flipbook')) {
    return <Ebook />
  }

  if (error) {
    return <InternalServerError />
  }

  if (isIframeTCRMI) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <PrivateRoutesRMIIframe />
        <ModalRoot />
      </>
    )
  }

  if (isPunchOut) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <HeaderPunchOut />
        {/* <NewAppNavbar /> */}
        <PrivateRoutesPunchOut />
        <FeedBackButton />
        <ModalRoot />
        <FooterPunchOut />
      </>
    )
  }
  if (isRetailer) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <HeaderRetailer />
        {/* <NewAppNavbar /> */}
        <PrivateRoutesPunchOut />
        <FeedBackButton />
        <ModalRoot />
        <FooterPunchOut />
      </>
    )
  }
  if (isAF) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <HeaderAF />
        {/* <NewAppNavbar /> */}
        <PrivateRoutesAF />
        <FeedBackButton />
        <ModalRoot />
        <FooterAf />
      </>
    )
  }
  if (isPublic) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <HeaderPublic />
        {/* <NewAppNavbar /> */}
        <PrivateRoutes />
        <ModalRoot />
        <FooterPublic />
      </>
    )
  }
  if (isPublicTr) {
    return (
      <>
        <ReactNotification isMobile={false} />
        <HeaderPublic />
        {/* <NewAppNavbar /> */}
        <PrivateRoutes />
        <ModalRoot />
        <FooterPublic />
      </>
    )
  }
  return (
    <>
      <ReactNotification isMobile={false} />
      <HeaderMarketplace />
      {/* <NewAppNavbar /> */}
      <PrivateRoutes />
      <FeedBackButton />
      <ModalRoot />
      <FooterMarketplace />
    </>
  )
}

export default App
