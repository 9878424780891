import React from 'react'
import {useTranslation} from 'react-i18next'

import Selector from 'components/Selector'

const GenerationDropdown = props => {
  const {t} = useTranslation()

  const handleSelectGeneration = event => {
    const data = event.target.value
    props.handleGrabGeneration(data)
  }

  return (
    <Selector
      title={t('GENERATION')}
      value={props.currentGeneration}
      selectId={`select-generation-${props.id}`}
      disabledState={!props.currentModel}
      data={props.generations}
      selectHandler={handleSelectGeneration}
      loading={props.loading}
      selectValueObject={true}
    />
  )
}

export default GenerationDropdown
