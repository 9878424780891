import React, {Suspense, lazy, useEffect, useState} from 'react'
import {Switch, Route, Redirect, useHistory, useLocation} from 'react-router-dom'
import {retryPromise as retry} from 'utils/retry_promise'
import Spinner from 'components/Spinner'
import {auth} from 'store/actions/authActions'
import {useDispatch} from 'react-redux'
import {getCurrentMarketplace, getURLParams} from 'utils/getData'
import {isHostPunchOut, isHostIframeTCRMI} from '../../utils'

const Login = lazy(() => retry(() => import('pages/Auth/Login')))
const ForgotPassword = lazy(() => retry(() => import('pages/Auth/ForgotPassword')))
const ResetPassword = lazy(() => retry(() => import('pages/Auth/ResetPassword')))
const AuthLayout = lazy(() => retry(() => import('layouts/AuthLayout')))
const PlatformUserUnauthorized = lazy(() => retry(() => import('pages/PlatformUserUnauthorized')))

const PublicRoutes = () => {
  const isPunchOut = isHostPunchOut()
  const isIframeTCRMI = isHostIframeTCRMI()
  const history = useHistory()
  const dispatch = useDispatch()
  const marketplace = getCurrentMarketplace()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const urlParams = getURLParams(location.search)

  if (isIframeTCRMI) {
    return (
      <Suspense fallback={<Spinner class="small" />}>
        <h1>We should think about a page for unautorized </h1>
      </Suspense>
    )
  }
  useEffect(() => {
    if (marketplace === 'public') {
      localStorage.setItem('accessToken', urlParams.accessToken)
      setLoading(true)
      dispatch(
        auth({
          username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_EMAIL,
          password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_PASSWORD,
          history,
          location,
        }),
      )
    }

    if (marketplace === process.env.REACT_APP_MARKETPLACE_PUBLIC_TR) {
      setLoading(true)
      dispatch(
        auth({
          username: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TR_EMAIL,
          password: process.env.REACT_APP_AUTOLOGIN_PUBLIC_TR_PASSWORD,
          history,
          location,
        }),
      )
    }
  }, [])

  if (loading) {
    return <Spinner class="small" />
  }

  return (
    <Suspense fallback={<Spinner class="small" />}>
      <AuthLayout>
        {
          isPunchOut ? (
            <Switch>
              <Route path="/platform-unauthorized" component={PlatformUserUnauthorized} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/restore-password/:token" component={ResetPassword} />
              {marketplace !== 'public' && (
                <Redirect to={{
                  pathname: '/login',
                  state: {from: history.location},
                }}
                />
              )}
            </Switch>
          )
        }
      </AuthLayout>
    </Suspense>
  )
}

export default PublicRoutes
