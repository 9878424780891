import request from 'utils/request'

export function fetchOrders(query) {
  return request({
    url: '/orders',
    method: 'get',
    params: query,
  })
}

export function fetchOrderStatus() {
  return request({
    url: '/orders/status',
    method: 'get',
  })
}

export function fetchOrderInfoById(id) {
  return request({
    url: `/orders/${id}`,
    method: 'get',
  })
}

export function fetchOrderItemsById(id, params) {
  return request({
    url: `/orders/${id}/items`,
    method: 'get',
    params,
  })
}

export function fetchOrdersChallenge() {
  return request({
    url: '/orders-challenge',
    method: 'get',
  })
}

export function fetchOrderShippingDetails(id) {
  return request({
    url: `orders/${id}/shipping`,
    method: 'get',
  })
}

export function checkIsEbayClient() {
  return request({
    url: 'orders/is-ebay-client',
    method: 'get',
  })
}

export function generateOrderShippingPdf(data) {
  return request({
    url: 'orders/322/shipping/pdf',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function fetchOrdersClients(query) {
  return request({
    url: '/orders-clients',
    method: 'get',
    params: query,
  })
}

export function fetchOrderClientsInfoById(id) {
  return request({
    url: `/orders-clients/${id}`,
    method: 'get',
  })
}

export function fetchOrderlientsItemsById(id, params) {
  return request({
    url: `/orders-clients/${id}/items`,
    method: 'get',
    params,
  })
}

export function fetchOrdersSupplier(query) {
  return request({
    url: '/orders-list',
    method: 'get',
    params: query,
  })
}

export function fetchOrderSupplierInfoById(id) {
  return request({
    url: `/orders-list/${id}`,
    method: 'get',
  })
}

export function fetchOrderSupplierItemsById(id, params) {
  return request({
    url: `/orders-list/${id}/items`,
    method: 'get',
    params,
  })
}

export function supplierMappingOptions() {
  return request({
    url: '/amerigo/supplier-mapping-options',
    method: 'get',
  })
}
