import $ from 'jquery'
import {countries, languages} from 'constants/locales'
import i18n from '../i18n'

export function getURLParams(location) {
  const data = {}
  const params = new URLSearchParams(location)
  params.forEach((paramValue, paramName) => {
    data[paramName] = paramValue
  })

  return data
}

export function getURLParamsArray(location) {
  const data = {}
  const params = new URLSearchParams(location)
  params.forEach((paramValue, paramName) => {
    if (paramName in data) {
      if (Array.isArray(data[paramName])) {
        data[paramName] = [...data[paramName], paramValue]
      } else {
        data[paramName] = [data[paramName], paramValue]
      }
    } else {
      data[paramName] = paramValue
    }
  })

  return data
}

export function getCurrentLanguage() {
  const codeLanguages = languages.map(lang => lang.code)
  const codeCountries = countries.map(country => country.code)
  const currentLanguage = i18n.language.replace('-', '_')

  const langAndCountryCodes = []
  codeLanguages.forEach(langCode => codeCountries.forEach(countryCode => langAndCountryCodes.push(`${langCode}_${countryCode}`)))

  return langAndCountryCodes.includes(currentLanguage) ? currentLanguage : `${currentLanguage.split('_')[0]}_${countries[0].code}`
}

export function getCurrentDomain() {
  let currentDomain = process.env.REACT_APP_API
  if (process.env.REACT_APP_API_RETAILER_AWS) {
    currentDomain = process.env.REACT_APP_API_RETAILER_AWS
  } else {
    if (window.location.hostname.split('.').some(subdomain => subdomain === 'automotor')) {
      currentDomain = `${process.env.REACT_APP_API_AUTOMOTOR}`
    } else if (window.location.hostname.includes(process.env.REACT_APP_MARKETPLACE_PUBLIC_TR)) {
      currentDomain = `${process.env.REACT_APP_API_PUBLIC_TR}`
    } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'amerigo')) {
      currentDomain = `${process.env.REACT_APP_API_AMERIGO}`
    } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'af')) {
      currentDomain = `${process.env.REACT_APP_API_AF}`
    } else if (process.env.REACT_APP_SUBDOMAIN_IFRAME_TECRMI.split('|').some(subdomain => window.location.host.includes(subdomain))) {
      currentDomain = `${process.env.REACT_APP_API_AUTOMOTOR}`
    } else if (process.env.REACT_APP_SUBDOMAIN_PUNCHOUT.split('|').some(subdomain => window.location.host.includes(subdomain))) {
      currentDomain = `${process.env.REACT_APP_API_RETAILER}`
    } else if (window.location.hostname.split('.').some(subdomain => subdomain === `${process.env.REACT_APP_MARKETPLACE_RETAILER}`)) {
      currentDomain = `${process.env.REACT_APP_API_RETAILER}`
    } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'public')) {
      currentDomain = `${process.env.REACT_APP_API_PUBLIC}`
    }
  }
  return currentDomain
}

export function getCurrentMarketplace() {
  let marketplace = process.env.REACT_APP_MARKETPLACE

  if (window.location.hostname.split('.').some(subdomain => subdomain === 'automotor')) {
    marketplace = process.env.REACT_APP_MARKETPLACE_AUTOMOTOR
  } else if (window.location.hostname.includes(process.env.REACT_APP_MARKETPLACE_PUBLIC_TR)) {
    marketplace = process.env.REACT_APP_MARKETPLACE_PUBLIC_TR
  } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'amerigo')) {
    marketplace = process.env.REACT_APP_MARKETPLACE_AMERIGO
  } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'af')) {
    marketplace = process.env.REACT_APP_MARKETPLACE_CATALOG_AF
  } else if (process.env.REACT_APP_SUBDOMAIN_IFRAME_TECRMI.split('|').some(subdomain => window.location.host.includes(subdomain))) {
    marketplace = process.env.REACT_APP_MARKETPLACE_AUTOMOTOR
  } else if (process.env.REACT_APP_SUBDOMAIN_PUNCHOUT.split('|').some(subdomain => window.location.host.includes(subdomain))) {
    marketplace = process.env.REACT_APP_MARKETPLACE_PUNCHOUT
  } else if (window.location.hostname.split('.').some(subdomain => subdomain === `${process.env.REACT_APP_MARKETPLACE_RETAILER}`)) {
    marketplace = process.env.REACT_APP_MARKETPLACE_RETAILER
  } else if (window.location.hostname.split('.').some(subdomain => subdomain === 'public')) {
    marketplace = 'public'
  } 

  return marketplace
}

export function getApi() {
  return `${getCurrentDomain()}/${getCurrentLanguage()}/api`
}

export function converObjectToParams(data) {
  return $.param(data)
}

export const getImagePath = item => `${getCurrentDomain()}${item?.image}`
