import {IOilActionTypes, IOilGlobalState} from '../../types/oilTypes'
import {
  ACTION_FETCH_OIL_FILTERS,
  ACTION_FETCH_OIL_ITEM,
  ACTION_FETCH_OIL_SEARCH,
  ACTION_LOADING_OIL_FILTERS, ACTION_LOADING_OIL_ITEM,
  ACTION_LOADING_OIL_SEARCH,
  ACTION_RESET_OIL_SEARCH,
  ACTION_RESET_OIL_FILTERS,
} from '../../../constants/actionsTypes'

const initialState: IOilGlobalState = {
  oil: null,
  oilLoading: false,
  loading: false,
  filterLoading: false,
  filters: null,
  pagination: null,

}

export default function oilSearchReducer(state = initialState, action: IOilActionTypes = {
  type: ACTION_FETCH_OIL_SEARCH,
  payload: null,
}): IOilGlobalState {
  switch (action.type) {
    case ACTION_FETCH_OIL_ITEM:
      return {
        ...state,
        oil: action?.payload || null,
      }
    case ACTION_LOADING_OIL_ITEM:
      return {
        ...state,
        oilLoading: action.payload,
      }
    case ACTION_FETCH_OIL_SEARCH:
      return {
        ...state,
        loading: false,
        pagination: action?.payload?.data,

      }
    case ACTION_RESET_OIL_SEARCH:
      return {
        ...state,
        oil: null,
        pagination: null,
        loading: false,
      }
    case ACTION_FETCH_OIL_FILTERS:
      return {
        ...state,
        filters: action.payload ?? null,
        filterLoading: false,
      }
    case ACTION_RESET_OIL_FILTERS:
      return {
        ...state,
        filters: null,
        filterLoading: false,
      }

    case ACTION_LOADING_OIL_FILTERS:
      return {
        ...state,
        filterLoading: action.payload,
      }
    case ACTION_LOADING_OIL_SEARCH:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
