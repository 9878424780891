import React, {useState, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import _ from 'underscore'
import {getValuesFromQuery} from 'utils'
import {useQuery} from 'utils/hooks'
import {getURLParams} from 'utils/getData'
import {updateLoadingCatalog, updateLoadingPageCatalog} from 'store/actions/marketplace/catalogActions'
import Spinner from 'components/Spinner'
import SubCategories from './SubCategories'
import AllCategoriesBtn from './AllCategoriesBtn'
import CategoryCollapseBtn from './CategoryCollapseBtn'
import {
  searchCategories,
  toLower,
  getIsSearched,
  getFiltered,
  clearSearches,
  hasSearchedItems,
} from './utils/search'
import {clearSelections, selectCategory} from './utils/select'

import './style.scss'

const Categories = ({closePanel, fromSideDrawerMenu}) => {
  const location = useLocation()
  const query = useQuery(location.search)
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const urlParams = getURLParams(location.search)
  const {categories, loading} = useSelector(state => state.categoriesState)
  const inputRef = useRef(null)
  const categoryRepository = useRef()
  const [localCategories, setLocalCategories] = useState([])
  const [showAllCategories, setShowAllCategories] = useState(true)
  const {catalogData} = useSelector(state => state.catalogState)

  useEffect(() => {
    if (categories) {
      categoryRepository.current = categories

      setLocalCategories(categories)
      autoSelectFromLocation()
    }
  }, [categories])

  const resetCatalog = status => {
    if (_.isEmpty(catalogData)) {
      dispatch(updateLoadingCatalog(status))
    } else {
      dispatch(updateLoadingPageCatalog(status))
    }
  }

  const handleSearchByCategory = search => {
    const value = toLower(search)
    if (!value) return resetAllFilters()

    const results = searchCategories(value, categoryRepository.current)
    categoryRepository.current = results

    const filtered = getFiltered(results)
    setLocalCategories(filtered)

    const isAllCategoriesSearched = getIsSearched(value, t('ALL_CATEGORIES'))
    setShowAllCategories(!!isAllCategoriesSearched)
  }

  const autoSelectFromLocation = () => {
    const path = getValuesFromQuery(
      query,
      'code_groupe',
      'code_ssgroupe',
      'code_repere',
    )
    if (!path.length) return
    handleSelect(path)
  }

  const handleSelect = (path = []) => {
    let results = selectCategory(path, categoryRepository.current)
    categoryRepository.current = results

    if (hasSearchedItems(results)) results = getFiltered(results)
    setLocalCategories(results)
  }

  const handleSearch = search => {
    const value = search.trim()
    handleSearchByCategory(value)
  }

  const resetSelectedCategories = () => {
    const cleared = clearSelections(categoryRepository.current)
    setLocalCategories(cleared)
    setShowAllCategories(true)
  }

  const resetAllFilters = () => {
    inputRef.current.focus()
    inputRef.current.value = ''
    const cleared = clearSearches(categoryRepository.current)
    setLocalCategories(cleared)
    setShowAllCategories(true)
  }

  return (
    <div className="catalog-categories">
      <div className="collapse show">
        <div className="categories-title">
          {t('CATEGORIES')}
        </div>
        <form className="category-form" onSubmit={event => event.preventDefault()}>
          <div className="input-container">
            <input
              ref={inputRef}
              className="search-category form-control"
              type="text"
              placeholder={t('SEARCH_BY_CATEGORY')}
              onChange={event => handleSearch(event.target.value)}
            />
            {
              inputRef.current && inputRef.current.value
                ? <button className="close-icon" aria-label="Reset" type="reset" onClick={resetAllFilters} />
                : ''
            }
          </div>
        </form>

        <ul className={!loading ? 'categories-list' : ''}>
          {
            !loading && showAllCategories && urlParams.hasOwnProperty('manufacturer_id')
              ? (
                <AllCategoriesBtn
                  resetCatalog={resetCatalog}
                  resetSelectedCategories={resetSelectedCategories}
                />
              )
              : null
          }

          {
            !loading
              ? !_.isEmpty(localCategories)
                ? localCategories.map((category, categoryIndex) => (
                  <li key={categoryIndex}>
                    <CategoryCollapseBtn
                      category={category}
                      categoryIndex={categoryIndex}
                      searchInputRef={inputRef}
                    />
                    <ul
                      id={`collapse-${category.code_groupe}-${categoryIndex}`}
                      className={`collapse ${category.hasInnerSearches || category.hasInnerSelected ? 'show' : ''}`}
                    >
                      {
                        category.subcategories.map((subcategory, subcategoryIndex) => (
                          subcategory.is_three ? (
                            <li key={subcategoryIndex}>
                              <CategoryCollapseBtn
                                category={category}
                                subcategory={subcategory}
                                categoryIndex={subcategoryIndex}
                                searchInputRef={inputRef}
                              />
                              <ul
                                id={`collapse-${category.code_groupe}-${subcategory.code_ssgroupe}-${subcategoryIndex}`}
                                className={`collapse ${subcategory.hasInnerSearches || subcategory.hasInnerSelected ? 'show' : ''}`}
                              >
                                <SubCategories
                                  subcategory={subcategory}
                                  fromSideDrawerMenu={fromSideDrawerMenu}
                                  closePanel={closePanel}
                                  resetCatalog={resetCatalog}
                                  handleSelect={handleSelect}
                                  searchInputRef={inputRef}
                                />
                              </ul>
                            </li>
                          ) : (
                            <SubCategories
                              key={subcategoryIndex}
                              subcategory={subcategory}
                              fromSideDrawerMenu={fromSideDrawerMenu}
                              closePanel={closePanel}
                              resetCatalog={resetCatalog}
                              handleSelect={handleSelect}
                              searchInputRef={inputRef}
                            />
                          )
                        ))
                      }
                    </ul>
                  </li>
                ))
                : <div className="collapse show"><div className="no-categories">{t('NO_CATEGORIES')}</div></div>
              : <Spinner class="small" />
          }
        </ul>
      </div>
    </div>
  )
}

export default Categories
