export const ROLE_CLIENT_PLATFORM = 'ROLE_CLIENT_PLATFORM'
export const ROLE_CLIENT_PLATFORM_ADMIN = 'ROLE_CLIENT_PLATFORM_ADMIN'
export const ROLE_SELLER = 'ROLE_SELLER'
export const ROLE_PROVIDER = 'ROLE_PROVIDER'
export const ROLE_CLIENT_AUTOMOTOR = 'ROLE_CLIENT_AUTOMOTOR'
export const ROLE_CLIENT_AMERIGO = 'ROLE_CLIENT_AMERIGO'
export const ROLE_CLIENT_AUTOMOTOR_AMERIGO = 'ROLE_CLIENT_AUTOMOTOR_AMERIGO'
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_ADV = 'ROLE_ADV'
export const ROLE_SUPPLIER_AMERIGO = 'ROLE_SUPPLIER_AMERIGO'

export const CLIENT_ROLES: string[] = [ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR_AMERIGO]
export const ADMIN_ROLES: string[] = [ROLE_ADMIN, ROLE_SUPER_ADMIN]
export const PLATFORM_ROLES: string[] = [ROLE_CLIENT_PLATFORM, ROLE_CLIENT_PLATFORM_ADMIN]
export const ALL_ROLES: string[] = [...CLIENT_ROLES, ...ADMIN_ROLES, ...PLATFORM_ROLES, ROLE_SELLER, ROLE_PROVIDER, ROLE_ADV]
export const SUPPLIER_AMERIGO: string[] = [ROLE_SUPPLIER_AMERIGO]
