import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
// import {fileUploaderToggle} from 'store/actions/fileUploaderActions'
import {showModal} from 'store/actions/modalsActions'
import {logout} from 'store/actions/authActions'
import {MARKETPLACE_AMERIGO, MARKETPLACE_AUTOMOTOR} from 'constants/defaultConstants'
import {TOOLBAR_MENU_EINA,
  TOOLBAR_MENU_NAV,
  TOOLBAR_MESSAGE_SUPPLIER,
  TOOLBAR_TARIFFS_SUPPLIER} from 'constants/menuNavLinks'
import {IMenuNavLink} from 'interfaces/menu-nav-link.interface'
import {IEinaSearchParams} from 'interfaces/eina.interface'
import {getCurrentMarketplace, converObjectToParams, getURLParams} from 'utils/getData'
import {RootState} from 'store/reducers/rootReducer'
import {checkPermissionEina, usePermission} from 'utils/hooks'
import './style.scss'
import {isHostPunchOut, isInAmerigoBool} from '../../../../utils'

const ToolBarNav = (): JSX.Element => {
  const {quantityToolbar} = useSelector((state: RootState) => state.cartState)
  const {user} = useSelector((state: RootState) => state.userState)
  const [hovered, setHovered] = useState<boolean>(false)
  const marketplace:any = getCurrentMarketplace()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {unseenMessages} = useSelector((state: RootState) => state.messengerState)

  const handlerLogout = () => {
    dispatch(logout())
  }
  const hasFinancialEina = checkPermissionEina()
  const {source}: any = getURLParams(location.search)
  const {vehicleId} = useSelector((state: RootState) => state.vehicleCategoriesState)

  const handleLogoutForRoleClientPlatform = () => {
    dispatch(logout())
    if (marketplace === process.env.REACT_APP_MARKETPLACE_RETAILER) {
      return 
    }
    const callbackUrl = localStorage.getItem('callback_url')
    if (callbackUrl) {
      window.location.href = callbackUrl
    } else {
      window.location.href = 'https://www.tyrescope.co.uk'
    }
  }

  const getTitleWithNotifications = (title: string, number: number) => {
    return `${t(title)} ${number > 0 ? `(${number})` : ''}`
  }

  const openUploadAmerigoProformaModal = () => {
    dispatch(showModal({
      open: true,
    }, 'UploadAmerigoProformaModal'))
  }

  const openUploadProformaModal = () => {
    dispatch(showModal({
      open: true,
    }, 'UploadProformaModal'))
  }

  const methods: { [index: string]: () => void } = {
    handlerLogout,
    openUploadProformaModal,
    handleLogoutForRoleClientPlatform,
  }

  const MENU_NAV = Array.from(TOOLBAR_MENU_NAV)

  if (hasFinancialEina) {
    const insertAt = TOOLBAR_MENU_NAV.length - 2

    if (vehicleId) {
      const einaParams: IEinaSearchParams = {
        source,
        vehicle_id: vehicleId,
      }

      TOOLBAR_MENU_EINA.map((item, index) => {
        // Add query params to eina url
        const newItem = (item.to === '/eina' ? {
          ...item,
          to: `/eina?${converObjectToParams(einaParams)}`,
        } : item)
        // Reorder menu items
        MENU_NAV.splice((index + insertAt), 0, newItem)
      })
    } else {
      // Reorder menu items
      TOOLBAR_MENU_EINA.map((item, index) => MENU_NAV.splice((index + insertAt), 0, item))
    }
  }
  // Tarrifs suppblier for amerigo
  if (isInAmerigoBool()) {
    TOOLBAR_TARIFFS_SUPPLIER.map((item, index) => MENU_NAV.splice((index + 3), 0, item))
    TOOLBAR_MESSAGE_SUPPLIER.map((item, index) => MENU_NAV.splice((index + 3), 0, item))
  }

  return (
    <div className="toolbar-nav">
      <ul>
        <li className="favorite-page-link">
          <NavLink
            to="/favorite"
            className="links"
            title={t('SAVED_LISTS')}
          >
            <i className="material-icons">favorite</i>
          </NavLink>
        </li>
        {
          marketplace === MARKETPLACE_AUTOMOTOR
            ? (
              <li className="upload-csv">
                <NavLink
                  to=""
                  title={t('UPLOAD_FILE')}
                  className="links"
                  onClick={() => openUploadProformaModal()}
                >
                  <i className="icon icon-upload-csv" />
                  .xlsx
                </NavLink>
              </li>
            )
            : marketplace === MARKETPLACE_AMERIGO && !isHostPunchOut()
              ? (
                <li className="upload-csv">
                  <NavLink
                    to=""
                    title={t('UPLOAD_FILE')}
                    className="links"
                    onClick={() => openUploadAmerigoProformaModal()}
                  >
                    <i className="icon icon-upload-csv" />
                    .xlsx
                  </NavLink>
                </li>
              ) : null
        }

        <li className="cart">
          <NavLink
            to="/cart"
            title={t('SHOPPING_CART')}
            className="links"
          >
            <i className="material-icons">
              shopping_cart
            </i>
            {
              quantityToolbar
                ? <span className="count">{quantityToolbar > 99 ? '99+' : quantityToolbar}</span>
                : null
            }
          </NavLink>
        </li>

        <li className="user">
          <div
            className={hovered ? 'nav-tooltip active' : 'nav-tooltip'}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div className="username-container">
              <i className="material-icons">person</i>
              {user ? (user.full_name.trim() || user.email?.split('@')?.[0]) : ''}
            </div>
            <div className="tooltiptext">
              {
                MENU_NAV.map(({
                  roles,
                  marketplace_sensible,
                  to,
                  rel,
                  target,
                  iconName,
                  title,
                  callback_method,
                }: IMenuNavLink, index: number) => (
                  (marketplace_sensible?.includes(marketplace) ? marketplace_sensible : usePermission(roles)) ? (
                    <NavLink
                      key={iconName + String(index)}
                      to={{pathname: typeof to === 'string' ? to : to(`${process.env.REACT_APP_ADMIN_PANEL ?? ''}`, localStorage.getItem('token') || '')}}
                      className={location.pathname === to ? 'active-path' : ''}
                      {...(rel && {rel})}
                      {...(target && {target})}
                      title={title === 'MESSAGING' ? getTitleWithNotifications(title, unseenMessages) : t(title)}
                      onClick={() => (methods[callback_method] ? methods[callback_method]() : null)}
                    >
                      {
                        title === 'LOG_OFF' ? (
                          <div className="logout">
                            <span className="tooltip-item">{t(title)}</span>
                          </div>
                        ) : title === 'MESSAGING' ? (
                          <span
                            className="tooltip-item"
                          >
                            {getTitleWithNotifications(title, unseenMessages)}
                          </span>
                        ) : (
                          <span className="tooltip-item">{t(title)}</span>
                        )
                      }
                    </NavLink>
                  ) : null
                ))
              }
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ToolBarNav
