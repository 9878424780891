import request from 'utils/request'

export function fetchReliableCarPartsReference(query) {
  return request({
    url: '/reliable/car-parts/search',
    method: 'get',
    params: query,
  })
}

export function fetchReliableCarPartsReferenceFilter(query) {
  return request({
    url: '/reliable/car-parts/filters',
    method: 'get',
    params: query,
  })
}

export function fetchReliableCarParts(url, query) {
  return request({
    url,
    method: 'get',
    params: query,
  })
}

export function fetchReliableFilters(url, query) {
  return request({
    url,
    method: 'get',
    params: query,
  })
}

export function fetchReliableCarPartsByGenArt(vehicle_id, query) {
  return request({
    url: `/reliable/${vehicle_id}/car-parts-by-genart`,
    method: 'get',
    params: query,
  })
}

export function fetchCheckZtskStock(query) {
  return request({
    url: '/reliable/car-parts/availability',
    method: 'get',
    params: query,
  })
}
