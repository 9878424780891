import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {fileUploaderToggle} from 'store/actions/fileUploaderActions'
import {logout} from 'store/actions/authActions'
import {MENU_NAV_LINK_AF, TOOLBAR_MENU_NAV_AF} from 'constants/menuNavLinksAF'
import {showModal} from 'store/actions/modalsActions'
import {getCurrentMarketplace} from 'utils/getData'

import './style.scss'

const MenuSideDrawerAF = ({backdropClickHandler}) => {
  const {user} = useSelector(state => state.userState)
  const marketplace = getCurrentMarketplace()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const location = useLocation()

  const closeMenuSideDrawerClickHandler = () => {
    // dispatch(resetCarParts())
    backdropClickHandler(false)
  }

  const handlerLogout = () => {
    dispatch(logout())
  }

  const handleLogoutForRoleClientPlatform = () => {
    dispatch(logout())
    const callbackUrl = localStorage.getItem('callback_url')
    if (callbackUrl) {
      window.location.href = callbackUrl
    }
  }

  const handleCsvClick = () => {
    closeMenuSideDrawerClickHandler()
    dispatch(fileUploaderToggle())
  }

  const openUploadProformaModal = () => {
    closeMenuSideDrawerClickHandler()
    dispatch(showModal({
      open: true,
    }, 'UploadProformaModal'))
  }

  const methods = {
    closeMenuSideDrawerClickHandler,
    handlerLogout,
    handleCsvClick,
    openUploadProformaModal,
    handleLogoutForRoleClientPlatform,
  }

  return (
    <nav className="menu-side-drawer open-drawer">
      <ul>
        {
          [...MENU_NAV_LINK_AF, ...TOOLBAR_MENU_NAV_AF].map(({
            to,
            rel,
            target,
            liClassName,
            iconClassName,
            iconName,
            title,
            callback_method,
          }, index) => (
            marketplace ? (
              <li
                {...(liClassName && {className: liClassName})}
                key={iconName + index}
              >
                <NavLink
                  to={{pathname: typeof to === 'string' ? to : to(`${process.env.REACT_APP_ADMIN_PANEL}`, localStorage.getItem('token') || '')}}
                  className={location.pathname === to ? 'active-path' : ''}
                  {...(rel && {rel})}
                  {...(target && {target})}
                  title={t(title)}
                  onClick={() => (methods[callback_method] ? methods[callback_method]() : null)}
                >
                  <i className={iconClassName}>{iconName}</i>
                  <span>{title ? t(title) : (user ? user.full_name : '')}</span>
                </NavLink>
              </li>
            ) : null
          ))
        }
      </ul>
    </nav>
  )
}

export default MenuSideDrawerAF
