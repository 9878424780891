import {ACTION_RESET_CAR_SELECTORS, ACTION_RESET_TRUCK_SELECTORS, ACTION_SET_INIT_STATE_SELECTORS} from 'constants/actionsTypes'

export const initialState = {
  resetCarSelectors: null,
  resetTruckSelectors: null,
}

export default function selectorReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_RESET_CAR_SELECTORS:
      return {
        ...state,
        resetCarSelectors: true,
      }

    case ACTION_RESET_TRUCK_SELECTORS:
      return {
        ...state,
        resetTruckSelectors: true,
      }

    case ACTION_SET_INIT_STATE_SELECTORS:
      return {
        ...state,
        resetCarSelectors: null,
        resetTruckSelectors: null,
      }

    default:
      return state
  }
}
