import React, {Suspense, lazy, memo} from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {retryPromise as retry} from 'utils/retry_promise'
import MainLayout from 'layouts/MainLayout' // footer need to be in the bottom
import Spinner from 'components/Spinner'
import {usePermission} from '../../utils/hooks'
import {ROLE_ADV, ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SELLER} from '../../constants/roles'

const FavoriteList = lazy(() => retry(() => import('pages/FavoriteList')))
const Home = lazy(() => retry(() => import('pages/PunchOut/Home')))
const CarPart = lazy(() => retry(() => import('pages/CarPart')))
const History = lazy(() => retry(() => import('pages/History')))
const Cart = lazy(() => retry(() => import('pages/Cart')))
const OrderList = lazy(() => retry(() => import('pages/OrderList')))
const Order = lazy(() => retry(() => import('pages/Order')))
const Checkout = lazy(() => retry(() => import('pages/Checkout')))
const Settings = lazy(() => retry(() => import('pages/Settings')))
const Error404 = lazy(() => retry(() => import('pages/Error/404')))
const Main = lazy(() => retry(() => import('pages/Reliable/Main')))
const ReliableContent = lazy(() => retry(() => import('pages/Reliable/ReliableContent')))
const VehicleSubcategories = lazy(() => retry(() => import('pages/Reliable/VehicleCategories/VehicleSubcategories')))
const CarPartsReliable = lazy(() => retry(() => import('pages/Reliable/CarPartsReliable')))
const CarPartsReference = lazy(() => retry(() => import('pages/Reliable/CarPartsReference')))
const OilsReference = lazy(() => retry(() => import('pages/Reliable/Oil/OilsReference')))
const ConsumablesReference = lazy(() => retry(() => import('pages/Reliable/Consumable/ConsumablesReference')))
const TyresReference = lazy(() => retry(() => import('pages/Reliable/Tyres/TyresReference')))
const CarOilItem = lazy(() => retry(() => import('pages/Reliable/Oil/CarOils/CarOilItem')))
const CarTyreItem = lazy(() => retry(() => import('pages/Reliable/Tyres/CarTyres/CarTyreItem')))
const CarConsumableItem = lazy(() => retry(() => import('pages/Reliable/Consumable/CarConsumables/CarConsumableItem')))
const BatteryReference = lazy(() => retry(() => import('pages/Reliable/Battery/BatteryReference')))
const CarBatteryItem = lazy(() => retry(() => import('pages/Reliable/Battery/CarBattery/CarBatteryItem')))
const Catalog = lazy(() => retry(() => import('pages/Catalog')))
const CarPartsGenArt = lazy(() => retry(() => import('pages/Reliable/ReliableContent/CarPartsGenArt')))
const Messages = lazy(() => retry(() => import('pages/EspaceClient/Messaging')))
const Tariffs = lazy(() => retry(() => import('pages/EspaceClient/Tariffs')))
const ClientList = lazy(() => retry(() => import('pages/ClientList')))
const FinancialDeadline = lazy(() => retry(() => import('pages/Financial/FinancialDeadline')))
const FinancialData = lazy(() => retry(() => import('pages/Financial/FinancialData')))
const PrivateRoutesPunchOut = memo(() => {
  const location = useLocation()
  const hasFinancialPermission = usePermission([ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SELLER])
  const hasSellerPermission = usePermission([ROLE_SELLER, ROLE_ADV])

  const checkPathname = () => {
    let router = <Route component={Error404} />

    if (location.pathname === '/' || location.pathname.includes('login')) {
      router = <Redirect to="/" />
    }
    return router
  }
  return (
    <MainLayout location={location}>
      <Suspense fallback={<Spinner class="small" />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/reliable" component={Main} />
          <Route path="/reliable/car-parts/view/:id" component={CarPart} />
          <Route path="/reliable/rmi/:vehicle_id/:tab_name" component={ReliableContent} />
          <Route path="/reliable/:vehicle_id/polk-subcategory" component={VehicleSubcategories} />
          <Route path="/reliable/:vehicle_id/:product_id/:reference_name/car-parts" component={CarPartsReliable} />
          <Route path="/reliable/:vehicle_id/car-parts" component={CarPartsReliable} />
          <Route path="/reliable/:vehicle_id/car-parts-by-genart" component={CarPartsGenArt} />
          <Route path="/reliable/car-parts/search" component={CarPartsReference} />
          <Route path="/reliable/oil/search" component={OilsReference} />
          <Route path="/reliable/consumable/search" component={ConsumablesReference} />
          <Route path="/reliable/battery/search" component={BatteryReference} />
          <Route path="/reliable/tyres/search" component={TyresReference} />
          <Route path="/reliable/oil/view/:id" component={CarOilItem} />
          <Route path="/reliable/tyre/view/:id" component={CarTyreItem} />
          <Route path="/reliable/consumable/view/:id" component={CarConsumableItem} />
          <Route path="/reliable/battery/view/:id" component={CarBatteryItem} />
          <Route exact path="/flipbook" />
          <Route path="/car-parts/view/:id" component={CarPart} />
          <Route path="/cart" component={Cart} />
          <Route path="/history" component={History} />
          <Route path="/orders/:order_id" component={Order} />
          <Route path="/orders" component={OrderList} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/car-parts" component={Catalog} />
          <Route path="/favorite" component={FavoriteList} />
          <Route path="/messaging" component={Messages} />
          <Route path="/tariffs" component={Tariffs} />
          {
            hasFinancialPermission && <Route path="/remaining" component={FinancialData} />
          }
          {
            hasFinancialPermission && <Route path="/financial" component={FinancialDeadline} />
          }

          {
            hasSellerPermission && <Route path="/select-client" component={ClientList} />
          }
          <Route path="/settings" component={Settings} />
          {checkPathname()}
        </Switch>
      </Suspense>
    </MainLayout>
  )
})

export default PrivateRoutesPunchOut
