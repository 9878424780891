import React, {useEffect, useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {countries, languages} from 'constants/locales'
import Selector from 'components/Selector'
import {updateAccountSettings} from 'store/actions/userActions'
import {getCurrentMarketplace} from 'utils/getData'
import './style.scss'

const FooterPublic = () => {
  const {i18n} = useTranslation()
  const {t} = useTranslation()
  const language = i18n.language.split('-')
  const [currentLanguage, setCurrentLanguage] = useState(language ? language[0] : '')
  const [currentCountry, setCurrentCountry] = useState(language ? language[1] : '')
  const user = useSelector(state => state.userState.user)
  const localToken = localStorage.getItem('token')

  const marketplace = getCurrentMarketplace()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      setCurrentLanguage(user.language)
    }
  }, [user])

  useEffect(() => {
    setCurrentLanguage(i18n.language.split('-')[0])
    i18n.changeLanguage(i18n.language)
  }, [i18n.language])

  const changeLanguage = e => {
    const lang = e.target.value

    setCurrentLanguage(lang)
    localStorage.setItem('language', `${lang}-${i18n.language.split('-')[1]}`)
    i18n.changeLanguage(`${lang}-${i18n.language.split('-')[1]}`)

    if (localToken) {
      dispatch(updateAccountSettings({language: lang}, false))
    }
  }

  const changeCountry = country => {
    const language = `${i18n.language.split('-')[0]}-${country}`
    setCurrentCountry(country)
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  const showLogo = () => (
    <NavLink to="/" className={marketplace}>
      <img src={`/images/logos/reliable_logo.svg`} alt={marketplace} />
    </NavLink>
  )
  const showTecAllianceLogo = () => (
    <div className={marketplace}>
      <img src={`/images/logos/tecalliance_logo.jpeg`} alt={marketplace} />
    </div>
  )

  return (
    <div className="public-wrapper-footer">
      <div className="footer">
        <div className="footer-logo-languages">
         
          <div className="languages">
          <div className="footer-logo">
            {showLogo()}
          </div>
            <div className="dropdown dropdown-languages">
              <div className="wrapper-languages">
                <Selector
                  selectId="select-language"
                  value={currentLanguage}
                  data={languages}
                  selectHandler={e => changeLanguage(e, language.code)}
                />
              </div>
            </div>
            <div className="legale">
               Copyright 2022 | V2.1.0 | <a href='/legales_reliable.pdf' target='_blank' rel='noopener noreferrer'>{t('MENTION_LEGALE')}</a>
            </div>
            <div className="footer-logo" style={{marginLeft: 10}}>
            {showTecAllianceLogo()}
          </div>
          </div>
          <div className='footer-terms-conditions'>
              <div className='terms-conditions'>
              {t('PUBLIC_TECALLIANCE')}
              </div>
              <div className='terms-conditions'>
              {t('PUBLIC_TERMS_AND_CONDITIONS')}
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterPublic
