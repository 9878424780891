import {
  ACTION_SET_CAR_PARTS,
  ACTION_RESET_CAR_PARTS,
  ACTION_FETCH_CAR_PARTS,
  ACTION_FILTER_CAR_PARTS,
  ACTION_CLEAR_FILTER_CAR_PARTS,
  ACTION_SET_CAR_PARTS_FILTERS,
  ACTION_FETCH_CAR_PARTS_FAIL,
  ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND,
  ACTION_FETCH_CAR_PARTS_IN_BACKGROUND,
  ACTION_SET_CAR_PARTS_WITH_REFERENCE,
  ACTION_SET_CAR_PARTS_FILTER_RELIABLE,
  ACTION_RESET_CAR_PARTS_FILTER,
  ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST,
} from 'constants/actionsTypes'

export const initialState = {
  carParts: null,
  filters: null,
  loading: false,
  loadingInBackGround: false,
  filterBy: {},
  outOfoffer: null,
  page: null,
  total_pages: null,
}

export default function carPartsReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_SET_CAR_PARTS:
      return {
        ...state,
        loading: false,
        carParts: action.carParts,
        outOfOffer: action.outOfOffer,
        page: action.page,
        total_pages: action.total_pages,
      }

    case ACTION_SET_CAR_PARTS_WITH_REFERENCE:
      return {
        ...state,
        loading: false,
        filters: action.filters,
        carParts: action.carParts,
        outOfOffer: action.outOfOffer,
      }

    case ACTION_SET_CAR_PARTS_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }

    case ACTION_FETCH_CAR_PARTS:
      return {
        ...state,
        loading: true,
      }

    case ACTION_FETCH_CAR_PARTS_IN_BACKGROUND:
      return {
        ...state,
        loadingInBackGround: true,
      }

    case ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND:
      if (action.total_pages == 1) {
        state.carParts = action.carParts
      } else {
        state.carParts = [...state.carParts, ...action.carParts]
      }
      return {
        ...state,
        loadingInBackGround: false,
        outOfOffer: action.outOfOffer,
        page: action.page,
        carParts: state.carParts,
        total_pages: action.total_pages,
      }

    case ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST:
     
      return {
        ...state,
        loading: false,
        outOfOffer: action.outOfOffer,
        carParts: action.carParts,
      } 

    case ACTION_SET_CAR_PARTS_FILTER_RELIABLE:
      return {
        ...state,
        loadingInBackGround: false,
        outOfOffer: action.outOfOffer,
        loading: false,
        page: action.page,
        carParts: action.carParts,
        total_pages: action.total_pages,
      }

    case ACTION_FETCH_CAR_PARTS_FAIL:
      return {
        ...state,
        loading: false,
      }

    case ACTION_RESET_CAR_PARTS:
      return {
        ...state,
        carParts: null,
        filters: null,
        outOfOffer: null,
        page: null,
        total_pages: null,
      }

    case ACTION_RESET_CAR_PARTS_FILTER:
      return {
        ...state,
        carParts: [],
        loading: true,

      }

    case ACTION_FILTER_CAR_PARTS:
      return {
        ...state,
        filterBy: action.filterBy,
      }

    case ACTION_CLEAR_FILTER_CAR_PARTS:
      return {
        ...state,
        filterBy: {},
      }

    default:
      return state
  }
}
