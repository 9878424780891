import React from 'react'
import {useTranslation} from 'react-i18next'

import Selector from 'components/Selector'

const ModelDropdown = props => {
  const {t} = useTranslation()

  const handleSelectModel = event => {
    const data = event.target.value
    props.handleGrabModel(data)
  }
  return (
    <Selector
      title={t('MODEL')}
      selectId={`select-model-${props.id}`}
      value={props.currentModel}
      disabledState={!props.currentManufacture}
      data={props.models}
      selectHandler={handleSelectModel}
      loading={props.loading}
      selectValueObject={true}
    />
  )
}

export default ModelDropdown
