import React from 'react'
import {useTranslation} from 'react-i18next'

import Selector from 'components/Selector'

const FuelDropdown = props => {
  const {t} = useTranslation()

  const handleSelectFuel = event => {
    const data = event.target.value
    props.handleGrabFuel(data)
  }

  return (
    <Selector
      title={t('FUEL')}
      selectId={`select-fuel-${props.id}`}
      value={props.currentFuel}
      disabledState={!props.currentGeneration}
      data={props.fuels}
      selectHandler={handleSelectFuel}
      loading={props.loading}
    />
  )
}

export default FuelDropdown
