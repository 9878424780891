import React from 'react'
import './style.scss'

interface IProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<IProgressBarProps> = ({percentage}): JSX.Element => (
  <div className="progress-bar-container">
    <div
      className="progress-bar-container box"
      style={{
        width: `${percentage}%`,
      }}
    >
      {`${percentage}%`}
    </div>
  </div>
)

export default ProgressBar
