import React from 'react'
import './style.scss'

interface IProgressCircularProps {
  percentage: number;
}

const ProgressCircular: React.FC<IProgressCircularProps> = ({percentage}): JSX.Element => {
  const getStyleForContainer = () => {
    if (percentage > 50) {
      return {
        clip: 'rect(auto, auto, auto, auto)',
      }
    }

    return {}
  }

  const getStyleForRightSide = () => {
    if (percentage <= 50) {
      return {
        display: 'none',
      }
    }

    return {
      transform: 'rotate(180deg)',
    }
  }

  return (
    <div className="progress-circular-container">
      <span className="label">
        {percentage}
        <span className="smaller">
          %
        </span>
      </span>
      <div
        style={getStyleForContainer()}
        className="circle"
      >
        <div
          style={{
            transform: `rotate(${percentage * 3.6}deg)`,
          }}
          className="half-circle"
        />
        <div
          style={getStyleForRightSide()}
          className="half-circle"
        />
      </div>
    </div>
  )
}

export default ProgressCircular
