import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './styles.scss'
import axios, {Canceler} from 'axios'
import {useDispatch} from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import {fetchSearchReliableConsumables} from 'api/reliable/search'
import {useOnClickOutside} from 'utils/hooks'
import Spinner from 'components/Spinner'

import {IConsumableSearch} from 'store/types/consumableTypes'
import {converObjectToParams, getCurrentDomain} from 'utils/getData'
import {handleConsumableFullSearch} from 'utils/consumable'
import {resetConsumableSearchFilter} from 'store/actions/reliable/consumableSearchActions'

interface ISearch {
    activeButtonIndex: string
}

interface IConsumable {
    brand_id: number,
    brand_name: string,
    category_id: number,
    category_name: string,
    conditioning: string,
    reference: number,
    unity: string,
    consumable_id: number,
    image:string
    
}

const CancelToken = axios.CancelToken
let cancel: Canceler | undefined

const ConsumableSearch: React.FC<ISearch> = ({activeButtonIndex}): JSX.Element => {
  const [carReference, setCarReference] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const refReference = useRef() as React.MutableRefObject<HTMLInputElement>
  const {t} = useTranslation()
  const [showSpinner, setShowSpinner] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const [suggestions, setSuggestions] = useState<IConsumable[]>([])

  useOnClickOutside(refReference, () => setShowSuggestions(false))

  const submitHandler = (e: any) => {
    e.preventDefault()
    getConsumables({q: inputRef?.current?.value})
  }
  const clickReferenceHandler = (consumable: IConsumable) => {
    const data: IConsumableSearch = {
      q: inputRef?.current?.value,
      consumable_id: String(consumable.consumable_id),
      'category_ids[]': [String(consumable.category_id)],
      'brand_ids[]': [String(consumable.brand_id)],
    }

    handleConsumableFullSearch(data, consumable, dispatch)

    history.push({
      pathname: '/reliable/consumable/search',
      search: `?${converObjectToParams(data)}`,
      key: 'SearchReference',
    })
    setShowSuggestions(false)
    setCarReference('')
  }

  const generateReferenceSearchLink = (consumable:IConsumable) => {
    const data: IConsumableSearch = {
      q: inputRef?.current?.value,
      consumable_id: String(consumable.consumable_id),
      'category_ids[]': [String(consumable.category_id)],
      'brand_ids[]': [String(consumable.brand_id)],
    }
    return `/reliable/consumable/search?${converObjectToParams(data)}`
  }

  const clickCategoryHandler = (consumable: IConsumable) => {
    const data: IConsumableSearch = {
      q: inputRef?.current?.value,
      'category_ids[]': [String(consumable.category_id)],
    }

    // if (consumable.category_id) {
    //   data['category_id[]']?.push(String(consumable.category_id))
    // } else {
    //   delete data['category_id[]']
    // }

    dispatch(resetConsumableSearchFilter())
    handleConsumableFullSearch(data, null, dispatch)

    history.push({
      pathname: '/reliable/consumable/search',
      search: `?${converObjectToParams(data)}`,
      key: 'SearchReference',
    })
    setShowSuggestions(false)
    setCarReference('')
  }

  const getConsumables = (listQuery: any) => {
    setShowSuggestions(true)
    setShowSpinner(true)
    if (cancel !== undefined) {
      cancel()
    }

    fetchSearchReliableConsumables(listQuery, {
      cancelToken: new CancelToken((c => {
        cancel = c
      })),
    })
      .then(({data}) => {
        setSuggestions(data)
        setShowSpinner(false)
      }).catch(() => {
        console.log('cancel')
      })
  }

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim()
    setCarReference(inputData)
    if (inputData.length >= 6) {
      getConsumables({q: inputData})
    } else {
      setShowSpinner(false)
      setShowSuggestions(false)
      setSuggestions([])
    }
  }
  const clearCarReference = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setCarReference('')
    setShowSuggestions(false)
    setSuggestions([])
  }

  return (
    <div
      ref={refReference}
      className={activeButtonIndex !== 'consumable' ? 'd-none' : 'consumable-reference-container'}
    >
      <div className="consumable-search">
        <form
          className="search-form custom-search-form"
          onSubmit={e => submitHandler(e)}
        >

          <div
            className="inner-search-container"
          >
            <div
              className="search-container"
            >
              <input
                ref={inputRef}
                className="search-header form-control"
                type="text"
                placeholder={t('SEARCH_BY_CONSUMABLE')}
                aria-label="Search"
                value={carReference}
                onChange={updateSearchValue}
              />
              {carReference && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearCarReference} />}

            </div>
            <button
              className={`button-style search-toolbar-button desktop ${carReference ? 'button-style-hover' : 'button-disabled-style'}`}
              type="submit"
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </form>
      </div>
      <div
        className={`result-search  ${suggestions.length ? '' : 'no-result'} ${showSuggestions ? 'block' : 'hidden'}`}
      >

        <div className="wrapper">
          <div className="suggestion-title">{t('SEARCH_CONSUMABLE_RESULTS')}</div>
        </div>
        <ul className="suggestions">
          {showSpinner
            ? <Spinner type="skeleton-line" />
            : suggestions.length ? suggestions.map((item, index) => (
              <li
                key={index}
                className="suggestion-list-item"
              >

                <div className="suggestion-item-title">

                  <img src={`${getCurrentDomain() ?? ''}${item.image}`} style={{marginRight: '10px'}} width="25" height="25" alt="consumable" />

                  <div
                    className="reference-consumable-wrapper"
                  >
                    {item.consumable_id ? (
                      <NavLink
                        to={generateReferenceSearchLink(item)}
                        onClick={() => clickReferenceHandler(item)}
                      >

                        {item.conditioning}
                        {' '}
                        {item.reference}
                      </NavLink>
                    ) : (
                      <span
                        className="reference-consumable"
                        onClick={() => clickReferenceHandler(item)}
                      > 
                        {item.conditioning}
                        {' '}
                        {item.reference}
                      </span>
                    )}
                  </div>

                  <div
                    className="category-consumable-wrapper"
                  >
                    <span
                      onClick={() => clickCategoryHandler(item)}
                      className="category-consumable"
                    >
                      {item.category_name}
                    </span>
                  </div>

                </div>
              </li>
            )) : <div className="no-suggestions">{`${t('NO_CONSUMABLE_RESULTS')}!`}</div>}
        </ul>
      </div>
    </div>
  )
}

export default ConsumableSearch
