import {ACTION_UPDATE_CATEGORIES, FETCH_UPDATE_CATEGORIES} from 'constants/actionsTypes'
import {fetchCategories} from 'api/catalogs'

export const fetchCategoriesDispatch = (data, cancelToken) => dispatch => {
  dispatch({type: FETCH_UPDATE_CATEGORIES})
  fetchCategories(data, cancelToken)
    .then(response => {
      dispatch({
        type: ACTION_UPDATE_CATEGORIES,
        categories: response.data,
      })
    })
}

export const resetCategories = () => dispatch => {
  dispatch({type: FETCH_UPDATE_CATEGORIES})
  dispatch({type: ACTION_UPDATE_CATEGORIES, categories: null})
}
