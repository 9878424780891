import {IConsumableActionTypes, IConsumableGlobalState} from '../../types/consumableTypes'
import {
  ACTION_FETCH_CONSUMABLE_FILTERS,
  ACTION_FETCH_CONSUMABLE_ITEM,
  ACTION_FETCH_CONSUMABLE_SEARCH,
  ACTION_LOADING_CONSUMABLE_FILTERS, ACTION_LOADING_CONSUMABLE_ITEM,
  ACTION_LOADING_CONSUMABLE_SEARCH,
  ACTION_RESET_CONSUMABLE_FILTERS,
  ACTION_RESET_CONSUMABLE_SEARCH,
} from '../../../constants/actionsTypes'

const initialState: IConsumableGlobalState = {
  consumable: null,
  consumableLoading: false,
  loading: false,
  filterLoading: false,
  filters: null,
  pagination: null,

}

export default function consumableSearchReducer(state = initialState, action: IConsumableActionTypes = {
  type: ACTION_FETCH_CONSUMABLE_SEARCH,
  payload: null,
}): IConsumableGlobalState {
  switch (action.type) {
    case ACTION_FETCH_CONSUMABLE_ITEM:
      return {
        ...state,
        consumable: action?.payload || null,
      }
    case ACTION_LOADING_CONSUMABLE_ITEM:
      return {
        ...state,
        consumableLoading: action.payload,
      }
    case ACTION_FETCH_CONSUMABLE_SEARCH:
      return {
        ...state,
        loading: false,
        pagination: action?.payload?.data,

      }
    case ACTION_RESET_CONSUMABLE_SEARCH:
      return {
        ...state,
        consumable: null,
        pagination: null,
        loading: false,
      }
    case ACTION_FETCH_CONSUMABLE_FILTERS:
      return {
        ...state,
        filters: action.payload ?? null,
        filterLoading: false,
      }
    case ACTION_LOADING_CONSUMABLE_FILTERS:
      return {
        ...state,
        filterLoading: action.payload,
      }
    case ACTION_LOADING_CONSUMABLE_SEARCH:
      return {
        ...state,
        loading: action.payload,
      }

    case ACTION_RESET_CONSUMABLE_FILTERS:
      return {
        ...state,
        filters: null,
      }
    default:
      return state
  }
}
