import React, {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Spinner from 'components/Spinner'
import {ReactComponent as SearchCarIcon} from 'icons/search/car.svg'
import {ReactComponent as SearchReferenceIcon} from 'icons/search/reference.svg'
import {ReactComponent as SearchTruckIcon} from 'icons/search/truck.svg'
import {ReactComponent as SearchVinIcon} from 'icons/search/vin.svg'
import {ReactComponent as SearchOilIcon} from 'icons/search/oils.svg'
import {ReactComponent as ConsumableIcon} from 'icons/search/consumable.svg'
import {ReactComponent as SearchTiresIcon} from 'icons/search/tires.svg'
import {ReactComponent as SearchPlateIcon} from 'icons/search/plate.svg'
import {ReactComponent as SearchTabsIcon} from 'icons/search/tabs.svg'
import {ReactComponent as SearchBatteryIcon} from 'icons/search/car-battery.svg'

import {converObjectToParams, getURLParamsArray} from 'utils/getData'
import {useHistory} from 'react-router-dom'
import SearchReference from './SearchReference'
import SearchVin from './SearchVin'
import SearchPlateNumber from './SearchPlateNumber'
import VehicleSelector from './VehicleSelector'
import TruckSelector from './TruckSelector'
import SearchVRM from './SearchVRM'
import TyresSearch from './TyresSearch'
import OilSearch from './OilSearch'
import ConsumableSearch from './ConsumableSearch'
import {isHostRetailer} from '../../../utils'
import './style.scss'
import BatterySearch from './BatterySearch'

const countries = ['IT', 'ES', 'FR']

const ReliableSearch = () => {
  const urlParams = getURLParamsArray(location.search)
  const [activeButtonIndex, setActiveButtonIndex] = useState('reference')
  const [activeButtonRetailer, setActiveButtonRetailer] = useState('reference')
  const {user} = useSelector(state => state.userState)
  const [hideTab, setHideTab] = useState(true)
  const [showRefRetailer, setShowRefRetailer] = useState(true)
  const [showVrmLookup, setShowVrmLookup] = useState()
  const history = useHistory()
  const dispatch = useDispatch()
  const stableDispatch = useCallback(dispatch, [])
  const {t} = useTranslation()

  const activateTab = tab => {
    setActiveButtonIndex(tab)
    urlParams.tab = tab
    if (isHostRetailer()) {
      if (tab == 'oil' || tab == 'tyres' || tab == 'consumable' || urlParams.tab == 'battery') {
        setShowRefRetailer(false)
        setActiveButtonRetailer(tab)
        delete urlParams.tabs
      } else {
        setShowRefRetailer(true)
        setActiveButtonRetailer('reference')
      }
    }
    
    history.replace({
      search: `${converObjectToParams(urlParams)}`,
    })
  }

  useEffect(() => {
    if (isHostRetailer()) {
      if (urlParams.tab == 'oil' || urlParams.tab == 'tyres' || urlParams.tab == 'consumable' || urlParams.tab == 'battery') {
        setShowRefRetailer(false)
        setActiveButtonRetailer(urlParams.tab)
        setActiveButtonIndex(urlParams.tab)
      } else {
        setShowRefRetailer(true)
        setActiveButtonRetailer('reference')
        setActiveButtonIndex(urlParams.tab || 'reference')
      }
    }
  }, [stableDispatch])

  useEffect(() => {
    if (user) {
      if (countries.includes(user.client_country_code)) {
        setHideTab(false)
      }
      if (!user.client_settings.plate_number_search) {
        setHideTab(true)
      }
      if (user.vrm_lookup) {
        setActiveButtonIndex('vrm')
      }
      setShowVrmLookup(user.vrm_lookup)
    }
  }, [user])

  return (
    <div className={`reliable-search small-reliable-search ${activeButtonIndex}`}>
      <div
        className="search-options search-options-margin"
        style={{
          flexDirection: isHostRetailer() ? 'column' : 'initial',
        }}
      >
        {
          user
            ? (
              isHostRetailer() 
                ? (  
                  <>
                    <div>
                      <button
                        className={`${activeButtonRetailer === 'reference' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                        type="submit"
                        onClick={() => activateTab('reference')}
                      >
                        <span className="icon"><SearchTabsIcon /></span>
                        <span className="icon-text">{t('CATALOG')}</span>

                      </button>

                      {
                        user.hasTyre
                          ? (
                            <button
                              className={`${activeButtonIndex === 'tyres' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                              type="submit"
                              onClick={() => activateTab('tyres')}
                            >
                              <span className="icon"><SearchTiresIcon /></span>
                              <span className="icon-text">{t('TYRES')}</span>

                            </button>
                          ) : null
                      }

                      {
                        user.hasOil
                          ? (
                            <button
                              className={`${activeButtonIndex === 'oil' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                              type="submit"
                              onClick={() => activateTab('oil')}
                            >
                              <span className="icon"><SearchOilIcon /></span>
                              <span className="icon-text">{t('OIL')}</span>

                            </button>
                          ) : null
                      }
                      {
                        user.hasConsumable
                          ? (
                            <button
                              className={`${activeButtonIndex === 'consumable' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                              type="submit"
                              onClick={() => activateTab('consumable')}
                            >
                              <span className="icon"><ConsumableIcon /></span>
                              <span className="icon-text">{t('CONSUMABLES')}</span>
                            </button>
                          ) : null
                      }
                      {
                        user.hasBattery
                          ? (
                            <button
                              className={`${activeButtonIndex === 'battery' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                              type="submit"
                              onClick={() => activateTab('battery')}
                            >
                              <span className="icon"><SearchBatteryIcon /></span>
                              <span className="icon-text">{t('BATTERY')}</span>
                            </button>
                          ) : null
                      }

                    </div>
                    {showRefRetailer 
                      ? (
                        <div className="ref-tabs">
                          <button
                            className={`${activeButtonIndex === 'reference' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                            type="submit"
                            onClick={() => activateTab('reference')}
                          >
                            <span className="icon"><SearchReferenceIcon /></span>
                            <span className="icon-text">{t('REFERENCE')}</span>

                          </button>

                          <button
                            className={
                              `${activeButtonIndex === 'vin' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`
                            }
                            type="submit"
                            onClick={() => activateTab('vin')}
                          >
                            <span className="icon"><SearchVinIcon /></span>
                            <span className="icon-text">{t('VIN')}</span>

                          </button>
                       
                          <button
                            className={
                              `${activeButtonIndex === 'plate'
                                ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button ${hideTab ? 'hide-tab' : ''}`
                            }
                            type="submit"
                            onClick={() => activateTab('plate')}
                          >
                            <span className="icon"><SearchPlateIcon /></span>
                            <span className="icon-text">{t('IMMAT')}</span>

                          </button>

                          <button
                            className={
                              `${activeButtonIndex === 'vrm' ? 'button-style' 
                                : 'button-style-outline button-outline-style-hover'} search-option-button ${!showVrmLookup ? 'hide-tab' : ''}`
                            }
                            type="submit"
                            onClick={() => activateTab('vrm')}
                          >
                            <span className="icon"><SearchPlateIcon /></span>
                            <span className="icon-text">{t('VRM_LOOKUP')}</span>

                          </button>

                          <button
                            className={`${activeButtonIndex === 'vehicule' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                            type="submit"
                            onClick={() => activateTab('vehicule')}
                          >
                            <span className="icon"><SearchCarIcon /></span>
                            <span className="icon-text">{t('CAR')}</span>

                          </button>

                          <button
                            className={`${activeButtonIndex === 'truck' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                            type="submit"
                            onClick={() => activateTab('truck')}
                          >
                            <span className="icon"><SearchTruckIcon /></span>
                            <span className="icon-text">{t('TRUCK')}</span>

                          </button>
                        </div>
                      )
                      : null }
                  </>
                )
                : (
                  <>
                    <button
                      className={`${activeButtonIndex === 'reference' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                      type="submit"
                      onClick={() => activateTab('reference')}
                    >
                      <span className="icon"><SearchReferenceIcon /></span>
                      <span className="icon-text">{t('REFERENCE')}</span>

                    </button>

                    <button
                      className={
                        `${activeButtonIndex === 'vin' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`
                      }
                      type="submit"
                      onClick={() => activateTab('vin')}
                    >
                      <span className="icon"><SearchVinIcon /></span>
                      <span className="icon-text">{t('VIN')}</span>

                    </button>

                    <button
                      className={
                        `${activeButtonIndex === 'plate'
                          ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button ${hideTab ? 'hide-tab' : ''}`
                      }
                      type="submit"
                      onClick={() => activateTab('plate')}
                    >
                      <span className="icon"><SearchPlateIcon /></span>
                      <span className="icon-text">{t('IMMAT')}</span>

                    </button>

                    <button
                      className={
                        `${activeButtonIndex === 'vrm' ? 'button-style' : 'button-style-outline button-outline-style-hover'} 
                        search-option-button ${!showVrmLookup ? 'hide-tab' : ''}`
                      }
                      type="submit"
                      onClick={() => activateTab('vrm')}
                    >
                      <span className="icon"><SearchPlateIcon /></span>
                      <span className="icon-text">{t('VRM_LOOKUP')}</span>

                    </button>

                    <button
                      className={`${activeButtonIndex === 'vehicule' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                      type="submit"
                      onClick={() => activateTab('vehicule')}
                    >
                      <span className="icon"><SearchCarIcon /></span>
                      <span className="icon-text">{t('CAR')}</span>

                    </button>

                    <button
                      className={`${activeButtonIndex === 'truck' ? 'button-style' : 'button-style-outline button-outline-style-hover'} search-option-button`}
                      type="submit"
                      onClick={() => activateTab('truck')}
                    >
                      <span className="icon"><SearchTruckIcon /></span>
                      <span className="icon-text">{t('TRUCK')}</span>

                    </button>
                  </>
                )
            )
            : <Spinner class="small" />
        }
      </div>

      <div className="search-wrapper">
        <SearchReference
          activeButtonIndex={activeButtonIndex}
        />
        <SearchVin
          activeButtonIndex={activeButtonIndex}
        />
        <SearchPlateNumber
          activeButtonIndex={activeButtonIndex}
        />
        {
          showVrmLookup ? <SearchVRM activeButtonIndex={activeButtonIndex} /> : null
        }
        <VehicleSelector
          activeButtonIndex={activeButtonIndex}
        />
        <TruckSelector
          activeButtonIndex={activeButtonIndex}
        />
        {
          isHostRetailer()
            ? (
              <div>
                <TyresSearch activeButtonIndex={activeButtonIndex} />
                <OilSearch activeButtonIndex={activeButtonIndex} />
                <ConsumableSearch activeButtonIndex={activeButtonIndex} />
                <BatterySearch activeButtonIndex={activeButtonIndex} />
              </div>
            ) : null
        }

      </div>
    </div>
  )
}

export default ReliableSearch
