import {ITyreActionTypes, ITyreGlobalState} from '../../types/tyreTypes'
import {
  ACTION_FETCH_TYRE_FILTERS,
  ACTION_FETCH_TYRE_ITEM,
  ACTION_FETCH_TYRE_SEARCH,
  ACTION_LOADING_TYRE_FILTERS, 
  ACTION_LOADING_TYRE_ITEM,
  ACTION_LOADING_TYRE_SEARCH,
  ACTION_RESET_TYRE_SEARCH,
} from '../../../constants/actionsTypes'

const initialState: ITyreGlobalState = {
  tyre: null,
  tyreLoading: false,
  loading: false,
  filterLoading: false,
  filters: null,
  pagination: null,
}

export default function tyreSearchReducer(state = initialState, action: ITyreActionTypes = {
  type: ACTION_FETCH_TYRE_SEARCH,
  payload: null,
}): ITyreGlobalState {
  switch (action.type) {
    case ACTION_FETCH_TYRE_ITEM:
      return {
        ...state,
        tyre: action?.payload || null,
      }
    case ACTION_LOADING_TYRE_ITEM:
      return {
        ...state,
        tyreLoading: action.payload,
      }
    case ACTION_FETCH_TYRE_SEARCH:
      return {
        ...state,
        loading: false,
        pagination: action?.payload?.data,

      }
    case ACTION_RESET_TYRE_SEARCH:
      return {
        ...state,
        tyre: null,
        pagination: null,
        loading: false,
      }
    case ACTION_FETCH_TYRE_FILTERS:
      return {
        ...state,
        filters: action.payload ?? null,
        filterLoading: false,
      }
    case ACTION_LOADING_TYRE_FILTERS:
      return {
        ...state,
        filterLoading: action.payload,
      }
    case ACTION_LOADING_TYRE_SEARCH:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
