import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import axios from 'axios'
import {converObjectToParams, getURLParams} from 'utils/getData'
import {fetchCatalogData} from 'store/actions/marketplace/catalogActions'

const AllCategoriesBtn = ({resetCatalog, resetSelectedCategories}) => {
  const source = axios.CancelToken.source()
  const cancelToken = source.token
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const urlObject = new URL(window.location.href)
  const location = useLocation()
  const history = useHistory()
  const urlParams = getURLParams(location.search)

  const toggleTopCategories = () => {
    const data = {
      page: 1,
      catalog_id: urlParams.catalog_id,
      ...(urlParams.q && {q: urlParams.q}),
      ...(urlParams.manufacturer_id && {manufacturer_id: urlParams.manufacturer_id}),
    }

    const modelIds = urlObject.searchParams.getAll('model_id[]')

    if (modelIds.length > 0) {
      data.model_id = modelIds
    }

    history.push({
      pathname: '/car-parts',
      search: `?${converObjectToParams(data)}`,
    })

    resetSelectedCategories()
    resetCatalog(true)

    if (data.manufacturer_id || data.q || data.model_id) {
      dispatch(fetchCatalogData(data, cancelToken)).then(() => {
        resetCatalog(false)
      })
    }
  }

  return (
    <li>
      <div className="main-category" onClick={() => toggleTopCategories()}>
        <span className="material-icons">star_border</span>
        {t('ALL_CATEGORIES')}
      </div>
    </li>
  )
}

export default AllCategoriesBtn
