import {
  ACTION_UPDATE_VEHICLE_CATEGORIES,
  ACTION_UPDATE_VEHICLE_SUBCATEGORIES,
  ACTION_UPDATE_VEHICLE_CATEGORIES_BY,
  ACTION_GET_VEHICLE_CATEGORIES_BY,
  ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED,
  ACTION_SET_VEHICLE_ID,
} from 'constants/actionsTypes'

export const initialState = {
  vehicleId: null,
  vehicleCategories: null,
  vehicleSubcategory: null,
  isFetching: false,
  requestFailed: false,
}

export default function vehicleCategoriesReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_GET_VEHICLE_CATEGORIES_BY:
      return {
        ...state,
        isFetching: true,
      }

    case ACTION_UPDATE_VEHICLE_CATEGORIES:
      return {
        ...state,
        isFetching: false,
        vehicleCategories: action.vehicleCategories,
      }

    case ACTION_UPDATE_VEHICLE_SUBCATEGORIES:
      return {
        ...state,
        isFetching: false,
        vehicleSubcategories: action.vehicleSubcategories,
      }

    case ACTION_UPDATE_VEHICLE_CATEGORIES_BY:
      return {
        ...state,
        vehicleCategories: action.vehicleCategories,
        vehicleId: action.vehicleId,
        isFetching: false,
      }

    case ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED:
      return {
        ...state,
        isFetching: false,
        requestFailed: true,
      }

    case ACTION_SET_VEHICLE_ID:
      return {
        ...state,
        vehicleId: action.vehicleId,
      }

    default:
      return state
  }
}
