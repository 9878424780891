import React, {Suspense, lazy, memo} from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {retryPromise as retry} from 'utils/retry_promise'
import MainLayout from 'layouts/MainLayout' // footer need to be in the bottom
import Spinner from 'components/Spinner'

const FavoriteList = lazy(() => retry(() => import('pages/FavoriteList')))
const ResetPassword = lazy(() => retry(() => import('pages/Auth/ResetPassword')))
const CarPart = lazy(() => retry(() => import('pages/CarPart')))
const Cart = lazy(() => retry(() => import('pages/Cart')))
const OrderList = lazy(() => retry(() => import('pages/OrderList')))
const Order = lazy(() => retry(() => import('pages/Order')))
const Checkout = lazy(() => retry(() => import('pages/Checkout')))
const Settings = lazy(() => retry(() => import('pages/Settings')))
const Error404 = lazy(() => retry(() => import('pages/Error/404')))
const BadRequest = lazy(() => retry(() => import('pages/Error/BadRequest')))
const InternalServerError = lazy(() => retry(() => import('pages/Error/InternalServerError')))
const Catalog = lazy(() => retry(() => import('pages/CatalogV2')))

const PrivateRoutesAF = memo(() => {
  const location = useLocation()
  const checkPathname = () => {
    let router = <Route component={Error404} />
    if (location.pathname === '/' || location.pathname.includes('login')) {
      router = <Redirect to="/car-parts?catalog_id=AUT" />
    }
    return router
  }

  return (
    <MainLayout>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/flipbook" />
          <Route path="/car-parts/view/:id" component={CarPart} />
          <Route path="/cart" component={Cart} />
          <Route path="/orders/:order_id" component={Order} />
          <Route path="/orders" component={OrderList} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/car-parts" component={Catalog} />
          <Route path="/settings" component={Settings} />
          <Route path="/favorite" component={FavoriteList} />
          <Route path="/restore-password" component={ResetPassword} />
          <Route path="/internal-error" component={InternalServerError} />
          <Route path="/bad-request" component={BadRequest} />
          {checkPathname()}
        </Switch>
      </Suspense>
    </MainLayout>

  )
})

export default PrivateRoutesAF
