import {
  ACTION_TOGGLE_CSV,
  ACTION_GET_CSV,
  ACTION_UPDATE_CSV,
  ACTION_CLEAR_CSV,
  ACTION_DELETE_ROW_CSV,
  ACTION_UPDATE_ERRORS_ROW_STATUS_CSV,
} from 'constants/actionsTypes'
import {uploadCSV} from 'api/csv'

export function fileUploaderToggle(explicitClose = false) {
  return (dispatch, getState) => {
    const {fileUploaderStatus} = getState().fileUploaderState

    if (explicitClose) {
      dispatch({
        type: ACTION_TOGGLE_CSV,
        fileUploaderStatus: false,
      })
    } else {
      dispatch({
        type: ACTION_TOGGLE_CSV,
        fileUploaderStatus: !fileUploaderStatus,
      })
    }
  }
}

export function uploadCarPartsFile(file, catalog = false) {
  return dispatch => {
    dispatch({
      type: ACTION_GET_CSV,
    })

    let link = '/carparts-upload-csv'
    // let link = '/amerigo/upload-csv'

    if (catalog) {
      link = '/catalog-upload-csv'
    }

    const data = new FormData()
    data.append('csv', file)

    return uploadCSV(
      link,
      data,
    )
      .then(response => {
        dispatch({
          type: ACTION_UPDATE_CSV,
          csvCount: response.data.count,
          csvRows: response.data.rows,
        })

        dispatch({
          type: ACTION_UPDATE_ERRORS_ROW_STATUS_CSV,
          errosRowStatus: !!response.data.rows?.['SELECT_SUPPLIER']?.length,
        })

        return response
      })
      .catch(error => {
        dispatch({
          type: ACTION_UPDATE_CSV,
          csvCount: 0,
          csvRows: [],
        })

        return error.response
      })
  }
}

export function clearUploadOrderFile() {
  return {
    type: ACTION_CLEAR_CSV,
  }
}

export function deleteRow(rowIndex, arrayStatus) {
  return {
    type: ACTION_DELETE_ROW_CSV,
    rowIndex,
    arrayStatus,
  }
}

export function updateErrosRowStatus(errosRowStatus) {
  return {
    type: ACTION_UPDATE_ERRORS_ROW_STATUS_CSV,
    errosRowStatus,
  }
}
