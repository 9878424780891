import request from 'utils/request'

export function fetchDistributors() {
  return request({
    url: '/public/clients-drop-down',
    method: 'get',
  })
}
export function fetchStoreTypes() {
  return request({
    url: '/public/stores-drop-down',
    method: 'get',
  })
}

export function postInvitation(data) {
  return request({
    url: '/public/distributor-invitation',
    method: 'post',
    data,
  })
}

export function accessToken(token) {
  return request({
    url: '/public/distributor-invitation/access',
    method: 'get',
    params: {token},
  })
}
