import React, {useState} from 'react'
import {usePermission, useCountUnseenMessages} from 'utils/hooks'
import Backdrop from '../components/Backdrop'
import Toolbar from '../components/Toolbar'
import MenuSideDrawer from '../components/MenuSideDrawer'
import FiltersSideDrawer from '../components/FiltersSideDrawer'
import CatalogFiltersSideDrawer from '../components/CatalogFiltersSideDrawer'
import './style.scss'

import {ROLE_ADV, ROLE_SELLER, ROLE_SUPPLIER_AMERIGO} from '../../../constants/roles'
import {checkClientIdFromLocalStorage} from '../../../utils'

const HeaderMarketplace = () => {
  const [type, setType] = useState(null)
  const hasSellerPermission = usePermission([ROLE_SELLER, ROLE_ADV, ROLE_SUPPLIER_AMERIGO])
  useCountUnseenMessages()

  const drawerToggleClickHandler = newType => {
    setType(newType)
  }

  const backdropClickHandler = () => {
    setType(null)
  }

  return (
    hasSellerPermission && !checkClientIdFromLocalStorage() ? null : (
      <div className="header">
        <div className="header-wrapper">
          <Toolbar drawerClickHandler={drawerToggleClickHandler} />
          {
            type === 'menu' && <MenuSideDrawer backdropClickHandler={backdropClickHandler} />
          }
          {
            type === 'filter' && <FiltersSideDrawer backdropClickHandler={backdropClickHandler} />
          }
          {
            type === 'catalog-filter' && <CatalogFiltersSideDrawer backdropClickHandler={backdropClickHandler} />
          }
          {type ? <Backdrop click={backdropClickHandler} /> : null}
        </div>
      </div>
    )
  )
}

export default HeaderMarketplace
