import React from 'react'
import {usePermission, useCountUnseenMessages} from 'utils/hooks'
import {ROLE_ADV, ROLE_SELLER} from '../../../constants/roles'
import PublicToolbar from './components/PublicToolbar'
import {checkClientIdFromLocalStorage, isHostPublicTr} from '../../../utils'
import { isHostPublic } from 'utils'
import './style.scss'

const HeaderPublic = () => {
  const hasSellerPermission = usePermission([ROLE_SELLER, ROLE_ADV])
  const isPublic = isHostPublic()
  if(!isPublic && !isHostPublicTr()) {
  useCountUnseenMessages()
  }

  return (
    hasSellerPermission && !checkClientIdFromLocalStorage() ? null : (
      <div className="public-header">
        <div className="header-wrapper">
          <PublicToolbar  />
        </div>
      </div>
    )
  )
}

export default HeaderPublic
