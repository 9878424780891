import {ACTION_UPDATE_CATALOG_BRANDS, FETCH_CATALOG_BRANDS} from 'constants/actionsTypes'
import {fetchCatalogBrands} from 'api/catalogs'

export const fetchCatalogBrandsDispatch = (data, cancelToken) => dispatch => {
  dispatch({type: FETCH_CATALOG_BRANDS})
  fetchCatalogBrands(data, cancelToken)
    .then(response => {
      dispatch({
        type: ACTION_UPDATE_CATALOG_BRANDS,
        brands: response.data,
      })
    })
}
