import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchOrdersChallenge} from 'api/orders'
import ToggleButton from 'components/Header/components/ToogleButton'
import Progress from 'components/Progress'
import {resetCarParts} from 'store/actions/carPartsActions'
import {getCurrentMarketplace, getURLParams} from 'utils/getData'
import {useTranslation} from 'react-i18next'
import './style.scss'
import ToolBarNavAF from '../ToolBarNavAF'

const ToolbarAF = ({drawerClickHandler}) => {
  const dispatch = useDispatch()
  const marketplace = getCurrentMarketplace()
  const {user} = useSelector(state => state.userState)
  const [orderChallengeDetails, setOrderChallengeDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const {i18n} = useTranslation()

  useEffect(() => {
    if (user && user.client_name) {
      setIsLoading(true)
      fetchOrdersChallenge()
        .then(result => {
          setOrderChallengeDetails(result.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [user, i18n.language])

  const location = useLocation()
  const urlParams = getURLParams(location.search)
  const handlerResetCarParts = event => {
    if (!event.ctrlKey && !event.metaKey) {
      dispatch(resetCarParts())
    }
  }

  const showLogos = () => (
    <NavLink to="/" onClick={event => handlerResetCarParts(event)} className={marketplace}>
      <img src={`/images/logos/${marketplace}.png`} alt={marketplace} />
    </NavLink>
  )

  const checkCatalog = urlParams.hasOwnProperty('catalog_id') && window.location.pathname === '/car-parts'
  const checkCatalogView = urlParams.hasOwnProperty('catalog_id') && window.location.pathname.includes('view')
  const checkReliable = window.location.pathname.includes('reliable') && window.location.pathname.includes('car-parts') && !window.location.pathname.includes('view')

  const filterStatus = checkCatalog || checkReliable

  let type = 'filter'

  if (urlParams.hasOwnProperty('catalog_id')) {
    type = 'catalog-filter'
  }

  const orderChallengeTranslation = orderChallengeDetails ? orderChallengeDetails.content : ''
  const translationWords = orderChallengeTranslation ? orderChallengeTranslation.split(' ') : ''

  return (
    <header className="toolbar">
      {
        filterStatus
          ? (
            <div className="filter-toggle-button">
              <ToggleButton
                toggleDrawer={drawerClickHandler}
                type={type}
              />
            </div>
          )
          : null
      }

      <div className={`toolbar-logo ${checkCatalog || checkCatalogView ? 'catalog' : ''}`}>
        {showLogos()}
      </div>

      {!isLoading && orderChallengeDetails && orderChallengeDetails.hasChallenge
      && (
        <div className="toolbar-order-challenge">
          <div className="order-challange-progress">
            <div className="order-challenge-title">
              <span className="order-challenge-title first">{translationWords[0]}</span>
              {translationWords.length > 1 && (
                <span className="order-challenge-title second">
                  {translationWords.length > 2 && translationWords[1]}
                  <span className="month">
                    &nbsp;
                    {translationWords.slice(translationWords.length > 2 ? 2 : 1).join(' ')}
                  </span>
                </span>
              )}
            </div>
            <Progress
              percentage={(orderChallengeDetails.clientChallenge.currentOrders / orderChallengeDetails.clientChallenge.ordersTarget) * 100}
              type="circular"
            />
          </div>
        </div>
      )}

      <div className="toolbar-nav-wrapper">
        <ToolBarNavAF />
      </div>

      <div className="toolbar-toggle-button">
        <ToggleButton toggleDrawer={drawerClickHandler} type="menu" />
      </div>
    </header>
  )
}

export default ToolbarAF
