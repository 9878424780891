import React, { useEffect } from 'react'
import {NavLink} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import {resetCarParts} from 'store/actions/carPartsActions'
import {getCurrentMarketplace} from 'utils/getData'
import {fetchOrdersChallenge} from 'api/orders'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'utils/hooks'
import {ACTION_UPDATE_HAS_CHALLENGE} from 'constants/actionsTypes'
import { isHostPublic, isHostPublicTr } from 'utils'

const PublicToolbar = () => {

  const dispatch = useDispatch()
  const marketplace = getCurrentMarketplace()
  const {user} = useSelector((state) => state.userState)
  const {i18n} = useTranslation()
  const prevLanguage = usePrevious(i18n.language)
  const isPublic = isHostPublic()
  const handlerResetCarParts = event => {
    if (!event.ctrlKey && !event.metaKey) {
      dispatch(resetCarParts())
    }
  }

  useEffect(() => {
    if (marketplace == 'amerigo' || (isPublic || isHostPublicTr())) return
    if ((user && user.client_name) || (prevLanguage && prevLanguage !== i18n.language)) {
      fetchOrdersChallenges()
    }
  }, [user, i18n.language])

  const fetchOrdersChallenges = () => {
    fetchOrdersChallenge()
      .then(result => {
        dispatch({
          type: ACTION_UPDATE_HAS_CHALLENGE,
          hasChallenge: result.data.hasChallenge,
          banner: result.data.banner,
        })
      })
  }

  const showLogos = () => (
      <NavLink
        to="/"
        onClick={event => handlerResetCarParts(event)}
        className={marketplace === process.env.REACT_APP_MARKETPLACE_PUBLIC_TR ? 'publictr' : marketplace}
      >
        <img src={`/images/logos/reliable_logo.svg`} alt={marketplace} />
      </NavLink>

  )
  return (
    <header className="toolbar">

      <div
        className={`toolbar-logo `}
      >
        {showLogos()}
      </div>

    </header>
  )
}

export default PublicToolbar
