import request from 'utils/request'

export function fetchReliableTruckManufacturers(query) {
  return request({
    url: '/reliable/truck-selector/manufacturers',
    method: 'get',
    params: query,
  })
}

export function fetchReliableTruckModels(id, query, {cancelToken}) {
  return request({
    url: `/reliable/truck-selector/${id}/models`,
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchReliableTruckFuelType(id, {cancelToken}) {
  return request({
    url: `/reliable/truck-selector/${id}/fuel-type`,
    method: 'get',
    cancelToken,
  })
}

export function fetchReliableTruckVariant(id, query, {cancelToken}) {
  return request({
    url: `/reliable/truck-selector/${id}/variant`,
    method: 'get',
    params: query,
    cancelToken,
  })
}
