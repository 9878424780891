import {ACTION_CLEAR_ERROR_STATUS, ACTION_INTERNAL_SERVER_ERROR, ACTION_BAD_REQUEST_ERROR} from 'constants/actionsTypes'
import {ACTION_BAD_GATEWAY_ERROR, ACTION_UNPROCESSABLE_ENTITY_ERROR} from '../../constants/actionsTypes'

export const initialState = {
  serverError: null,
  badRequest: null,
  badGateway: null,
  unprocessableEntity: null,
}

export default function errorReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_INTERNAL_SERVER_ERROR:
      return {
        ...state,
        serverError: action.serverError,
      }

    case ACTION_BAD_REQUEST_ERROR:
      return {
        ...state,
        badRequest: action.badRequest,
      }

    case ACTION_BAD_GATEWAY_ERROR:
      return {
        ...state,
        badGateway: action.badGateway,
      }

    case ACTION_UNPROCESSABLE_ENTITY_ERROR:
      return {
        ...state,
        unprocessableEntity: action.unprocessableEntity,
      }

    case ACTION_CLEAR_ERROR_STATUS:
      return {
        ...state,
        serverError: null,
        badRequest: null,
        badGateway: null,
        unprocessableEntity: null,
      }

    default:
      return state
  }
}
