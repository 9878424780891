import React, {Suspense, lazy, memo} from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {retryPromise as retry} from 'utils/retry_promise'
import MainLayout from 'layouts/MainLayout' // footer need to be in the bottom
import Spinner from 'components/Spinner'
import PublicHomeTR from 'pages/PublicHomeTR'
import {isHostPublic, isHostPublicTr, isHostPunchOut} from '../../utils'
import {checkPermissionEina, usePermission} from '../../utils/hooks'
import {
  ROLE_ADV,
  ROLE_CLIENT_AMERIGO,
  ROLE_CLIENT_AUTOMOTOR,
  ROLE_CLIENT_AUTOMOTOR_AMERIGO,
  ROLE_CLIENT_PLATFORM_ADMIN,
  ROLE_SELLER,
  ROLE_SUPPLIER_AMERIGO,
} from '../../constants/roles'

const FavoriteList = lazy(() => retry(() => import('pages/FavoriteList')))
const ResetPassword = lazy(() => retry(() => import('pages/Auth/ResetPassword')))
const Home = lazy(() => retry(() => import('pages/Home')))
const PublicHome = lazy(() => retry(() => import('pages/PublicHome')))
const CarPart = lazy(() => retry(() => import('pages/CarPart')))
const History = lazy(() => retry(() => import('pages/History')))
const Cart = lazy(() => retry(() => import('pages/Cart')))
const OrderList = lazy(() => retry(() => import('pages/OrderList')))
const Order = lazy(() => retry(() => import('pages/Order')))

const OrderListClients = lazy(() => retry(() => import('pages/OrderListClients')))
const OrderListClientsPlatform = lazy(() => retry(() => import('pages/OrderListClients/OrderPlatform')))
const OrderListSupplier = lazy(() => retry(() => import('pages/OrderListSupplier')))
const OrderListSupplierPlatform = lazy(() => retry(() => import('pages/OrderListSupplier/OrderPlatform')))
const Checkout = lazy(() => retry(() => import('pages/Checkout')))
const Settings = lazy(() => retry(() => import('pages/Settings')))
const Error404 = lazy(() => retry(() => import('pages/Error/404')))
const Main = lazy(() => retry(() => import('pages/Reliable/Main')))
const ReliableContent = lazy(() => retry(() => import('pages/Reliable/ReliableContent')))
const VehicleSubcategories = lazy(() => retry(() => import('pages/Reliable/VehicleCategories/VehicleSubcategories')))
const CarPartsReliable = lazy(() => retry(() => import('pages/Reliable/CarPartsReliable')))
const CarPartsReference = lazy(() => retry(() => import('pages/Reliable/CarPartsReference')))
// const Catalog = lazy(() => retry(() => import('pages/Catalog')))
const CarPartsGenArt = lazy(() => retry(() => import('pages/Reliable/ReliableContent/CarPartsGenArt')))
const Messages = lazy(() => retry(() => import('pages/EspaceClient/Messaging')))
const MessagesSupplier = lazy(() => retry(() => import('pages/EspaceClient/MessagingSupplier')))
const Tariffs = lazy(() => retry(() => import('pages/EspaceClient/Tariffs')))
const TariffsSupplier = lazy(() => retry(() => import('pages/EspaceClient/TariffsSupplier')))
const ClientList = lazy(() => retry(() => import('pages/ClientList')))
const CatalogV2 = lazy(() => retry(() => import('pages/CatalogV2')))
const FinancialDeadline = lazy(() => retry(() => import('pages/Financial/FinancialDeadline')))
const FinancialData = lazy(() => retry(() => import('pages/Financial/FinancialData')))
const ProformaMapping = lazy(() => retry(() => import('pages/ProformaMapping')))
const ProformaMappingBasic = lazy(() => retry(() => import('pages/ProformaMappingBasic')))
const Eina = lazy(() => retry(() => import('pages/EinaVTS')))
const PrivateRoutes = memo(() => {
  const isPunchOut = isHostPunchOut()

  const location = useLocation()
  const hasFinancialPermission = usePermission([
    ROLE_CLIENT_AUTOMOTOR,
    ROLE_CLIENT_AUTOMOTOR_AMERIGO,
    ROLE_SELLER,
    ROLE_ADV,
  ])
  const hasSellerPermission = usePermission([ROLE_SELLER, ROLE_ADV])
  const hasSupplierPermission = usePermission([ROLE_SUPPLIER_AMERIGO, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_CLIENT_AMERIGO])
  const hasFinancialEina = checkPermissionEina()
  const hasSupplierAmerigoPermission = usePermission([ROLE_SUPPLIER_AMERIGO])
  const hasClientPlatformAdmin = usePermission([ROLE_CLIENT_PLATFORM_ADMIN])
  const isPublic = isHostPublic()
  const isPublicTr = isHostPublicTr()

  const checkPathname = () => {
    let router = <Route component={Error404} />

    if (location.pathname === '/' || location.pathname.includes('login')) {
      router = <Redirect to="/" />
    }
    return router
  }

  function getHomeRoute() {
    if (isPublic) {
      return <Route exact path="/" component={PublicHome} />
    }
    if (isPublicTr) {
      // return <Route exact path="/" component={PublicHomeTR} />
      return <Route exact path="/" component={PublicHome} />
    }
    return <Route exact path="/" component={Home} />
  }

  return (
    <MainLayout location={location}>
      <Suspense fallback={<Spinner class="small" />}>
        <Switch>
          {getHomeRoute()}
          <Route exact path="/reliable" component={Main} />
          <Route path="/reliable/car-parts/view/:id" component={CarPart} />
          <Route
            path="/reliable/rmi/:vehicle_id/:tab_name"
            component={ReliableContent}
          />
          <Route
            path="/reliable/:vehicle_id/polk-subcategory"
            component={VehicleSubcategories}
          />
          <Route
            path="/reliable/:vehicle_id/:product_id/:reference_name/car-parts"
            component={CarPartsReliable}
          />
          <Route
            path="/reliable/:vehicle_id/car-parts"
            component={CarPartsReliable}
          />
          <Route
            path="/reliable/:vehicle_id/car-parts-by-genart"
            component={CarPartsGenArt}
          />
          <Route
            path="/reliable/car-parts/search"
            component={CarPartsReference}
          />
          <Route path="/proforma-mapping" component={ProformaMapping} />

          <Route exact path="/flipbook" />
          <Route path="/car-parts/view/:id" component={CarPart} />
          <Route path="/cart" component={Cart} />
          <Route path="/history" component={History} />
          <Route path="/orders/:order_id" component={Order} />
          <Route path="/orders" component={OrderList} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/car-parts" component={CatalogV2} />
          <Route path="/favorite" component={FavoriteList} />
          <Route path="/messaging" component={Messages} />
          <Route path="/tariffs" component={Tariffs} />
          {!isPunchOut && (
            <Route path="/restore-password" component={ResetPassword} />
          )}
          {/* <Route path="/newsearch" component={CatalogV2} /> */}
          {hasFinancialPermission && (
            <Route path="/remaining" component={FinancialData} />
          )}
          {hasFinancialPermission && (
            <Route path="/financial" component={FinancialDeadline} />
          )}

          {hasSellerPermission && (
            <>
              <Route
                path="/proforma-mapping-basic"
                component={ProformaMappingBasic}
              />
              <Route path="/select-client" component={ClientList} />
              <Route path="/settings" component={Settings} />

            </>
          )}
          {hasSupplierPermission && (
            <Route path="/select-client" component={ClientList} />
          )}
          {hasSupplierPermission && (
            <Route path="/tariffs-supplier" component={TariffsSupplier} />
          )}
          {hasSupplierPermission && (
            <Route path="/messaging-supplier" component={MessagesSupplier} />
          )}
          <Route path="/settings" component={Settings} />
          {hasFinancialEina && (
            <Route path="/eina" component={Eina} />) }
          {hasClientPlatformAdmin && (<Route path="/orders-clients/:order_id" component={OrderListClientsPlatform} />)}
          {hasClientPlatformAdmin && (<Route path="/orders-clients" component={OrderListClients} />)}
          {hasSupplierAmerigoPermission && (<Route path="/orders-list/:order_id" component={OrderListSupplierPlatform} />)}
          {hasSupplierAmerigoPermission && (<Route path="/orders-list" component={OrderListSupplier} />)}
          {checkPathname()}
        </Switch>
      </Suspense>
    </MainLayout>
  )
})

export default PrivateRoutes
