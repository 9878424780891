import {ACTION_UPDATE_CATALOG_BRANDS, FETCH_CATALOG_BRANDS} from 'constants/actionsTypes'

export const initialState = {
  brands: null,
  loading: false,
}

export default function catalogBrandsReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_CATALOG_BRANDS:
      return {
        ...state,
        loading: false,
        brands: action.brands,
      }
    case FETCH_CATALOG_BRANDS:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}
