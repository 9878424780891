import React from 'react'
import './style.scss'

const ToggleButton = props => {
  const openDrawer = () => {
    props.toggleDrawer(props.type)
  }

  return (
    <button type="button" className={`toggle-button ${props.type}`} onClick={openDrawer}>
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
    </button>
  )
}

export default ToggleButton
