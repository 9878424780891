import {
  ACTION_UPDATE_USER,
  ACTION_FETCH_USER_INFO,
  ACTION_UPDATE_ACCOUNT_SETTINGS,
  RESET_ACCOUNT_SETTINGS,
  RUN_UPDATE_SETTINGS,
  FAILED_UPDATE_SETTINGS,
  ACTION_FETCH_USER_INFO_FAIL,
} from 'constants/actionsTypes'

export const initialState = {
  settings: null,
  loadingSettings: false,
  loading: false,
  user: null,
  error: false,
}

export default function userReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_USER:
      return {
        ...state,
        loading: false,
        user: action.user,
        error: false,
      }

    case ACTION_FETCH_USER_INFO:
      return {
        ...state,
        loading: true,
      }

    case ACTION_FETCH_USER_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case RUN_UPDATE_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
      }

    case ACTION_UPDATE_ACCOUNT_SETTINGS:
      return {
        ...state,
        loadingSettings: false,
        settings: action.settings,
      }
    case RESET_ACCOUNT_SETTINGS:
      return {
        ...state,
        settings: null,
      }

    case FAILED_UPDATE_SETTINGS:
      return {
        ...state,
        loadingSettings: false,
      }

    default:
      return state
  }
}
