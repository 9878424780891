import {
  ACTION_SET_CAR_PARTS,
  ACTION_FETCH_CAR_PARTS,
  ACTION_FILTER_CAR_PARTS,
  ACTION_RESET_CAR_PARTS,
  ACTION_CLEAR_FILTER_CAR_PARTS,
  ACTION_SET_CAR_PARTS_FILTERS,
  ACTION_FETCH_CAR_PARTS_FAIL,
  ACTION_FETCH_CAR_PARTS_IN_BACKGROUND,
  ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND,
  ACTION_SET_CAR_PARTS_WITH_REFERENCE,
  ACTION_SET_CAR_PARTS_FILTER_RELIABLE,
  ACTION_RESET_CAR_PARTS_FILTER,
  ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST,
} from 'constants/actionsTypes'
import {fetchReliableCarParts, fetchReliableFilters} from 'api/reliable/car-parts'

export function fetchCarPartsAllOEList(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
    vehicle_identifier,
    reference_count,
  } = data

  return dispatch => {
    dispatch({type: ACTION_FETCH_CAR_PARTS})

    fetchReliableFilters(`/reliable/${vehicleId}/all-oe-filter/car-parts`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
      ...(reference_count && {reference_count}),
    }).then(response => {
      dispatch({
        type: ACTION_SET_CAR_PARTS_FILTERS,
        filters: response.data.filters,
      })
    })

    fetchReliableCarParts(`/reliable/${vehicleId}/all-oe-list/car-parts`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
      ...(reference_count && {reference_count}),
    })
      .then(({data}) => {
        dispatch({
          type: ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST,
          carParts: data.oe_references,
          outOfOffer: data.out_of_offer,
        })
      }).catch(() => {
        dispatch({type: ACTION_FETCH_CAR_PARTS_FAIL})
      })
  }
}

export function fetchCarPartsReliableWithReference(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
    referenceName,
    product_id,
    vehicle_identifier,
  } = data

  return dispatch => {
    dispatch({type: ACTION_FETCH_CAR_PARTS})

    fetchReliableCarParts(`/reliable/${vehicleId}/${product_id}/${referenceName}/car-parts`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
    })
      .then(({data}) => {
        dispatch({
          type: ACTION_SET_CAR_PARTS_WITH_REFERENCE,
          filters: data.filters,
          carParts: data.oe_references,
          outOfOffer: data.out_of_offer,
        })
      }).catch(() => {
        dispatch({type: ACTION_FETCH_CAR_PARTS_FAIL})
      })
  }
}

export function fetchCarPartsReliable(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
    vehicle_identifier,
    page,
    per_page,
  } = data

  return dispatch => {
    dispatch({type: ACTION_FETCH_CAR_PARTS})

    fetchReliableFilters(`reliable/${vehicleId}/car-parts/filter`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
    }).then(response => {
      dispatch({
        type: ACTION_SET_CAR_PARTS_FILTERS,
        filters: response.data,
      })
    })

    fetchReliableCarParts(`/reliable/${vehicleId}/car-parts/new`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
      ...(page && {page}),
      ...(per_page && {per_page}),
    })
      .then(({data}) => {
        dispatch({
          type: ACTION_SET_CAR_PARTS,
          page: data.page,
          total_pages: data.total_pages,
          carParts: data.data.oe_references,
          outOfOffer: data.data.out_of_offer,
        })
      }).catch(() => {
        dispatch({type: ACTION_FETCH_CAR_PARTS_FAIL})
      })
  }
}

export function fetchCarPartsReliableFilter(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
    vehicle_identifier,
    page,
    per_page,
    supplierId,
    manufactureId,
  } = data

  return dispatch => {
    dispatch({type: ACTION_RESET_CAR_PARTS_FILTER})

    setTimeout(() => {
      fetchReliableCarParts(`/reliable/${vehicleId}/car-parts/new`, {
        ...(source && {source}),
        ...(code_groupe && {code_groupe}),
        ...(code_repere && {code_repere}),
        ...(code_ssgroupe && {code_ssgroupe}),
        ...(vehicle_identifier && {vehicle_identifier}),
        ...(page && {page}),
        ...(per_page && {per_page}),
        ...(supplierId && {supplierId}),
        ...(manufactureId && {manufactureId}),
      })
        .then(({data}) => {
          dispatch({
            type: ACTION_SET_CAR_PARTS_FILTER_RELIABLE,
            page: data.page,
            total_pages: data.total_pages,
            carParts: data.data.oe_references,
            outOfOffer: data.data.out_of_offer,
          })
        }).catch(() => {
          dispatch({type: ACTION_FETCH_CAR_PARTS_FAIL})
        })
    }, 200)
  }
}

export function fetchAndPushCarPartsReliable(data) {
  const {
    code_groupe,
    code_repere,
    code_ssgroupe,
    source,
    vehicleId,
    vehicle_identifier,
    page,
    per_page,
    supplierId,
    manufactureId,
  } = data

  return dispatch => {
    dispatch({type: ACTION_FETCH_CAR_PARTS_IN_BACKGROUND})

    fetchReliableCarParts(`/reliable/${vehicleId}/car-parts/new`, {
      ...(source && {source}),
      ...(code_groupe && {code_groupe}),
      ...(code_repere && {code_repere}),
      ...(code_ssgroupe && {code_ssgroupe}),
      ...(vehicle_identifier && {vehicle_identifier}),
      ...(page && {page}),
      ...(per_page && {per_page}),
      ...(per_page && {per_page}),
      ...(supplierId && {supplierId}),
      ...(manufactureId && {manufactureId}),
    })
      .then(({data}) => {
        dispatch({
          type: ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND,
          page: data.page,
          total_pages: data.total_pages,
          carParts: data.data.oe_references,
          outOfOffer: data.data.out_of_offer,
        })
      }).catch(() => {
        dispatch({type: ACTION_FETCH_CAR_PARTS_FAIL})
      })
  }
}

export function resetCarParts() {
  return {
    type: ACTION_RESET_CAR_PARTS,
    carParts: null,
    filters: null,
    outOfOffer: null,
  }
}

export function filterCarParts(data) {
  return {
    type: ACTION_FILTER_CAR_PARTS,
    filterBy: data,
  }
}

export function clearFilterCarParts() {
  return {
    type: ACTION_CLEAR_FILTER_CAR_PARTS,
  }
}
