import _ from 'underscore'

export function carPartName(data) {
  const {
    manufacturerName,
    shortName,
    modelName,
    name,
    engineCapacity,
    capacityQualifier,
    vehicle_trim_code,
    startYear,
    startMonth,
    endYear,
    endMonth,
    fapDescription,
    otherDescription,
    valvesDescription,
    nbOfCylinder,
    kwPower,
    horsePower,
    engine_code,
    lhd,
    sub_model,
    first_reg_date,
  } = data
  let carName = ''

  if (manufacturerName) {
    carName = manufacturerName
  }

  if (shortName || modelName) {
    carName = `${carName} ${shortName || modelName}`
  }

  if (name) {
    carName = `${carName} ${name}`
  }

  if (engineCapacity) {
    carName = `${carName} ${engineCapacity}`
  }

  if (sub_model) {
    carName = `${carName} (${sub_model})`
  }

  if (capacityQualifier) {
    carName = `${carName} ${capacityQualifier}`
  }

  if (vehicle_trim_code) {
    carName = `${carName} ${vehicle_trim_code}`
  }

  if (valvesDescription) {
    carName = `${carName} ${valvesDescription}`
  }

  if (otherDescription) {
    carName = `${carName} ${otherDescription}`
  }

  if (startMonth && startYear) {
    carName = `${carName} ${startMonth}/${startYear}`
  }

  if (endMonth && endYear) {
    carName = `${carName} - ${endMonth}/${endYear}`
  }

  if (fapDescription) {
    carName = `${carName} ${fapDescription}`
  }

  if (nbOfCylinder) {
    carName = `${carName} ${nbOfCylinder}`
  }

  if (kwPower) {
    carName = `${carName} - ${kwPower} KW`
  }

  if (horsePower) {
    carName = `${carName} - ${horsePower} CH DIN`
  }

  if (engine_code) {
    carName = `${carName} ${engine_code}`
  }

  if (lhd) {
    carName = `${carName} ${lhd}`
  }

  if (first_reg_date) {
    carName = `${carName} DATE_1ER_MEC: ${first_reg_date}`
  }

  return carName
}

export function truckName(data) {
  const {
    truck_name,
    model_name,
    engine_capacity,
    body_description,
    start_year,
    start_month,
    end_year,
    end_month,
  } = data
  let truckName = ''

  if (model_name) {
    truckName = model_name
  }

  if (truck_name) {
    truckName = `${truckName} ${truck_name}`
  }

  if (engine_capacity) {
    truckName = `${truckName} ${engine_capacity}`
  }

  if (body_description) {
    truckName = `${truckName} ${body_description}`
  }

  if (start_month && start_year) {
    truckName = `${truckName} ${start_month}/${start_year}`
  }

  if (end_month && end_year) {
    truckName = `${truckName} - ${end_month}/${end_year}`
  }

  return truckName
}

export function renderDescription(item, t) {
  let text = []
  if (item.hasOwnProperty('attributes') && Array.isArray(item.attributes)) {
    text = item.attributes.reduce((tags, attribute) => {
      tags.push(`${attribute.name}: ${attribute.value}`)
      return tags
    }, [])
  }

  if (item.description) {
    text.push(item.description)
  }

  if (item.hasOwnProperty('quantity') && item.quantity > 0) {
    text.push(`${item.quantity} ${t('ITEMS_IN_PACK')}`)
  }

  if (item.replacements_list) {
    text.push(`${t('REPLACEMENTS')}: ${item.replacements_list}`)
  }

  if (item.replacements) {
    text.push(`OE: ${item.replacements}`)
  }

  return text.join(', ')
}

export function fallbackImage(event) {
  event.target.onerror = null
  event.target.src = '/images/car-part-placeholder.png'
}

export function renderTitle(item) {
  let carName

  if (item.hasOwnProperty('data_supplier') && !_.isEmpty(item.data_supplier)) {
    carName = `${item.data_supplier.name} ${item.data_supplier_reference_original}`
  } else {
    carName = `${item.manufacturer_name} ${item.oe_reference || item.name ? item.oe_reference || item.name : ''}`
  }

  return carName
}
