import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReliableSearch from 'pages/Reliable/ReliableSearch'
import {isHostPublic, isHostPublicTr, isInReliableBool} from '../../utils'
import InternalServerError from '../../pages/Error/InternalServerError'
import {useHandleBadGatewayRequest, useHandleUnprocessableEntity, useHandleBadRequest, useLocationChange} from '../../utils/hooks'
import {clearErrorStatus} from '../../store/actions/errorActions'
import './style.scss'

const MainLayout = ({children, location}) => {
  const dispatch = useDispatch()
  const isPublic = isHostPublic()
  const {serverError, badRequest} = useSelector(state => state.errorState)

  useHandleBadRequest()
  useHandleBadGatewayRequest()
  useHandleUnprocessableEntity()
  
  useLocationChange((location, prevLocation) => {
    if (prevLocation && prevLocation?.pathname !== location?.pathname) {
      if (serverError || badRequest) {
        dispatch(clearErrorStatus())
      }
    }
  })

  return (
    serverError ? <InternalServerError /> : (
      <div className="layout">
        {
          ((location?.pathname === '/' || isInReliableBool()) && (!isPublic && !isHostPublicTr())) && <ReliableSearch />
        }
        {children}
      </div>
    )
  )
}

export default MainLayout
