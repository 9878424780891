import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {fetchCarPartsReference} from 'store/actions/reliable/carPartsReferenceActions'
import {filterCarParts} from 'store/actions/carPartsActions'
import Filter from 'pages/Reliable/Filters'
import {getURLParams, converObjectToParams} from 'utils/getData'

import './style.scss'

const FiltersSideDrawer = props => {
  const {filters, filterLoading} = useSelector(state => state.carPartsReferenceState)
  const carPartsFilters = useSelector(state => state.carPartsState.filters)
  const history = useHistory()
  const dispatch = useDispatch()
  let urlParams = getURLParams(history.location.search)

  const grabFilterCarPartsReferenceState = data => {
    delete urlParams['sup[]']
    delete urlParams['man[]']
    delete urlParams['with_default']
    if (data.manufacture.length > 0) {
      urlParams = {...urlParams, man: data.manufacture}
    }

    if (data.supplier.length > 0) {
      urlParams = {...urlParams, sup: data.supplier.map(el => el.id)}
    }

    if (data.isAmerigoChecked) {
      urlParams.is_amerigo = 0
    } else {
      urlParams.is_amerigo = data.isAmerigo ? 1 : 0
    }
    history.push({
      search: `?${converObjectToParams(urlParams)}`,
    })
    dispatch(fetchCarPartsReference(urlParams))
  }

  const grabFilterCarParts = ({isAmerigo, manufacture, supplier}) => {
    dispatch(filterCarParts({filterBySupplier: supplier, filterByManufacturer: manufacture, isAmerigo}))
    props.backdropClickHandler(false)
  }

  return (
    <div className="filters-side-drawer open-drawer">
      {
        !filterLoading
          ? (
            <Filter
              grabFilterData={filters ? grabFilterCarPartsReferenceState : grabFilterCarParts}
              filters={filters ? filters : carPartsFilters}
            />
          )
          : null
      }
    </div>
  )
}

export default FiltersSideDrawer
