import ar from 'languages/ar.json'
import en from 'languages/en.json'
import es from 'languages/es.json'
import fr from 'languages/fr.json'
import it from 'languages/it.json'
import ko from 'languages/ko.json'
import qa from 'languages/qa.json'
import ru from 'languages/ru.json'
import tr from 'languages/tr.json'
import zh from 'languages/zh.json'

export const translations = {
  en: {
    translation: {...en},
  },
  ar: {
    translation: {...ar},
  },
  es: {
    translation: {...es},
  },
  fr: {
    translation: {...fr},
  },
  it: {
    translation: {...it},
  },
  ko: {
    translation: {...ko},
  },
  qa: {
    translation: {...qa},
  },
  ru: {
    translation: {...ru},
  },
  tr: {
    translation: {...tr},
  },
  zh: {
    translation: {...zh},
  },
}
