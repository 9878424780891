import request from 'utils/request'

export function fetchClientsForSeller(query) {
  return request({
    url: '/seller/clients',
    method: 'get',
    params: query,
  })
}

export function saveClientForSeller(clientId) {
  return request({
    url: `/seller/clients/${clientId}`,
    method: 'post',
  })
}

export function getFileTypes() {
  return request({
    url: '/client-notification/file-types',
    method: 'get',
  })
}

export function fetchClientMessagesList(params) {
  return request({
    url: '/messages',
    method: 'get',
    params,
  })
}

export function fetchUnseenMessages() {
  return request({
    url: '/messages/unseen-count',
    method: 'GET',
  })
}

export function fetchClientFilesList(params) {
  return request({
    url: '/client-notification/tariffs',
    method: 'get',
    params,
  })
}

export function uploadSellerFiles(data) {
  return request({
    url: '/seller/upload-notification-tariff',
    method: 'post',
    data,
    headers: {'content-type': 'multipart/form-data'},
  })
}

export function patchNotificationFilesWithLoadFilesResponse(data) {
  return request({
    url: '/seller/update-load-files-response',
    method: 'POST',
    data,
  })
}

export function patchNotificationFileWithLoadFilesResponse(fileId, data) {
  return request({
    url: `/seller/notification-file/${fileId}/update-load-file-response`,
    method: 'PATCH',
    data,
  })
}

export function uploadMessage(data) {
  return request({
    url: '/messages/message',
    method: 'post',
    data,
    headers: {'content-type': 'multipart/form-data'},
  })
}

export function deleteMessage(messageId) {
  return request({
    url: `/messages/message/${messageId}`,
    method: 'delete',
  })
}

export function deleteTariff(messageId) {
  return request({
    url: `/client-notification/upload/${messageId}`,
    method: 'delete',
  })
}

export function fetchZoneCommercials() {
  return request({
    url: '/seller/zone-commercials',
    method: 'get',
  })
}

export function fetchCountries() {
  return request({
    url: '/seller/countries',
    method: 'get',
  })
}

export function downloadTariff(id) {
  return request({
    url: `/client-notification/file/${id}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function downloadMessage(id) {
  return request({
    url: `/messages/download-file/${id}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getSupplierMappingList() {
  return request({
    url: '/client-notification/supplier-mapping-list',
    method: 'get',
  })
}

export function getManufacturerMappingList() {
  return request({
    url: '/client-notification/manufacturer-mapping-list',
    method: 'get',
  })
}

export function getAllClientsBySeller() {
  return request({
    url: '/seller/clients/all',
    method: 'get',
  })
}
export function getAmerigoClients() {
  return request({
    url: '/seller/amerigo-clients/all',
    method: 'get',
  })
}

export function getSupplierMappingGroup() {
  return request({
    url: '/messages/supplier-mapping-group',
    method: 'get',
  })
}
