import {
  ACTION_AMERIGO_CLEAR_CSV,
  ACTION_AMERIGO_DELETE_ROW_CSV,
  ACTION_AMERIGO_GET_CSV,
  ACTION_AMERIGO_TOGGLE_CSV,
  ACTION_AMERIGO_UPDATE_CSV, ACTION_AMERIGO_UPDATE_ERRORS_ROW_STATUS_CSV,
} from 'constants/actionsTypes'

export const initialState = {
  fileUploaderStatus: null,
  csvCount: 0,
  header: [],
  csvRows: [],
  errosRowStatus: false,
  isFetching: false,
}

export default function AmerigoFileUploaderReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_AMERIGO_GET_CSV:
      return {
        ...state,
        isFetching: true,
      }

    case ACTION_AMERIGO_TOGGLE_CSV:
      return {
        ...state,
        fileUploaderStatus: action.fileUploaderStatus,
      }

    case ACTION_AMERIGO_UPDATE_CSV:
      return {
        ...state,
        isFetching: false,
        header: action.header,
        csvCount: action.csvCount,
        csvRows: action.csvRows,
      }

    case ACTION_AMERIGO_CLEAR_CSV:
      return {
        ...state,
        csvCount: 0,
        csvRows: [],
      }

    case ACTION_AMERIGO_DELETE_ROW_CSV:
      state.csvRows[action.arrayStatus] = state.csvRows[action.arrayStatus]
        .filter(csvRow => csvRow.index !== action.rowIndex)
      return {
        ...state,
        csvCount: +state.csvCount - 1,
        csvRows: state.csvRows,
      }

    case ACTION_AMERIGO_UPDATE_ERRORS_ROW_STATUS_CSV:
      return {
        ...state,
        errosRowStatus: action.errosRowStatus,
      }

    default:
      return state
  }
}
