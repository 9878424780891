import {MARKETPLACE_CATALOG_AF} from 'constants/defaultConstants'
import {getCurrentMarketplace} from './getData'

export function isInReliable() {
  const isInReliable = window.location.href.indexOf('reliable') > -1

  return isInReliable ? 'reliable.' : ''
}

export function isInReliableBool() {
  return window.location.href.indexOf('/reliable') > -1
}

export function isInAmerigoBool() {
  return window.location.hostname.split('.').some(subdomain => subdomain === 'amerigo')
}

export function isInFavoriteBool() {
  return window.location.href.indexOf('/favorite') > -1
}

export function validateEmail() {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const getSpinnerHeight = content => {
  const windowHeight = window.innerHeight
  const offset = content?.current?.offsetTop || null

  // ? Calculate the required height for the loader.
  return windowHeight - offset
}

const getValueFromQuery = (query, id) => {
  const item = query.get(id)
  if (!item) return null
  return parseInt(item, 10)
}

export const getValuesFromQuery = (query, ...ids) => {
  const results = []
  ids.forEach(id => {
    const result = getValueFromQuery(query, id)
    if (!result) return
    results.push(result)
  })
  return results
}

export const scrollToTop = (height = 0, time = 700) => $('html, body').animate({
  scrollTop: height,
}, time)

export const isHostPunchOut = () => process.env.REACT_APP_SUBDOMAIN_PUNCHOUT.split('|').some(subdomain => window.location.host.includes(subdomain))
export const isHostIframeTCRMI = () => process.env.REACT_APP_SUBDOMAIN_IFRAME_TECRMI.split('|').some(subdomain => window.location.host.includes(subdomain))
export const isHostRetailer = () => process.env.REACT_APP_MARKETPLACE_RETAILER.split('|').some(subdomain => window.location.host.includes(subdomain))
export const isHostPublic = () => process.env.REACT_APP_MARKETPLACE_PUBLIC.split('|').some(subdomain => window.location.host.includes(subdomain))
export const isHostPublicTr = () => {
  return process.env.REACT_APP_MARKETPLACE_PUBLIC_TR.split('|').some(subdomain => window.location.host.includes(subdomain))
}
export const isHostAF = () => getCurrentMarketplace() === MARKETPLACE_CATALOG_AF

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const checkClientIdFromLocalStorage = () => {
  return localStorage.getItem('clientId') !== null
}

export const getClientIdFromLocalStorage = () => {
  return Number(localStorage.getItem('clientId'))
}
