import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getCurrentDomain, getURLParams} from 'utils/getData'
import ToggleButton from 'components/Header/components/ToogleButton'
import ToolBarNav from 'components/Header/components/ToolBarNav'
import OrderChallenge from 'components/Header/components/Toolbar/components/OrderChallenge'
import {resetCarParts} from 'store/actions/carPartsActions'
import './style.scss'

const ToolbarPunchOut = ({drawerClickHandler}) => {
  const {catalogInfo, loading} = useSelector(
    state => state.catalogInfoState,
  )
  const {user} = useSelector(state => state.userState)
  const location = useLocation()
  const urlParams = getURLParams(location.search)
  const dispatch = useDispatch()
  const punchoutLogo = localStorage.getItem('logo_url')
  const callbackUrl = localStorage.getItem('callback_url')
  const handlerResetCarParts = event => {
    if (!event.ctrlKey && !event.metaKey) {
      dispatch(resetCarParts())
    }
  }

  const getImagePath = item => `${getCurrentDomain()}/images/marketplace/catalogs/desktop/${item.image}`

  const showCatalogImage = () => !loading && catalogInfo && catalogInfo?.id === urlParams.catalog_id ? (
    <div className="toolbar-catalog-logo-punchout">
      {window.location.href.indexOf('view') !== -1 ? (
        <NavLink to={urlParams.catalog_id === 'CNT' ? '/' : `/car-parts?catalog_id=${urlParams.catalog_id}`}>
          <img src={getImagePath(catalogInfo)} alt={catalogInfo?.name} />
        </NavLink>
      ) : (
        <img src={getImagePath(catalogInfo)} alt={catalogInfo?.name} />
      )}
    </div>
  ) : null

  const showPunchOutLogo = () => punchoutLogo || user?.client_logo ? (
    callbackUrl ? (
      <a href={callbackUrl} className="toolbar-catalog-logo-punchout">
        <img
          src={punchoutLogo ? punchoutLogo : user?.client_logo}
          alt="client_logo"
        />
      </a>
    ) : (
      <NavLink
        to="/"
        onClick={event => handlerResetCarParts(event)}
        className="toolbar-catalog-logo-punchout"
      >
        <img
          src={punchoutLogo ? punchoutLogo : user?.client_logo}
          alt="client_logo"
        />
      </NavLink>
    )
  ) : null

  const checkCatalog = urlParams.hasOwnProperty('catalog_id')
    && window.location.pathname === '/car-parts'
  const checkReliable = window.location.pathname.includes('reliable')
    && window.location.pathname.includes('car-parts')
    && !window.location.pathname.includes('view')

  const filterStatus = checkCatalog || checkReliable

  let type = 'filter'

  if (urlParams.hasOwnProperty('catalog_id')) {
    type = 'catalog-filter'
  }

  return (
    <header className="toolbar">
      {filterStatus ? (
        <div className="filter-toggle-button">
          <ToggleButton toggleDrawer={drawerClickHandler} type={type} />
        </div>
      ) : null}
      {showCatalogImage()}
      {showPunchOutLogo()}
      {user?.client_name ? (
        <div className="toolbar-client-name">{user?.client_name}</div>
      ) : null}
      <OrderChallenge />
      <div className="toolbar-nav-wrapper">
        <ToolBarNav />
      </div>

      <div className="toolbar-toggle-button">
        <ToggleButton toggleDrawer={drawerClickHandler} type="menu" />
      </div>
    </header>
  )
}

export default ToolbarPunchOut
