import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {usePrevious} from 'utils/hooks'
import {RootState} from 'store/reducers/rootReducer'
import {useTranslation} from 'react-i18next'
import {fetchOrdersChallenge} from 'api/orders'
import Progress from 'components/Progress'
import {getCurrentMarketplace} from 'utils/getData'
import './style.scss'

import {ACTION_UPDATE_HAS_CHALLENGE} from '../../../../../../constants/actionsTypes'

interface IOrderChallenge {
  currentOrders: number;
  ordersTarget: number;
}

interface IOrderChallengeDetails {
  hasChallenge: boolean;
  content: string;
  banner: string;
  clientChallenge: IOrderChallenge;
}

const OrderChallenge: React.FC = (): JSX.Element => {
  const [orderChallengeDetails, setOrderChallengeDetails] = useState<IOrderChallengeDetails | null>(null)
  const marketplace = getCurrentMarketplace()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {i18n} = useTranslation()
  const dispatch = useDispatch()
  const prevLanguage = usePrevious(i18n.language)
  const {user} = useSelector((state: RootState) => state.userState)
  let isMounted = true

  useEffect(() => {
    if (marketplace == 'amerigo') return
    if ((user && user.client_name) || (prevLanguage && prevLanguage !== i18n.language)) {
      fetchOrdersChallenges()
    }

    return () => {
      isMounted = false
    }
  }, [user, i18n.language])

  const fetchOrdersChallenges = () => {
    setIsLoading(true)
    fetchOrdersChallenge()
      .then(result => {
        dispatch({
          type: ACTION_UPDATE_HAS_CHALLENGE,
          hasChallenge: result?.data?.hasChallenge,
          banner: result.data.banner,
        })
        isMounted && setOrderChallengeDetails(result.data)
      })
      .finally(() => {
        isMounted && setIsLoading(false)
      })
  }

  const orderChallengeTranslation: string = orderChallengeDetails !== null ? orderChallengeDetails.content : ''
  const translationWords: string[] = orderChallengeTranslation ? orderChallengeTranslation.split(' ') : []

  return (
    <React.Fragment>
      {!isLoading && orderChallengeDetails && orderChallengeDetails.hasChallenge
      && (
        <div className="order-challenge-container">
          <div className="order-challange-progress">
            <div className="order-challenge-title">
              <span className="order-challenge-title first">{translationWords[0]}</span>
              {translationWords.length > 1 && (
                <span className="order-challenge-title second">
                  {translationWords.length > 2 && translationWords[1]}
                  <span className="month">
                    &nbsp;
                    {translationWords.slice(translationWords.length > 2 ? 2 : 1).join(' ')}
                  </span>
                </span>
              )}
            </div>
            <Progress
              percentage={(orderChallengeDetails.clientChallenge.currentOrders / orderChallengeDetails.clientChallenge.ordersTarget) * 100}
              type="circular"
            />
          </div>
        </div>
      )}
      {}
    </React.Fragment>
  )
}

export default OrderChallenge
