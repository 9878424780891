import {AxiosResponse} from 'axios'
import {Dispatch} from 'redux'
import {IConsumable, IConsumableFilter, IConsumableSearch, IPagination} from '../store/types/consumableTypes'
import {fetchConsumableFilters,
  fetchConsumableSearch,
  loadingConsumableFilters,
  loadingConsumableSearch} from '../store/actions/reliable/consumableSearchActions'
import {fetchReliableConsumableFilter, fetchReliableConsumables} from '../api/reliable/consumable'
import {converObjectToParams, getURLParamsArray} from './getData'

export const handleConsumableFullSearch = (data: IConsumableSearch, consumable: IConsumable | null, dispatch:Dispatch<any>) => {
  handleConsumableDataSearch(data, consumable, dispatch)
  handleConsumableFilterSearch(data, consumable, dispatch)
}

export const handleConsumableDataSearch = (data: IConsumableSearch, consumable: IConsumable | null, dispatch:Dispatch<any>) => {
  dispatch(loadingConsumableSearch(true))
  fetchReliableConsumables(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<IPagination[]>) => {
      dispatch(fetchConsumableSearch({consumable, data}))
      dispatch(loadingConsumableSearch(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingConsumableSearch(false))
    })
}

export const handleConsumableFilterSearch = (data: IConsumableSearch, consumable: IConsumable | null, dispatch:Dispatch<any>) => {
  dispatch(loadingConsumableFilters(true))
  fetchReliableConsumableFilter(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<IConsumableFilter>) => {
      dispatch(fetchConsumableFilters(data))
      dispatch(loadingConsumableFilters(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingConsumableFilters(false))
    })
}
