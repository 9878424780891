export function getCarDescription(data) {
  const {
    manufacturer_name,
    short_name,
    name,
    engine_capacity,
    capacity_qualifier,
    gear_box_description,
    body_description,
    start_year,
    start_month,
    end_year,
    end_month,
    fap_description,
    nc_of_Cylinder,
    nb_of_cylinder,
    kw_power,
    horse_power,
    model_id,
    model_name,
    manufacturerName,
  } = data
  let carName = ''

  if (manufacturer_name) {
    carName = manufacturer_name
  }

  if (short_name || model_name) {
    carName = `${carName} ${short_name || model_name}`
  }
  if (manufacturerName) {
    carName = manufacturerName
  }

  if (name) {
    carName = `${carName} ${name}`
  }

  if (engine_capacity) {
    carName = `${carName} ${engine_capacity}`
  }

  if (capacity_qualifier) {
    carName = `${carName} ${capacity_qualifier}`
  }

  if (body_description) {
    carName = `${carName} ${body_description}`
  }

  if (gear_box_description) {
    carName = `${carName} ${gear_box_description}`
  }

  if (start_month && start_year) {
    carName = `${carName} ${start_month}/${start_year}`
  }

  if (end_month && end_year) {
    carName = `${carName} - ${end_month}/${end_year}`
  }

  if (fap_description) {
    carName = `${carName} ${fap_description}`
  }

  if (nc_of_Cylinder || nb_of_cylinder) {
    carName = `${carName} ${nc_of_Cylinder || nb_of_cylinder}`
  }

  if (kw_power) {
    carName = `${carName} - ${kw_power} KW`
  }

  if (horse_power) {
    carName = `${carName} - ${horse_power} CH DIN`
  }

  if (model_id) {
    carName = `${carName} ${model_id}`
  }

  return carName
}
