import {
  ACTION_UPLOAD_PROFORMA_FILE,
  ACTION_UPLOAD_PROFORMA_FILE_SUCCESS,
  ACTION_UPDATE_PROFORMA_DELIVERY_DATE,
  ACTION_UPDATE_PROFORMA_ROWS,
  ACTION_UPLOAD_PROFORMA_FILE_FAILURE,
  ACTION_CLEAR_PROFORMA_CSV,
  ACTION_CLOSE_SPINER,
} from 'constants/actionsTypes'

export const initialState = {
  fileUploaderStatus: null,
  csvFileName: '',
  csvCount: 0,
  csvRows: [],
  csvRowsHeader: [],
  deliveryDate: '',
  errors: null,
  isFetching: false,
}

export default function fileUploaderReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPLOAD_PROFORMA_FILE:
      return {
        ...state,
        isFetching: true,
      }

    case ACTION_UPLOAD_PROFORMA_FILE_SUCCESS:
      return {
        ...state,
        csvFileName: action.csvFileName,
        csvCount: action.csvCount,
        csvRowsHeader: action.csvRowsHeader,
        csvRows: action.csvRows,
        isFetching: false,
      }
    case ACTION_UPDATE_PROFORMA_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.deliveryDate,
      }
    case ACTION_UPDATE_PROFORMA_ROWS:
      return {
        ...state,
        csvRows: action.csvRows,
        csvCount: action.csvCount,
      }
    case ACTION_UPLOAD_PROFORMA_FILE_FAILURE:
      return {
        ...state,
        errors,
        isFetching: false,
      }
    case ACTION_CLEAR_PROFORMA_CSV:
      return {
        ...state,
        ...initialState,
      }

    case ACTION_CLOSE_SPINER:
      return {
        ...state,
        isFetching: false,
      }

    default:
      return state
  }
}
