import {useEffect} from 'react'
import {Dispatch} from 'redux'
import {useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import createBrowserHistory from './history'
import {logout} from '../store/actions/authActions'
import {isHostPunchOut} from './index'
import {getURLParams} from './getData'
import {IUrlParams} from '../interfaces/url-params.interface'

const punchOutHandleUnauthorized = (dispatch: Dispatch<any>) => {
  const history = createBrowserHistory
  if (!localStorage.getItem('callback_url')) {
    dispatch(logout())
    history.push('/platform-unauthorized')
  }
}

export const usePunchOut = (): void => {
  const location = useLocation()
  const dispatch = useDispatch()
  const urlParams: IUrlParams = getURLParams(location.search)
  const {callback_url, callback_order_url, logo_url} = urlParams
  useEffect(() => {
    if (isHostPunchOut()) {
      if (callback_url) {
        localStorage.setItem('callback_url', callback_url)
        localStorage.setItem('callback_order_url', callback_order_url || '')
        localStorage.setItem('logo_url', logo_url || '')
      } else {
        punchOutHandleUnauthorized(dispatch)
      }
    }
  }, [])
}
