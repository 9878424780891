import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {fetchReliableVRM} from 'api/reliable/search'
import {converObjectToParams} from 'utils/getData'
import {getCarDescription} from 'utils/getCarDescription'
import {getTruckDescription} from 'utils/getTruckDescription'
import {setVehicleId} from 'store/actions/reliable/vehicleCategoriesActions'
import Spinner from 'components/Spinner'
import {CAR_IDENTIFIER, SOURCE_TECDOC, TRUCK_IDENTIFIER} from 'constants/defaultConstants'

interface ISearchVRMProps {
    activeButtonIndex: string
}

const SearchVRM: React.FC<ISearchVRMProps> = ({activeButtonIndex}): JSX.Element => {
  const history = useHistory()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [vrm, setVrm] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<Array<any>>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [isCar, setIsCar] = useState(false)
  const [isTruck, setIsTruck] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (activeButtonIndex !== 'vrm') {
      setShowSuggestions(false)
      setSuggestions([])
    }
  }, [activeButtonIndex])

  const submitHandler = (event: React.FormEvent<HTMLElement>): void => {
    event.preventDefault()

    if (vrm && vrm.trim() && vrm.length >= 2) {
      getVrm({
        registration_plate: vrm,
      })
    }
  }

  const getVrm = (listQuery: {registration_plate: string}): void => {
    setShowSuggestions(true)
    setShowSpinner(true)
    setSuggestions([])

    fetchReliableVRM(listQuery)
      .then(({data}) => {
        setSuggestions([])
        if (data.cars.cars) {
          setIsCar(true)
          setIsTruck(false)
          setSuggestions(data.cars.cars)
        }

        if (data.cars.trucks) {
          setIsTruck(true)
          setIsCar(false)
          setSuggestions(data.cars.trucks)
        }
        setShowSpinner(false)
      })
      .catch(() => {
        setSuggestions([])
        setShowSpinner(false)
      })
  }

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {value} = event.target
    setVrm(value)
    setShowSuggestions(false)
  }

  const clearVRM = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()

    inputRef.current && inputRef?.current.focus()

    setVrm('')
    setSuggestions([])
    setShowSuggestions(false)
  }

  const clickPlateNumberHandler = (item: {id: string, source: string}): void => {
    const {id, source} = item
    const vehicle_identifier = isCar ? CAR_IDENTIFIER : TRUCK_IDENTIFIER

    const data = {
      source: source || SOURCE_TECDOC,
      registration_plate: vrm,
      vehicle_identifier,
    }

    setShowSuggestions(false)
    setSuggestions([])

    dispatch(setVehicleId(Number(id)))
    history.push({
      pathname: `/reliable/rmi/${id}/categories`,
      search: `?${converObjectToParams(data)}`,
    })
  }

  const plateNumberListComponent = (vrmCssClass: string): JSX.Element | null => {
    if (showSuggestions && vrm) {
      return (
        <div className={`result-search ${suggestions.length ? '' : 'no-result'} ${vrmCssClass}`}>
          <div className="wrapper">
            <div className="suggestion-title">{t('RESULTS_VRM_LOOKUP')}</div>
          </div>
          <ul className={`suggestions ${suggestions.length >= 10 ? 'scroll' : ''}`}>
            {showSpinner
              ? <Spinner type="skeleton-line" />
              : suggestions.length ? suggestions.map((item, index) => (
                <li key={index} onClick={() => clickPlateNumberHandler(item)} style={{color: item.is_selected ? 'purple' : '#212529'}}>
                  {isCar ? getCarDescription(item) : null}
                  {isTruck ? getTruckDescription(item) : null}
                </li>
              )) : <div className="no-suggestions">{`${t('NO_DATA')}!`}</div>}
          </ul>
        </div>
      )
    }

    return null
  }

  return (
    <div className={`${activeButtonIndex !== 'vrm' ? 'd-none' : 'inner-search-wrapper'}`}>
      <form
        className="search-form custom-search-form"
        onSubmit={submitHandler}
      >
        <div className="inner-search-container">
          <div className="search-container vrm">
            <input
              ref={inputRef}
              className="search-header form-control"
              type="text"
              placeholder={t('VRM_LOOKUP')}
              aria-label="Search"
              value={vrm}
              onChange={updateSearchValue}
            />
            {vrm && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearVRM} />}
            {plateNumberListComponent('desktop')}
          </div>

          <button
            className={`button-style search-toolbar-button ${vrm && vrm.trim() && vrm.length >= 2 ? 'button-style-hover' : 'button-disabled-style'}`}
            type="submit"
          >
            <i className="material-icons">search</i>
          </button>
        </div>
        {plateNumberListComponent('mobile')}
      </form>
    </div>
  )
}

export default SearchVRM
