export function getTruckDescription(data) {
  const {
    model_name,
    manufacturer_name,
    name,
    engine_capacity,
    body_description,
    start_year,
    start_month,
    end_year,
    end_month,
    weight,
    power_kw_from,
    power_kw_to,
    power_hp_from,
    power_hp_to,
  } = data
  let truckName = ''

  if (manufacturer_name) {
    truckName = manufacturer_name
  }

  if (model_name) {
    truckName = `${truckName} ${model_name}`
  }

  if (name) {
    truckName = `${truckName} ${name}`
  }

  if (engine_capacity) {
    truckName = `${truckName} ${engine_capacity}`
  }

  if (body_description) {
    truckName = `${truckName} ${body_description}`
  }

  if (start_month && start_year) {
    truckName = `${truckName} ${start_month}/${start_year}`
  }

  if (end_month && end_year) {
    truckName = `${truckName} - ${end_month}/${end_year}`
  }

  if (weight) {
    truckName = `${truckName} ${weight}`
  }

  if (power_kw_from) {
    truckName = `${truckName} ${power_kw_from}`
  }

  if (power_kw_to) {
    truckName = `${truckName} ${power_kw_to}`
  }

  if (power_hp_from) {
    truckName = `${truckName} ${power_hp_from}`
  }

  if (power_hp_to) {
    truckName = `${truckName} - ${power_hp_to}`
  }

  return truckName
}
