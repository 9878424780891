import React from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import '../style.scss'

const InternalServerError = () => {
  const {t} = useTranslation()

  return (
    <div className="wrapper-not-found-page">
      <div className="not-found-page">
        <div className="not-found-page-image">
          <img src="/images/500_outline.svg" alt="500_outline" />
          <img src="/images/500.svg" alt="500" />
        </div>
        <div className="not-found-page-text">
          {t('INTERNAL_SERVER_ERROR')}
        </div>
        <div className="not-found-page-button">
          <NavLink
            to="/"
            className="button button-style"
          >
            {t('HOME')}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default InternalServerError
