import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation, useHistory} from 'react-router-dom'
import _ from 'underscore'
import {getURLParams, converObjectToParams} from 'utils/getData'
import {fetchCategoriesDispatch, resetCategories} from 'store/actions/marketplace/catalogCategoriesActions'
import {fetchCatalogData, resetCatalogData, updateLoadingCatalog, updateLoadingPageCatalog} from 'store/actions/marketplace/catalogActions'
import {fetchCatalogModels} from 'api/catalogs'
import {fetchCatalogBrandsDispatch} from 'store/actions/marketplace/catalogBrandsActions'
import axios from 'axios'
import BrandFilter from './Brand'
import ModelFilter from './Model'
import './style.scss'

const CatalogFilters = ({closePanel, fromSideDrawerMenu}) => {
  const source = axios.CancelToken.source()
  const cancelToken = source.token
  const location = useLocation()
  const dispatch = useDispatch()
  const {catalogData} = useSelector(state => state.catalogState)
  const {brands} = useSelector(state => state.catalogBrandsState)
  const history = useHistory()

  const [models, setModels] = useState([])
  const [isLoadingModels, setLoadingModels] = useState(false)
  const [filtered, setFiltered] = useState([])
  const urlParams = getURLParams(location.search)
  const [filterParams, setFilterParams] = useState({catalog_id: urlParams.catalog_id})

  useEffect(() => {
    if (brands && urlParams.manufacturer_id) {
      setFilterParams({...filterParams, manufacturer_id: urlParams.manufacturer_id})
      fetchModels(urlParams.manufacturer_id)

      const url = new URL(window.location.href)
      const modelIds = url.searchParams.getAll('model_id[]')
      if (modelIds) {
        setFiltered(modelIds)
      }
    }
  }, [brands])

  const fetchModels = manufacturer_id => {
    setLoadingModels(true)

    fetchCatalogModels({manufacturer_id, catalog_id: urlParams.catalog_id})
      .then(({data}) => {
        setLoadingModels(false)
        setModels(data)
      })
  }

  const grabBrand = manufacturer_id => {
    setFilterParams({
      ...filterParams,
      manufacturer_id,
    })

    fetchModels(manufacturer_id)
    history.push({
      search: `?${converObjectToParams({
        catalog_id: urlParams.catalog_id,
        manufacturer_id,
        model_id: [],
      })}`,
    })
    dispatch(resetCategories())
    dispatch(fetchCategoriesDispatch({catalog_id: urlParams.catalog_id, manufacturer_id}))
  }

  const resetCatalog = status => {
    if (_.isEmpty(catalogData)) {
      dispatch(updateLoadingCatalog(status))
    } else {
      dispatch(updateLoadingPageCatalog(status))
    }
  }

  const grabModel = model => {
    resetCatalog(true)
    setFilterParams({
      ...filterParams,
      model_id: model,
    })
    history.push({
      search: `?${converObjectToParams({
        ...filterParams,
        model_id: model,
      })}`,
    })
    if (fromSideDrawerMenu) {
      closePanel()
    }
    dispatch(fetchCategoriesDispatch({
      ...filterParams,
      model_id: model,
    }))
    dispatch(fetchCatalogData({...filterParams, model_id: model}, cancelToken)).then(() => {
      resetCatalog(false)
    })
  }

  const resetFilter = () => {
    setModels([])
    history.push({
      search: `?${converObjectToParams({
        catalog_id: urlParams.catalog_id,
      })}`,
    })
    dispatch(resetCatalogData())
    dispatch(fetchCatalogBrandsDispatch({catalog_id: urlParams.catalog_id}))
    dispatch(fetchCategoriesDispatch({catalog_id: urlParams.catalog_id}))
    if (fromSideDrawerMenu) {
      closePanel()
    }
  }

  return (
    <>
      <BrandFilter grabBrand={grabBrand} />
      <ModelFilter isLoadingModels={isLoadingModels} models={models} grabModel={grabModel} filtered={filtered} resetFilter={resetFilter} />
    </>
  )
}

export default CatalogFilters
