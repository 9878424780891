import React, {useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {fileUploaderToggle} from 'store/actions/fileUploaderActions'
import {logout} from 'store/actions/authActions'
import {TOOLBAR_TARIFFS_SUPPLIER, TOOLBAR_MENU_NAV, TOOLBAR_MENU_EINA, MENU_NAV_LINK} from 'constants/menuNavLinks'
import {showModal} from 'store/actions/modalsActions'
import {IMenuNavLink} from 'interfaces/menu-nav-link.interface'
import {getCurrentMarketplace} from 'utils/getData'
import {RootState} from 'store/reducers/rootReducer'
import {checkPermissionEina} from 'utils/hooks'

import './style.scss'

interface IMenuSideDrawer {
  backdropClickHandler: (item: boolean) => void;
}

const MenuSideDrawer : React.FC<IMenuSideDrawer> = ({backdropClickHandler}): JSX.Element => {
  const {user} = useSelector((state: RootState) => state.userState)
  const marketplace:any = getCurrentMarketplace()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const location = useLocation()
  const {unseenMessages} = useSelector((state: RootState) => state.messengerState)
  const getTitleWithNotifications = (title:string, number:number): string => {
    return `${t(title)} ${number > 0 ? `(${number})` : ''}`
  }
  const hasFinancialEina = checkPermissionEina()
  const closeMenuSideDrawerClickHandler = (): void => {
    // dispatch(resetCarParts())
    backdropClickHandler(false)
  }
 
  const hasRoles = (roles: string[] = []): boolean => {
    return roles.some((role: string) => user?.roles.includes(role))
  }

  const handlerLogout = (): void => {
    dispatch(logout())
  }

  const handleLogoutForRoleClientPlatform = () => {
    dispatch(logout())
    const callbackUrl = localStorage.getItem('callback_url')
    if (callbackUrl) {
      window.location.href = callbackUrl
    }
  }

  const handleCsvClick = (): void => {
    closeMenuSideDrawerClickHandler()
    dispatch(fileUploaderToggle())
  }

  const openUploadProformaModal = (): void => {
    closeMenuSideDrawerClickHandler()
    dispatch(showModal({
      open: true,
    }, 'UploadProformaModal'))
  }

  const openUploadAmerigoProformaModal = () => {
    dispatch(showModal({
      open: true,
    }, 'UploadAmerigoProformaModal'))
  }

  const methods: { [index: string]: () => void } = {
    closeMenuSideDrawerClickHandler,
    handlerLogout,
    handleCsvClick,
    openUploadProformaModal,
    handleLogoutForRoleClientPlatform,
    openUploadAmerigoProformaModal,
  }

  let MENU_NAV = [...MENU_NAV_LINK, ...TOOLBAR_MENU_NAV]
  if (hasFinancialEina) {
    MENU_NAV = [...TOOLBAR_MENU_EINA, ...MENU_NAV]
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  // Tarrifs suppblier for amerigo
  if (window.location.hostname.split('.').some(subdomain => subdomain === 'amerigo')) {
    TOOLBAR_TARIFFS_SUPPLIER.map((item, index) => MENU_NAV.splice((index + 3), 0, item))
  }
  return (
    <nav className="menu-side-drawer open-drawer">
      <ul>
        {
          MENU_NAV.map(({
            roles,
            marketplace_sensible,
            to,
            rel,
            target,
            liClassName,
            iconClassName,
            iconName,
            title,
            callback_method,
          }: IMenuNavLink, index) => (
            (marketplace_sensible?.includes(marketplace) ? marketplace_sensible : hasRoles(roles)) ? (
              <li
                {...(liClassName && {className: liClassName})}
                key={iconName + String(index)}
              >
                <NavLink
                  to={{pathname: typeof to === 'string' ? to : to(`${process.env.REACT_APP_ADMIN_PANEL ?? ''}`, localStorage.getItem('token') || '')}}
                  className={location.pathname === to ? 'active-path' : ''}
                  {...(rel && {rel})}
                  {...(target && {target})}
                  title={t(title)}
                  onClick={() => (methods[callback_method] ? methods[callback_method]() : null)}
                >
                  <i className={iconClassName}>{iconName}</i>
                  {
                    title === 'MESSAGING' ? (
                      <span className="tooltip-item">{getTitleWithNotifications(title, unseenMessages)}</span>
                    ) : (
                      <span>{title ? t(title) : (user ? user.full_name : '')}</span>
                    )
                  }
                </NavLink>
              </li>
            ) : null
          ))
        }
      </ul>
    </nav>
  )
}

export default MenuSideDrawer
