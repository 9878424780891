import {combineReducers} from 'redux'
import carPartsReducer from './carPartsReducer'
import cartReducer from './cartReducer'
import fileUploaderReducer from './fileUploaderReducer'
import amerigoFileUploaderReducer from './amerigoFileUploaderReducer'
import errorReducer from './errorReducer'
import userReducer from './userReducer'
import messengerReducer from './messengerReducer'
import manuallySearchReducer from './reliable/manuallySearchReducer'
import vehicleCategoriesReducer from './reliable/vehicleCategoriesReducer'
import breadcrumbsReducer from './reliable/breadcrumbsReducer'
import carPartsReferenceReducer from './reliable/carPartsReferenceReducer'
import tecRMIReduce from './reliable/tecRMIReduce'
import categoriesReducer from './marketplace/catalogCategoriesReducer'
import catalogReducer from './marketplace/catalogReducer'
import catalogBrandsReducer from './marketplace/catalogBrandsReducer'
import selectorReducer from './selectorReducer'
import catalogInfoReducer from './marketplace/catalogInfoReducer'
import modal from './modalsReducer'
import favoriteCategoriesReducer from './favoriteCategoriesReducer'
import proformaOrderReducer from './proformaOrderReducer'
import proformaAmerigoOrderReducer from './proformaAmerigoOrderReducer'
import carPartReducer from './carPartReducer'
import vinReducer from './catalog/vinReducers'
import challengeReducer from './challengeReducer'
import oilSearchReducer from './reliable/oilSearchReducer'
import tyreSearchReducer from './reliable/tyreReducer'
import consumableSearchReducer from './reliable/consumableSearchReducer'

export const rootReducer = combineReducers({
  carPartsState: carPartsReducer,
  manuallySearchState: manuallySearchReducer,
  categoriesState: categoriesReducer,
  cartState: cartReducer,
  catalogState: catalogReducer,
  catalogInfoState: catalogInfoReducer,
  catalogBrandsState: catalogBrandsReducer,
  vehicleCategoriesState: vehicleCategoriesReducer,
  breadcrumbsState: breadcrumbsReducer,
  carPartsReferenceState: carPartsReferenceReducer,
  fileUploaderState: fileUploaderReducer,
  amerigoFileUploaderState: amerigoFileUploaderReducer,
  errorState: errorReducer,
  userState: userReducer,
  selectorState: selectorReducer,
  modal,
  favoriteCategoriesState: favoriteCategoriesReducer,
  tecRMIState: tecRMIReduce,
  proformaOrder: proformaOrderReducer,
  proformaAmerigoOrder: proformaAmerigoOrderReducer,
  carPartState: carPartReducer,
  messengerState: messengerReducer,
  challengeState: challengeReducer,
  vinState: vinReducer,
  oilSearchState: oilSearchReducer,
  tyreSearchState: tyreSearchReducer,
  consumableSearchState: consumableSearchReducer,
})

export type RootState = ReturnType<typeof rootReducer>
