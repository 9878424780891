import {ACTION_FETCH_MODELS_VIN, ACTION_FETCH_CAR_PARTS_MODEL, ACTION_UPDATE_MODELS_VIN, ACTION_UPDATE_CAR_PARTS_MODEL, ACTION_RESET_MODELS_VIN} from 'constants/actionsTypes'

export const initialState = {
  models: {
    cars: null,
    trucks: null,
  },
  carparts: null,
  loadingModels: false,
  loadingCarparts: false,
}

export default function vinReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_FETCH_MODELS_VIN:
      return {
        ...state,
        models: action.models,
      }

    case ACTION_RESET_MODELS_VIN:
      return {
        ...state,
        models: null,
        loadingModels: false,
      }

    case ACTION_FETCH_CAR_PARTS_MODEL:
      return {
        ...state,
        carparts: action.carparts,
      }

    case ACTION_UPDATE_MODELS_VIN:
      return {
        ...state,
        loadingModels: action.loadingModels,
      }

    case ACTION_UPDATE_CAR_PARTS_MODEL:
      return {
        ...state,
        loadingCarparts: action.loadingCarparts,
      }

    default:
      return state
  }
}
