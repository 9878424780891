import {FETCH_CATALOG_INFO, ACTION_UPDATE_CATALOG_INFO} from 'constants/actionsTypes'

export const initialState = {
  catalogInfo: null,
  loading: false,
}

export default function catalogInfoReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_CATALOG_INFO:
      return {
        ...state,
        loading: false,
        catalogInfo: action.catalogInfo,
      }
    case FETCH_CATALOG_INFO:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}
