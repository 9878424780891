import React, {useState, useEffect} from 'react'
import './styles.scss'
import {usePermission} from 'utils/hooks'
import request from 'utils/request'

const LogoSupplier = ({role}) => {
  const hasPermission = usePermission(role)
  const [imageURL, setImageURL] = useState('')

  function getImageURL() {
    return request({
      url: '/seller/supplier-logo',
      method: 'get',
    }).then(response => {
      setImageURL(response.data)
    })
  }

  useEffect(() => {
    if (hasPermission) {
      getImageURL()
    }
  }, [])

  return (
    <>
      {hasPermission ? <img className="second-logo" src={imageURL} height="40" width="40" alt="" /> : ''}
      {}
    </>
  )
}

export default LogoSupplier
