let searchValue = ''

export const toLower = item => item ? item.toString().toLowerCase() : ''

export const getIsSearched = (value, name) => toLower(name).includes(value)

export const hasSearchedItems = (categories = []) => categories.some(({isSearched, hasInnerSearches}) => {
  return isSearched || (hasInnerSearches || false)
})

const getMutated = categories => {
  if (!categories) return

  return categories.map(category => {
    const isSearched = getIsSearched(searchValue, category.name)
    let subcategories = category.subcategories

    // ? if it's not a sub-sub category
    if (subcategories) {
      subcategories = getMutated(subcategories)
      const hasInnerSearches = hasSearchedItems(subcategories)

      return {
        ...category,
        isSearched,
        subcategories,
        hasInnerSearches,
      }
    }

    return {
      ...category,
      isSearched,
    }
  })
}

export const getFiltered = (categories = []) => categories.filter(({isSearched, hasInnerSearches}) => {
  return isSearched || hasInnerSearches
})

export const searchCategories = (value = '', categories = []) => {
  if (!categories) return
  searchValue = value
  return getMutated(categories)
}

export const clearSearches = (categories = []) => categories.map(category => {
  let subcategories = category.subcategories
  if (subcategories) {
    delete category.hasInnerSearches
    delete category.isSearched
    subcategories = clearSearches(subcategories)
    return {
      ...category,
      subcategories,
    }
  }
  delete category.isSearched
  return category
})
