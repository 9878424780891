import {ACTION_UPDATE_UNSEEN_MESSAGES} from 'constants/actionsTypes'

export const initialState = {
  unseenMessages: 0,
}

export default function messengerReducer(state = initialState, action: { type: string; unseenMessages: number } = {type: '', unseenMessages: 0}): any {
  switch (action.type) {
    case ACTION_UPDATE_UNSEEN_MESSAGES:
      return {
        ...state,
        unseenMessages: action.unseenMessages,
      }
    default:
      return state
  }
}
