import React from 'react'
import {useTranslation} from 'react-i18next'

import Selector from 'components/Selector'

const ManufacturerDropdown = props => {
  const {t} = useTranslation()

  const handleSelectManufacturer = event => {
    const data = event.target.value
    props.handleGrabManufacturer(data)
  }
  return (
    <Selector
      title={props.loading ? t('LOADING_BRANDS') : t('BRAND')}
      selectId={`select-brand-${props.id}`}
      value={props.currentManufacture}
      disabledState={props.loading || props.disable}
      data={props.manufactures}
      selectHandler={handleSelectManufacturer}
      loading={props.loading}
      selectValueObject
    />
  )
}

export default ManufacturerDropdown
