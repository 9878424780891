import {AxiosPromise} from 'axios'
import request from 'utils/request'
import {converObjectToParams} from 'utils/getData'

export function getQuickFilters(): AxiosPromise<any> {
  return request({
    url: 'tyres/quick-filter',
    method: 'get',
  })
}
export function searchDimensions(dimension:string, {cancelToken}:any): AxiosPromise<any> {
  return request({
    url: `tyres/search-dimension?q=${dimension}`,
    method: 'get',
    cancelToken,
  })
}
export function resultCount(params:any): AxiosPromise<any> {
  return request({
    url: `tyres/count?${converObjectToParams(params)}`,
    method: 'get',
  })
}
