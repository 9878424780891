import React, {useState} from 'react'
import Backdrop from '../components/Backdrop'
import CatalogFiltersSideDrawer from '../components/CatalogFiltersSideDrawer'
import './style.scss'
import ToolbarAF from './components/ToolbarAF'
import MenuSideDrawerAF from './components/MenuSideDrawerAF'

const HeaderAF = () => {
  const [type, setType] = useState(null)

  const drawerToggleClickHandler = newType => {
    setType(newType)
  }

  const backdropClickHandler = () => {
    setType(null)
  }

  return (
    <div className="header">
      <div className="header-wrapper">
        <ToolbarAF drawerClickHandler={drawerToggleClickHandler} />
        {
          type === 'menu' && <MenuSideDrawerAF backdropClickHandler={backdropClickHandler} />
        }
        {
          type === 'catalog-filter' && <CatalogFiltersSideDrawer backdropClickHandler={backdropClickHandler} />
        }
        {type ? <Backdrop click={backdropClickHandler} /> : null}
      </div>
    </div>
  )
}

export default HeaderAF
