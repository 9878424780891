import {ACTION_CAR_PARTS_REFERENCES, ACTION_CAR_PARTS_FILTERS, FETCH_CAR_PARTS_FILTERS, ACTION_RESET_CAR_PARTS_REFERENCE, FETCH_CAR_PARTS_REFERENCES} from 'constants/actionsTypes'

export const initialState = {
  carPartsReference: null,
  loading: false,
  filterLoading: false,
  filters: null,
  total_pages: 0,
  pagination: null,
}

export default function carPartsReferenceReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_CAR_PARTS_REFERENCES:
      return {
        ...state,
        carPartsReference: action.carPartsReference.data,
        loading: false,
        pagination: {
          total: action.carPartsReference.total_count,
          page: action.carPartsReference.page,
          per_page: action.carPartsReference.per_page,
        },
      }

    case ACTION_RESET_CAR_PARTS_REFERENCE:
      return {
        ...state,
        carPartsReference: null,
        pagination: null,
        loading: false,
      }
    case ACTION_CAR_PARTS_FILTERS:
      return {
        ...state,
        filters: action.filters,
        filterLoading: false,
      }
    case FETCH_CAR_PARTS_FILTERS:
      return {
        ...state,
        filterLoading: true,
      }
    case FETCH_CAR_PARTS_REFERENCES:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}
