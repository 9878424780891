import {ACTION_RESET_CATALOG_DATA, ACTION_UPDATE_CATALOG_DATA, ACTION_UPDATE_LOADING_CATALOG, ACTION_UPDATE_LOADING_PAGE_CATALOG} from 'constants/actionsTypes'
import {fetchCatalog} from 'api/catalogs'

export const fetchCatalogData = (data, cancelToken) => dispatch => {
  return fetchCatalog(data, cancelToken)
    .then(response => {
      dispatch({
        type: ACTION_UPDATE_CATALOG_DATA,
        catalogData: response.data,
      })
    })
}

export function resetCatalogData() {
  return {
    type: ACTION_RESET_CATALOG_DATA,
  }
}

export function updateLoadingCatalog(loadingCatalog) {
  return {
    type: ACTION_UPDATE_LOADING_CATALOG,
    loadingCatalog,
  }
}

export function updateLoadingPageCatalog(loadingPageCatalog) {
  return {
    type: ACTION_UPDATE_LOADING_PAGE_CATALOG,
    loadingPageCatalog,
  }
}
