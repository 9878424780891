import {ACTION_UPDATE_HAS_CHALLENGE} from '../../constants/actionsTypes'

export const initialState = {
  hasChallenge: false,
  loadingChallenge: true,
  banner: '',
}

export default function challengeReducer(state = initialState, action: { type: string; hasChallenge: number, banner: string } = {type: '', hasChallenge: 0, banner: ''}): any {
  switch (action.type) {
    case ACTION_UPDATE_HAS_CHALLENGE:
      return {
        ...state,
        loadingChallenge: false,
        hasChallenge: action.hasChallenge,
        banner: action.banner,
      }
    default:
      return state
  }
}
