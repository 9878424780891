import {ACTION_CLEAR_ERROR_STATUS, ACTION_BAD_REQUEST_ERROR, ACTION_INTERNAL_SERVER_ERROR} from 'constants/actionsTypes'
import {ACTION_BAD_GATEWAY_ERROR, ACTION_UNPROCESSABLE_ENTITY_ERROR} from '../../constants/actionsTypes'

export function clearErrorStatus() {
  return {
    type: ACTION_CLEAR_ERROR_STATUS,
  }
}

export function internalServerError() {
  return {
    type: ACTION_INTERNAL_SERVER_ERROR,
    serverError: true,
  }
}

export function badRequestError(errorResponse) {
  return {
    type: ACTION_BAD_REQUEST_ERROR,
    badRequest: errorResponse,
  }
}

export function badGatewayError(errorResponse) {
  return {
    type: ACTION_BAD_GATEWAY_ERROR,
    badGateway: errorResponse,
  }
}

export function unprocessableEntityError(errorResponse) {
  return {
    type: ACTION_UNPROCESSABLE_ENTITY_ERROR,
    unprocessableEntity: errorResponse,
  }
}
