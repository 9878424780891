import React from 'react'
import ReactDOM from 'react-dom'
import {Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import $ from 'jquery'
import store from 'store'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import * as serviceWorker from './serviceWorker'
import history from './utils/history'
import './i18n'
import Public from './Public'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import './index.scss'

$.fn.selectpicker.Constructor.BootstrapVersion = '4'

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

const app = (
  <Provider store={store}>
    <Router history={history}>
      <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
          <Public />
        </DndProvider>
      </React.StrictMode>
    </Router>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

serviceWorker.unregister()
