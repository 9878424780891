import request from 'utils/request'

export function uploadCSV(url, data) {
  return request({
    url,
    method: 'post',
    data,
    headers: {'content-type': 'multipart/form-data'},
  })
}
export function uploadCSVAmerigo(url, data) {
  return request({
    url,
    method: 'post',
    data,
    headers: {'content-type': 'multipart/form-data'},
  })
}
export function getFileHeader(file, headerLine) {
  const data = new FormData()
  data.append('csv', file)
  data.append('header_line', headerLine)
  return request({
    url: '/proforma-file-header',
    method: 'post',
    data,
    headers: {'content-type': 'multipart/form-data'},
  })
}
