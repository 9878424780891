import {login} from 'api/auth'
import store from 'store'
import {isHostAF} from 'utils'
import {fetchUserInfo, resetAccountSettings, updateUserInfo} from './userActions'
import {ROLE_ADV, ROLE_SELLER, ROLE_SUPPLIER_AMERIGO} from '../../constants/roles'

export function auth({username, password, history, location}) {
  return dispatch => {
    return login({username, password})
      .then(response => {
        localStorage.setItem('token', response.data.token)
        dispatch(fetchUserInfo(response.data.user)).then(() => {
          redirectToRouteBasedOnUserInfo(history, location)
        })
      })
  }
}

const redirectToRouteBasedOnUserInfo = (history, location) => {
  const {user} = store.getState().userState
  const isAF = isHostAF()
  // !not force change password && !not role_seller or role_adv
  if (!user.force_change_password && ![ROLE_SELLER, ROLE_ADV, ROLE_SUPPLIER_AMERIGO].some(role => user?.roles.includes(role)) && !isAF) {
    history.push((
      location.state
      && location.state.from
      && location.state.from.pathname !== '/'
      && location.state.from.pathname.includes('_')
    ) ? {
        pathname: location.state.from.pathname,
        search: location.state.from.search,
      } : '/')
  }
}

export function logout() {
  return dispatch => {
    localStorage.clear()

    if (store.getState().userState.user) {
      dispatch(resetAccountSettings())
      dispatch(updateUserInfo(null))
    }
  }
}
