import request from 'utils/request'

export function fetchReliableVehicleCategories(vehicleId, query) {
  return request({
    url: `/reliable/${vehicleId}/categories`,
    method: 'get',
    params: query,
  })
}

export function fetchReliableVehicleSubcategories(vehicleId, query) {
  return request({
    url: `/reliable/${vehicleId}/polk-subcategory`,
    method: 'get',
    params: query,
  })
}
export function fetchRMIGraphicsCategoryGenArt(vehicleId, query) {
  return request({
    url: `/reliable/${vehicleId}/categories/genartnr`,
    method: 'get',
    params: query,
  })
}

export function fetchItemIdsByCategory(data) {
  return request({
    url: '/reliable/categories/itemMpIds',
    method: 'post',
    data,
  })
}
