import React from 'react'
import {useTranslation} from 'react-i18next'
import Selector from 'components/Selector'

const CarVersionDropdown = props => {
  const {t} = useTranslation()

  const handleSelectCarVersion = event => {
    const data = event.target.value
    props.handleGrabCarVersion(data)
  }

  return (
    <Selector
      title={t('VERSION')}
      selectId={`select-version-${props.id}`}
      value={props.currentVersion}
      disabledState={!props.currentGeneration}
      data={props.carVersions}
      selectHandler={handleSelectCarVersion}
      loading={props.loading}
    />
  )
}

export default CarVersionDropdown
