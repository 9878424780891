import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import _ from 'underscore'

import {MARKETPLACE_AMERIGO} from 'constants/defaultConstants'
import Spinner from 'components/Spinner'
import {useGeFilterParams} from 'utils/hooks'
import {converObjectToParams, getCurrentMarketplace, getURLParams} from 'utils/getData'
import {clearFilterCarParts, resetCarParts} from '../../../store/actions/carPartsActions'
import './style.scss'

const Filters = props => {
  const {t} = useTranslation()
  const marketplace = getCurrentMarketplace()
  const urlParams = getURLParams(location.search)
  const loading = useSelector(state => state.carPartsReferenceState.filterLoading)
  const [filterBySupplier] = useState([])
  const [filterByManufacturer] = useState([])
  const [filterBySubcategory] = useState([])
  const [filterByAttributes, setFilterByAttributes] = useState([])
  const [filterAttributesSearch, setFilterAttributesSearch] = useState([])
  // const [isAmerigo, setIsAmerigo] = useState(marketplace === MARKETPLACE_AMERIGO)  //Farid asked to remove Amerigo default
  const [isAmerigo, setIsAmerigo] = useState(0)
  const [filtersCollapseStatus, getFiltersCollapseStatus] = useState(true)
  const [disableIsAmerigo, setDisableIsAmerigo] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetCarParts())
      dispatch(clearFilterCarParts())
    }
  }, [])
  // set Default Supplier
  useEffect(() => {
    const {manufacture, suppliers} = useGeFilterParams()

    filterBySupplier.length = 0
    filterByManufacturer.length = 0
    suppliers.forEach(el => {
      const find = props.filters ? props.filters.data_suppliers.find(supplier => supplier.id === el) : false
      if (find) {
        filterBySupplier.push(find)
      }
      setTimeout(() => {
        document.getElementById(el) ? document.getElementById(el).checked = true : false
      }, 500)
    })

    const isAmerigoChecked = filterBySupplier.some(el => el.is_amerigo === true)
    if (isAmerigoChecked) {
      setTimeout(() => {
        enableInputs()
        setDisableIsAmerigo(true)
      }, 500)
    }
    if (props.filters) {
      manufacture.forEach(el => {
        const find = props.filters.car_manufacturers.find(elem => elem.id == el)
        if (find) {
          filterByManufacturer.push(find.id)
        }
        setTimeout(() => {
          document.getElementById(el) ? document.getElementById(el).checked = true : false
        }, 500)
      })
      if (props.filters?.attributes) {
        setFilterByAttributes([])
      }
    }
    if (marketplace === 'amerigo') {
      const url = new URL(window.location.href)
      const is_amerigo = Number(url.searchParams.get('is_amerigo'))

      if (!is_amerigo) {
        setIsAmerigo(false)
        return
      }
      document.getElementById('is-amerigo') ? document.getElementById('is-amerigo').checked = true : false
      setIsAmerigo(true)
    }
    setDisableIsAmerigo(false)
    enableInputs()
  }, [props.filters])

  useEffect(() => {
    const isAmerigoChecked = filterBySupplier.some(el => el.is_amerigo === true)
    const inputs = document.querySelectorAll("input[type='checkbox']")
    if (props.disableLoading) {
      inputs.forEach(el => {
        el.disabled = true
      })
    } else {
      // TODO:: refactor
      inputs.forEach(el => {
        if (el.dataset.amerigo === 'true') {
          if (el.checked && !isAmerigoChecked) {
            el.disabled = false
          } else if (!isAmerigoChecked) {
            el.disabled = false
          } else {
            el.disabled = true
          }
        } else {
          el.disabled = false
        }
      })
    }
  }, [props.disableLoading])

  const filterBySuppliers = (e, type) => {
    if (filterBySupplier.some(elem => elem.id === type.id)) {
      const index = filterBySupplier.map(e => e.id).indexOf(type.id)
     
      filterBySupplier.splice(index, 1)
    } else {
      if (type.is_amerigo) {
        setDisableIsAmerigo(true)
      }
      filterBySupplier.push(type)
    }

    const isAmerigoChecked = filterBySupplier.some(el => el.is_amerigo === true)
    props.grabFilterData({
      manufacture: filterByManufacturer,
      supplier: filterBySupplier,
      isAmerigo,
      isAmerigoChecked,
      attributes: filterAttributesSearch,
    })
    disableCheckboxes()
  }

  const filterByAttributesSearch = e => {
    if (filterByAttributes.some(elem => elem === e)) {
      const index = filterByAttributes.indexOf(e)
      filterByAttributes.splice(index, 1)
    } else {
      filterByAttributes.push(e)
    }
    console.log('as')
    setFilterAttributesSearch([])
    for (let i = 0; i < filterByAttributes.length; i++) {
      const split = filterByAttributes[i].split('#') // just split once
      filterAttributesSearch.push(split[1]) // value
    }
    setTimeout(() => {
      props.grabFilterData({
        manufacture: filterByManufacturer,
        supplier: filterBySupplier,
        isAmerigo,
        attributes: filterAttributesSearch,
      })
    }, 0)
  }

  const disableCheckboxes = () => {
    const inputs = document.querySelectorAll("input[type='checkbox']")
    let hasAmerigo = false
    inputs.forEach(el => {
      if (el.checked) {
        if (el.dataset.amerigo === 'true') {
          hasAmerigo = true
        }
      }
    })
    if (!hasAmerigo) {
      setDisableIsAmerigo(false)
    } else {
      setDisableIsAmerigo(true)
    }
  }

  useEffect(() => {
    enableInputs()
  })

  const enableInputs = () => {
    const inputs = document.querySelectorAll("input[type='checkbox']")
    inputs.forEach(el => {
      if (el.checked && el.dataset.amerigo === 'true') {
        el.disabled = false
      }
    })
  }

  const filterByManufacturers = manufacturer => {
    if (filterByManufacturer.includes(manufacturer.id)) {
      const index = filterByManufacturer.indexOf(manufacturer.id)
      filterByManufacturer.splice(index, 1)
    } else {
      filterByManufacturer.push(manufacturer.id)
    }
    props.grabFilterData({manufacture: filterByManufacturer, supplier: filterBySupplier, isAmerigo, attributes: filterAttributesSearch})
  }

  const filterBySubcategories = subcategory => {
    if (filterBySubcategory.includes(subcategory.id)) {
      const index = filterBySubcategory.indexOf(subcategory.id)
      filterBySubcategory.splice(index, 1)
    } else {
      filterBySubcategory.push(subcategory.id)
    }

    props.grabFilterData({
      manufacture: filterByManufacturer,
      supplier: filterBySupplier,
      subcategory: filterBySubcategory,
      isAmerigo,
      attributes: filterAttributesSearch,
    })
  }

  const isAmerigoFilter = isAmerigo => {
    setIsAmerigo(!isAmerigo)
    props.grabFilterData({manufacture: filterByManufacturer, supplier: filterBySupplier, isAmerigo: !isAmerigo, attributes: filterAttributesSearch})
  }

  const defaultSupplier = (type, supplierSelectedDefault) => {
    if (supplierSelectedDefault && type.id === supplierSelectedDefault) {
      if (!_.isEmpty(filterBySupplier)) {
        // filterData()
      }
      return true
    }
  }

  return (
    <div className="filters">
      <div
        className={`title ${!filtersCollapseStatus ? 'title-white' : ''}`}
        onClick={() => getFiltersCollapseStatus(!filtersCollapseStatus)}
      >
        <div>{t('FILTERS')}</div>
        <div>
          <img
            src="/images/icons/arrow_drop_down.svg"
            className={filtersCollapseStatus ? 'rotate-inactive' : 'rotate-active'}
            alt="dropdown"
          />
        </div>
      </div>

      <div className={`collapse padding-filters ${filtersCollapseStatus ? 'show' : ''}`}>
        {
          props.filters && props.filters.is_amerigo ? (
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="is-amerigo"
                defaultChecked={isAmerigo}
                name="is-amerigo"
                onChange={() => isAmerigoFilter(isAmerigo)}
                className="custom-control-input"
              />
              <label htmlFor="is-amerigo" className="custom-control-label">Is Amerigo</label>
            </div>
          ) : null
        }
        <div className="filter-wpapper">
          <span className="filter-title">{t('TYPE')}</span>
          <div className={!loading && props.filters ? 'filter-suppliers' : ''}>
            {!loading && props.filters
              ? !_.isEmpty(props.filters.data_suppliers) ? props.filters.data_suppliers.map((type, index) => (
                <div key={index} className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    data-amerigo={type.is_amerigo}
                    id={type.id}
                    defaultChecked={defaultSupplier(type, props.filters.supplier_selected_default)}
                    disabled={marketplace === MARKETPLACE_AMERIGO ? type.is_amerigo ? disableIsAmerigo : false : false}
                    name="model"
                    onChange={e => filterBySuppliers(e, type)}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor={type.id}>{type.name}</label>
                </div>
              )) : <div className="no-filters">{t('NO_FILTERS')}</div>
              : <Spinner class="small" />}
          </div>
        </div>

        <div className="filter-wpapper">
          <span className="filter-title">{t('BRAND')}</span>
          {!loading && props.filters
            ? !_.isEmpty(props.filters.car_manufacturers) ? props.filters.car_manufacturers.map((manufacturer, index) => (
              <div key={index} className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id={manufacturer.id}
                  name={manufacturer.name}
                  onClick={() => filterByManufacturers(manufacturer)}
                  className="custom-control-input"
                />
                <label
                  htmlFor={manufacturer.id}
                  className="custom-control-label"
                >
                  {manufacturer.name}
                </label>
              </div>
            )) : <div className="no-filters">{t('NO_FILTERS')}</div>
            : <Spinner class="small" />}
        </div>
        {!loading && props.filters && !_.isEmpty(props.filters?.attributes)
          ? (
            <div className="filter-wpapper" style={{marginTop: '10px'}}>
        
              <span className="filter-title">{t('AIM_CRITERIA')}</span>
              <div className={!loading && !_.isEmpty(props.filters?.attributes) ? 'filter-suppliers' : ''}>
                {!loading && props.filters
                  ? !_.isEmpty(props.filters?.attributes) ? Object.keys(props.filters?.attributes).map((att, keyParentIndex) => {
                    return (
                      <div key={keyParentIndex}>
                        <span className="filter-title-criteria">{att}</span>
                        <div>
                          {props.filters?.attributes?.[att].map((value, index) => {
                            const keys = `${keyParentIndex}#${value}`
                            return (
                              <div key={index} className="custom-control custom-checkbox">
                                
                                <input
                                  type="checkbox"
                                  id={keys}
                                  name={value}
                                  onClick={() => filterByAttributesSearch(keys)}
                                  className="custom-control-input"
                                />
                                <label
                                  htmlFor={keys}
                                  className="custom-control-label"
                                >
                                  {value}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })
                    : <div className="no-filters">{t('NO_FILTERS')}</div>
                  : <Spinner class="small" />}
              </div>
            </div>
          ) : null }

        {
          props.location.pathname.includes('car-parts-by-genart') ? (
            <div className="filter-wpapper">
              <span className="filter-title">{t('SUBCATEGORY')}</span>
              {!loading && props.filters
                ? !_.isEmpty(props.filters?.subcategories) ? props.filters?.subcategories.map((subcategory, index) => (
                  <div key={index} className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id={subcategory.id}
                      name={subcategory.name}
                      onClick={() => filterBySubcategories(subcategory)}
                      className="custom-control-input"
                    />
                    <label
                      htmlFor={subcategory.id}
                      className="custom-control-label"
                    >
                      {subcategory.name}
                    </label>
                  </div>
                )) : <div className="no-filters">{t('NO_FILTERS')}</div>
                : <Spinner class="small" />}
            </div>
          ) : null
        }
      </div>
    </div>
  )
}

export default withRouter(Filters)
