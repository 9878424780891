import {AxiosPromise} from 'axios'
import {IEina} from '../../interfaces/eina.interface'
import request from '../../utils/request'
import {converObjectToParams} from '../../utils/getData'

export function fetchVersionTechnotes(id:number, query:IEina) : AxiosPromise<any> {
  return request({
    url: `/eina/version/${id}/technotes`,
    method: 'get',
    params: query,
  })
}

export function fetchReliableOils(query: any) : AxiosPromise<any> {
  return request({
    url: `/oil/products?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchOilItemView(oil_id: string) : AxiosPromise<any> {
  return request({
    url: `/oil/products/${oil_id}`,
    method: 'get',
  })
}

export function fetchOilCategories(vehicleID: string, source: string) : AxiosPromise<any> {
  return request({
    url: `/oil/vehicle-categories/?vehicle_id=${vehicleID}&source=${source}`,
    method: 'get',
  })
}

export function fetchVehicleListFilters(vehicleID: string, source: string) : AxiosPromise<any> {
  return request({
    url: `/oil/vehicle-list-filters/?vehicle_id=${vehicleID}&source=${source}`,
    method: 'get',
  })
}

export function fetchReliableOilFilter(query: any) : AxiosPromise<any> {
  return request({
    url: `/oil/filters?${converObjectToParams(query)}`,
    method: 'get',
  })
}
