import React, {useState, useRef, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {batch, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import axios from 'axios'

import {resetCarPartsReference, fetchCarPartsReference, fetchCarPartsFilter} from 'store/actions/reliable/carPartsReferenceActions'
import {converObjectToParams, getCurrentMarketplace, getURLParams} from 'utils/getData'
import {fetchReferences} from 'api/reliable/search'
import {useOnClickOutside} from 'utils/hooks'
import Spinner from 'components/Spinner'
import {isHostPublic} from 'utils'

import './style.scss'

const CancelToken = axios.CancelToken
let cancel

const SearchReference = props => {
  const [carReference, setCarReference] = useState('')
  const [source, setSource] = useState('')
  const marketplace = getCurrentMarketplace()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const inputRef = useRef(null)
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const refReference = useRef()
  useOnClickOutside(refReference, () => setShowSuggestions(false))

  useEffect(() => {
    if (props.activeButtonIndex !== 'reference') {
      setCarReference('')
      setSource('')
      setSuggestions([])
    }
  }, [props.activeButtonIndex])

  const getReferences = listQuery => {
    if (cancel !== undefined) {
      cancel()
    }

    setShowSpinner(true)

    fetchReferences(listQuery, {
      cancelToken: new CancelToken((c => {
        cancel = c
      })),
    })
      .then(({data}) => {
        setSuggestions([])
        setSuggestions(data)
        setShowSpinner(false)
      })
  }

  const updateSearchValue = event => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim()
    setCarReference(inputData)

    if (inputData.length >= 6) {
      setShowSuggestions(true)
      setShowSpinner(true)

      getReferences({
        q: inputData,
        ...(source && {source}),
      })
    } else {
      setShowSuggestions(false)
    }
  }

  const clearCarReference = event => {
    event.preventDefault()
    inputRef.current.focus()
    setCarReference('')
    setSource('')
  }

  const submitHandler = event => {
    event.preventDefault()

    if (carReference) {
      setShowSuggestions(true)
      setShowSpinner(true)

      getReferences({
        q: carReference,
        ...(source && {source}),
      })
    }
  }

  const clickReferenceHandler = item => {
    let data = {}
    data = {
      q: item.reference,
      source: item.source,
      subcategory_id: item.subcategory_id,
      man_sup_id: item.man_sup_id,
      // is_amerigo: marketplace === 'amerigo' ? 1 : 0,
      is_amerigo: 0,
    }
    if (item?.type) {
      data.type = item?.type
    }
    if (marketplace === 'amerigo') {
      data.with_default = 1
    }

    batch(() => {
      dispatch(resetCarPartsReference())
      dispatch(fetchCarPartsReference(getURLParams(converObjectToParams(data))))
    })

    setCarReference('')
    setSource('')
    setShowSuggestions(false)
    setSuggestions([])
    const urlParams = getURLParams(window.location.search)

    if (Object.keys(urlParams).length > 0) {
      dispatch(fetchCarPartsFilter(getURLParams(converObjectToParams(data))))
        .then(response => {
          if (response && marketplace === 'amerigo' && response.supplier_selected_default) {
            data.sup = [response.supplier_selected_default]
          }
          props.history.push({
            pathname: '/reliable/car-parts/search',
            search: `?${converObjectToParams(data)}`,
            from: 'SearchReference',
          })
        })

      return
    }

    props.history.push({
      pathname: '/reliable/car-parts/search',
      search: `?${converObjectToParams(data)}`,
      from: 'SearchReference',
    })
  }

  const button = displayClass => (
    <button
      className={`button-style search-toolbar-button ${displayClass} ${carReference ? 'button-style-hover' : 'button-disabled-style'}`}
      type="submit"
    >
      {
        displayClass === 'desktop'
          ? <i className="material-icons">search</i>
          : <span className="search-text">{t('FIND')}</span>
      }
    </button>
  )

  const suggestionsListComponent = suggestionsClass => {
    if (showSuggestions && carReference) {
      return (
        <div className={`result-search ${suggestions.length ? '' : 'no-result'} ${suggestionsClass}`}>
          <div className="wrapper">
            <div className="suggestion-title">{t('RESULTS_REFERENCES')}</div>
          </div>
          <ul className="suggestions">
            {showSpinner
              ? <Spinner type="skeleton-line" />
              : suggestions && suggestions?.length ? suggestions?.map((item, index) => (
                <li key={index} className="suggestion-list-item" onClick={() => clickReferenceHandler(item)}>
                  {
                    item.replacement
                      ? (
                        <div className="suggestion-item-replacement">
                          <span>{carReference}</span>
                          {' '}
                          {t('REFERENCE_REPLACED')}
                        </div>
                      )
                      : ''
                  }
                  <div className="suggestion-item-title">
                    <span>{item.reference}</span>
                    <span>{item.supplier_or_manufacturer}</span>
                    <span>{item.category_name}</span>
                  </div>
                </li>
              )) : <div className="no-suggestions">{`${t('NO_REFERENCES')}!`}</div>}
          </ul>
        </div>
      )
    }

    return null
  }

  return (
    <div
      className={props.activeButtonIndex !== 'reference' ? 'd-none' : 'car-reference-container'}
      ref={refReference}
    >
      <div className="car-reference">
        <form
          className="search-form custom-search-form"
          onSubmit={submitHandler}
        >
          <div
            className="inner-search-container"
          >
            <div className="search-container">
              <input
                ref={inputRef}
                className="search-header form-control"
                type="text"
                placeholder={t('SEARCH_BY_REFERENCE')}
                aria-label="Search"
                value={carReference}
                onChange={updateSearchValue}
              />
              {carReference && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearCarReference} />}
              {suggestionsListComponent('desktop')}
              {isHostPublic() && suggestionsListComponent('mobile')}
            </div>

            {button('desktop')}
          </div>
          {!isHostPublic() && suggestionsListComponent('mobile')}
        </form>
      </div>
    </div>
  )
}

export default withRouter(SearchReference)
