import {ACTION_RESET_CAR_PARTS_REFERENCE, FETCH_CAR_PARTS_FILTERS,
  ACTION_CAR_PARTS_REFERENCES, ACTION_CAR_PARTS_FILTERS, FETCH_CAR_PARTS_REFERENCES} from 'constants/actionsTypes'
import {fetchReliableCarPartsReference, fetchReliableCarPartsReferenceFilter} from 'api/reliable/car-parts'

export function fetchCarPartsReference(data) {
  return dispatch => {
    dispatch({type: FETCH_CAR_PARTS_REFERENCES})

    return fetchReliableCarPartsReference(data)
      .then(response => {
        dispatch({
          type: ACTION_RESET_CAR_PARTS_REFERENCE,
          pagination: null,
        })
        dispatch({
          type: ACTION_CAR_PARTS_REFERENCES,
          carPartsReference: response.data,
        })
        return response.data
      })
  }
}
export function resetCarPartsReference() {
  return dispatch => {
    dispatch({type: ACTION_RESET_CAR_PARTS_REFERENCE})
  }
}

export function fetchCarPartsFilter(data) {
  return dispatch => {
    dispatch({type: FETCH_CAR_PARTS_FILTERS})

    return fetchReliableCarPartsReferenceFilter(data)
      .then(response => {
        dispatch({
          type: ACTION_CAR_PARTS_FILTERS,
          filters: response.data,
        })
        return response.data
      })
  }
}
