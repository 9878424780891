import request from 'utils/request'
import {converObjectToParams} from 'utils/getData'

export function fetchReliablePlateNumber(query) {
  return request({
    url: `/reliable/plate-number?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function updateReliablePlateNumber(data) {
  return request({
    url: '/reliable/plate-number',
    method: 'patch',
    data,
  })
}

export function fetchReliableVin(query) {
  return request({
    url: `/reliable/vin/v2?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchReliableOils(query) {
  return request({
    url: `/oil/products?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchOilItemView(oil_id) {
  return request({
    url: `/oil/products/${oil_id}`,
    method: 'get',
  })
}

export function fetchReliableOilFilter(query) {
  return request({
    url: `/oil/filters?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchReliableConsumables(query) {
  return request({
    url: `/consumable/products?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchConsumableItemView(consumable_id) {
  return request({
    url: `/consumable/products/${consumable_id}`,
    method: 'get',
  })
}

export function fetchReliableConsumableFilter(query) {
  return request({
    url: `/consumable/filters?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchSearchReliableTyres(query, {cancelToken}) {
  return request({
    url: `/tyres/search?${converObjectToParams(query)}`,
    method: 'get',
    cancelToken,

  })
}

export function fetchReliableTyres(query) {
  return request({
    url: `/tyres/products?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchTyreItemView(tyre_id) {
  return request({
    url: `/tyres/products/${tyre_id}`,
    method: 'get',
  })
}

export function fetchReliableTyreFilter(query) {
  return request({
    url: `/tyres/filters?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function patchReliableVin(data) {
  return request({
    url: '/reliable/vin',
    method: 'patch',
    data,
  })
}

export function putReliableVin(data) {
  return request({
    url: '/reliable/vin',
    method: 'put',
    data,
  })
}

export function fetchReferences(query, {cancelToken}) {
  return request({
    url: `/reliable/autocomplete/search?${converObjectToParams(query)}`,
    method: 'get',
    cancelToken,
  })
}

export function fetchSearchReliableOils(query, {cancelToken}) {
  return request({
    url: `/oil/search?${converObjectToParams(query)}`,
    method: 'get',
    cancelToken,
  })
}
export function fetchSearchReliableConsumables(query, {cancelToken}) {
  return request({
    url: `/consumable/search?${converObjectToParams(query)}`,
    method: 'get',
    cancelToken,
  })
}

export function fetchReliableVRM(query) {
  return request({
    url: `/reliable/vrm?${converObjectToParams(query)}`,
    method: 'get',
  })
}

export function fetchSearchReliableBattery(query, {cancelToken}) {
  return request({
    url: `/battery/search?${converObjectToParams(query)}`,
    method: 'get',
    cancelToken,
  })
}
