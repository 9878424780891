import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Select from 'react-select'

import {getURLParams} from 'utils/getData'
import '../style.scss'

const ModelFilter = ({isLoadingModels, models, grabModel, filtered, resetFilter}) => {
  const loadingCategories = useSelector(state => state.categoriesState.loading)
  const {t} = useTranslation()

  const [options, setOptions] = useState([])
  const [selectedModels, setSelectedModels] = useState([])
  const [disableButton, setDisableButton] = useState(true)

  const urlParams = getURLParams(location.search)

  useEffect(() => {
    if (models.length > 0) {
      setOptions(models)
      setSelectedModels(filtered)
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [models, filtered])

  // useEffect(() => {
  //   if (urlParams.q) {
  //     resetHandler()
  //   }
  // }, [urlParams.q])

  const customStylesForSelect = {
    control: base => ({
      ...base,
      boxShadow: 'none',
      '&:focus': {
        borderColor: '#80bdff',
      },
    }),
  }

  const filterBy = () => {
    grabModel(selectedModels)
  }

  const resetHandler = () => {
    setSelectedModels([])
    setOptions([])
    resetFilter()
  }

  const changeSelectHandler = item => {
    setSelectedModels(Array.isArray(item) ? item.map(m => m.model_id) : [])
  }

  return (
    <div className="catalog-filters__model">
      <Select
        name="model"
        inputId="search-by-model"
        isMulti
        isClearable={false}
        styles={customStylesForSelect}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={t('SEARCH_BY_MODEL')}
        onChange={changeSelectHandler}
        isDisabled={options.length > 0 ? false : true}
        options={options}
        value={options.filter(item => selectedModels.includes(item.model_id))}
        isLoading={isLoadingModels}
        getOptionLabel={option => option.model_name}
        getOptionValue={option => option.model_id}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <div className="button-container">
        <button
          type="button"
          className={isLoadingModels || disableButton || loadingCategories ? 'accept-button button-disabled-style' : 'accept-button'}
          onClick={() => filterBy()}
          disabled={!!isLoadingModels || disableButton || loadingCategories}
        >
          {t('ACCEPT')}
        </button>
        {
          urlParams?.manufacturer_id
            ? (
              <button
                type="button"
                className={isLoadingModels || disableButton || loadingCategories ? 'cancel-button button-disabled-style' : 'cancel-button'}
                onClick={(() => resetHandler())}
                disabled={!!isLoadingModels || disableButton || loadingCategories}
              >
                {t('RESET')}
              </button>
            )
            : null
        }
      </div>
    </div>
  )
}

export default ModelFilter
