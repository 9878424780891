let localPath = []

const compare = (id, category) => {
  if (!category) return false

  const {code_groupe, code_ssgroupe, code_repere} = category
  const value = code_repere ?? code_ssgroupe ?? code_groupe

  return value === id
}

const select = (categories = []) => {
  if (!localPath.length) return categories

  const copy = [...categories]
  const id = localPath[0]
  let index = null

  const category = categories.find((category, categoryIndex) => {
    const comparison = compare(id, category)
    if (comparison) index = categoryIndex
    return comparison
  })

  if (!category) return copy
  localPath.shift()

  let subcategories = category.subcategories
  if (subcategories) {
    subcategories = select(subcategories)
    category.hasInnerSelected = true
    category.subcategories = subcategories
  } else {
    category.isSelected = true
  }
  copy[index] = category
  return copy
}

export const clearSelections = list => {
  Object.values(list).forEach(item => {
    if (item.isSelected) {
      item.isSelected = false
    }
    if (item.subcategories) {
      clearSelections(item.subcategories)
    }
  })
  return list
}

export const selectCategory = (path = [], list = []) => {
  if (!list || !list.length) return []
  localPath = path
  clearSelections(list)
  return select(list)
}
