import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import Select from 'react-select'

import {getURLParams} from 'utils/getData'

import '../style.scss'

const BrandFilter = ({grabBrand}) => {
  const {brands, loading} = useSelector(state => state.catalogBrandsState)
  const location = useLocation()
  const {t} = useTranslation()

  const [options, setOptions] = useState([])
  const [selectedBrand, setSelectedBrand] = useState('')

  const urlParams = getURLParams(location.search)

  useEffect(() => {
    if (brands) {
      setOptions(brands)

      if (urlParams?.manufacturer_id) {
        setSelectedBrand(brands.find(item => urlParams.manufacturer_id === item.car_manufacturer_id))
      }
    }
  }, [brands])

  useEffect(() => {
    if (loading) {
      setSelectedBrand('')
    }
  }, [loading])

  const customStylesForSelect = {
    control: base => ({
      ...base,
      boxShadow: 'none',
      '&:focus': {
        borderColor: '#80bdff',
      },
    }),
  }

  const changeSelectHandler = selectedOption => {
    setSelectedBrand(selectedOption)
    grabBrand(selectedOption.car_manufacturer_id)
  }

  const filterOption = (option, searchText) => {
    if (option.data.car_manufacturer_name.toLowerCase().includes(searchText.toLowerCase())) {
      return true
    }

    return false
  }

  return (
    <div className="catalog-filters__brand">
      <Select
        name="brand"
        inputId="search-by-brand"
        placeholder={t('SEARCH_BY_BRAND')}
        onChange={changeSelectHandler}
        options={options}
        styles={customStylesForSelect}
        value={selectedBrand}
        isLoading={loading}
        getOptionLabel={option => option.car_manufacturer_name}
        getOptionValue={option => option.car_manufacturer_id}
        filterOption={filterOption}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  )
}

export default BrandFilter
