import {NavLink, useLocation} from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {converObjectToParams, getURLParams} from 'utils/getData'
import {fetchCatalogData} from 'store/actions/marketplace/catalogActions'
import axios from 'axios'

const SubCategories = ({subcategory, closePanel, fromSideDrawerMenu, resetCatalog, handleSelect, searchInputRef}) => {
  const source = axios.CancelToken.source()
  const cancelToken = source.token
  const location = useLocation()
  const urlParams = getURLParams(location.search)
  const urlObject = new URL(window.location.href)
  const dispatch = useDispatch()

  const {loadingCatalog, loadingPageCatalog} = useSelector(state => state.catalogState)

  const toggleCategoriesCollapse = activeSubcategory => {
    resetCatalog(true)
    const options = urlObject.searchParams.getAll('model_id[]')
    if (options.length > 0) {
      activeSubcategory.model_id = options
    }
    if (urlParams.manufacturer_id) {
      activeSubcategory.manufacturer_id = urlParams.manufacturer_id
    }
    dispatch(fetchCatalogData(activeSubcategory, cancelToken)).then(() => {
      resetCatalog(false)
    })
    if (fromSideDrawerMenu) {
      closePanel()
    }
  }

  const handleCategoryClick = (category, path = []) => {
    handleSelect(path)
    toggleCategoriesCollapse({
      page: 1,
      q: urlParams.q ? urlParams.q : null,
      catalog_id: urlParams.catalog_id,
      code_groupe: category.code_groupe,
      code_repere: category.code_repere,
      code_ssgroupe: category.code_ssgroupe,
    })
  }

  const generateLink = data => {
    const modelIds = urlObject.searchParams.getAll('model_id[]')

    if (modelIds.length > 0) {
      data.model_id = modelIds
    }

    if (urlParams.manufacturer_id) {
      data.manufacturer_id = urlParams.manufacturer_id
    }
    return `/car-parts?${converObjectToParams(data)}`
  }

  return (
    subcategory && subcategory.subcategories
      .map((subCat, subCatIndex) => {
        const path = [
          subCat.code_groupe,
          subCat.code_ssgroupe,
          subCat.code_repere,
        ]
        return (
          <li key={subCatIndex} className="subcategory-nav-link">
            <NavLink
              className={`${loadingCatalog || loadingPageCatalog ? 'disabled-link' : ''} ${subCat.isSelected ? 'is-selected' : ''}`}
              to={generateLink({
                page: 1,
                q: urlParams.q ? urlParams.q : null,
                catalog_id: urlParams.catalog_id,
                code_groupe: subCat.code_groupe,
                code_repere: subCat.code_repere,
                code_ssgroupe: subCat.code_ssgroupe,
              })}
              onClick={() => handleCategoryClick(subCat, path)}
            >
              <Highlighter
                highlightClassName="category-title is-searched"
                searchWords={[searchInputRef.current?.value?.trim()]}
                autoEscape={true}
                textToHighlight={subCat.name}
              />
            </NavLink>
          </li>
        )
      })
  )
}

export default SubCategories
