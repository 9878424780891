import request from 'utils/request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data,
  })
}

export function forgotPassword(data) {
  return request({
    url: '/forgot-password',
    method: 'post',
    data,
  })
}

export function restorePassword(token, {first, second}) {
  return request({
    url: `/restore-password/${token}`,
    method: 'post',
    data: {
      password: {
        first,
        second,
      },
    },
  })
}

export function getPublicToken() {
  return request({
    url: '/public-token',
    method: 'get',
  })
}
