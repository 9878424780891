import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import $ from 'jquery'
import {isEmpty} from 'underscore'
import {carPartName} from 'utils/carPart'
import getGenerationName from 'utils/getGenerationName'

const Selector = ({
  data,
  loading,
  selectId,
  title,
  translate,
  selectHandler,
  value,
  disabledState,
  selectValueObject,
}) => {
  const {resetCarSelectors, resetTruckSelectors} = useSelector(state => state.selectorState)
  const {t, i18n} = useTranslation()

  useEffect(() => {
    if (selectId) {
      $(`#${selectId}`).selectpicker({title})
    }
  }, [title])

  useEffect(() => {
    if (selectId) {
      $(`#${selectId}`)
        .selectpicker({title})
        .selectpicker('refresh')
    }
  }, [i18n.language])

  useEffect(() => {
    // refresh if select has received res with data for options
    if (data !== null && Array.isArray(data)) {
      $('.selectpicker').selectpicker('refresh')
    }

    // refresh if changed from truck selector to vehicle and vice versa
    if (data === null && (resetCarSelectors || resetTruckSelectors)) {
      $('.selectpicker').selectpicker('refresh')
    }
  }, [data])

  useEffect(() => {
    if (value) {
      $('.selectpicker').selectpicker('refresh')
    }
  }, [value])

  const popularItems = options => {
    let currentItem = 0

    if (options.length >= 9) {
      for (let i = 0; i <= 9; i++) {
        if (options[i].quantity === 0) {
          break
        }

        currentItem++
      }
    }

    return currentItem
  }

  const renderOptionWithObject = options => {
    return options.map((item, index) => {
      let classSeparator = ''

      if (selectId === 'select-brand-1') {
        classSeparator = popularItems(options) > 0 && index === popularItems(options) ? 'item-separator' : ''
      }

      return (
        <option
          className={`dropdown-item ${classSeparator}`}
          key={index}
          value={JSON.stringify(item)}
        >
          {
            selectId.startsWith('select-generation') ? getGenerationName(item) : carPartName(item) || item.description
          }
        </option>
      )
    })
  }

  const renderOption = options => options.map((item, index) => (
    <option className="dropdown-item" key={index} value={item.id || item.code || item}>
      {translate ? t(item.description) : carPartName(item) || item.description || item}
      {item.hp ? ` | ${item.hp} hp` : null}
    </option>
  ))

  return (
    <select
      className="selectpicker"
      id={selectId}
      data-width="auto"
      data-size="10"
      data-actions-box="true"
      data-dropdown-align-right={selectId === 'select-version' ? true : 'auto'}
      onChange={selectHandler}
      value={value}
      title={t(title)}
      disabled={disabledState}
    >
      {
        loading
          ? <option className="dropdown-item center" disabled>{t('LOADING')}</option>
          : isEmpty(data)
            ? (
              <option className="dropdown-item center" disabled>{t('NO_DATA')}</option>
            )
            : selectValueObject
              ? renderOptionWithObject(data)
              : renderOption(data)
      }
    </select>
  )
}

export default Selector
