import React, {useRef} from 'react'
import Highlighter from 'react-highlight-words'

const CategoryCollapseBtn = ({category, subcategory, categoryIndex, searchInputRef}) => {
  const itemsEls = useRef([])
  const mainCategoryInvolved = subcategory || category
  const dataTarget = `collapse-${category.code_groupe}${subcategory ? `-${subcategory.code_ssgroupe}` : ''}-${categoryIndex}`

  return (
    <a
      type="button"
      id={`main-category-${category.code_groupe}`}
      ref={mainCategoryInvolved => itemsEls.current.push(mainCategoryInvolved)}
      className="main-category"
      data-toggle="collapse"
      data-target={`#${dataTarget}`}
      aria-expanded={!!mainCategoryInvolved?.hasInnerSearches || !!mainCategoryInvolved?.hasInnerSelected}
      aria-controls={dataTarget}
    >
      <span className="material-icons icon-arrow-down">keyboard_arrow_down</span>
      <span className="material-icons icon-arrow-right">keyboard_arrow_right</span>
      <Highlighter
        highlightClassName="category-title is-searched"
        searchWords={[searchInputRef.current?.value?.trim()]}
        autoEscape={true}
        textToHighlight={mainCategoryInvolved.name}
      />
    </a>
  )
}

export default CategoryCollapseBtn
