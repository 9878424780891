import {IConsumableActionTypes, IConsumableFilter, IConsumableResult, IConsumableSearchFetchData} from '../../types/consumableTypes'
import {
  ACTION_FETCH_CONSUMABLE_FILTERS,
  ACTION_FETCH_CONSUMABLE_ITEM,
  ACTION_FETCH_CONSUMABLE_SEARCH,
  ACTION_LOADING_CONSUMABLE_FILTERS, ACTION_LOADING_CONSUMABLE_ITEM,
  ACTION_LOADING_CONSUMABLE_SEARCH,
  ACTION_RESET_CONSUMABLE_FILTERS,
  ACTION_RESET_CONSUMABLE_SEARCH,
} from '../../../constants/actionsTypes'

export function fetchConsumableSearch(consumableData: IConsumableSearchFetchData): IConsumableActionTypes {
  return {
    type: ACTION_FETCH_CONSUMABLE_SEARCH,
    payload: consumableData,
  }
}

export function loadingConsumableSearch(loading: boolean): IConsumableActionTypes {
  return {
    type: ACTION_LOADING_CONSUMABLE_SEARCH,
    payload: loading,
  }
}

export function resetConsumableSearch(): IConsumableActionTypes {
  return {
    type: ACTION_RESET_CONSUMABLE_SEARCH,
    payload: null,
  }
}

export function fetchConsumableFilters(filters: IConsumableFilter): IConsumableActionTypes {
  return {
    type: ACTION_FETCH_CONSUMABLE_FILTERS,
    payload: filters,
  }
}

export function loadingConsumableFilters(loading: boolean): IConsumableActionTypes {
  return {
    type: ACTION_LOADING_CONSUMABLE_FILTERS,
    payload: loading,
  }
}

export function fetchConsumableItem(consumable: IConsumableResult): IConsumableActionTypes {
  return {
    type: ACTION_FETCH_CONSUMABLE_ITEM,
    payload: consumable,
  }
}

export function loadingConsumableItem(loading: boolean): IConsumableActionTypes {
  return {
    type: ACTION_LOADING_CONSUMABLE_ITEM,
    payload: loading,
  }
}

export function resetConsumableSearchFilter(): IConsumableActionTypes {
  return {
    type: ACTION_RESET_CONSUMABLE_FILTERS,
    payload: null,
  }
}
