import request from 'utils/request'

export function fetchCatalogs() {
  return request({
    url: '/catalogs',
    method: 'get',
  })
}

export function fetchCatalog(query, {cancelToken}) {
  return request({
    url: '/car-parts',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchCatalogBrands(query, {cancelToken}) {
  return request({
    url: '/manufacturers',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchCategories(query, {cancelToken}) {
  return request({
    url: '/categories',
    method: 'get',
    params: query,
    cancelToken,
  })
}

export function fetchCatalogInfo(catalog_id) {
  return request({
    url: `/catalog/info?catalog_id=${catalog_id}`,
    method: 'get',
  })
}

export function fetchCatalogModels({manufacturer_id, catalog_id}) {
  return request({
    url: `/${manufacturer_id}/models?catalog_id=${catalog_id}`,
    method: 'get',
  })
}

export function fetcVehehicleCategories(params) {
  return request({
    url: '/tyres/vehicle-categories',
    method: 'get',
    params,
  })
}
export function fetchItemTyre(params) {
  return request({
    url: '/tyres/products',
    method: 'get',
    params,

  })
}
export function fetchItemTyreFilters(params) {
  return request({
    url: '/tyres/filters',
    method: 'get',
    params,
  
  })
}
