import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import _ from 'underscore'
import axios from 'axios'

import {resetVehicleCategories, setVehicleId} from 'store/actions/reliable/vehicleCategoriesActions'
import {converObjectToParams} from 'utils/getData'
import {resetCarSelectors} from 'store/actions/selectorActions'
import {fetchManuallySearch, toggleManuallySearch, updateManuallySearch} from 'store/actions/reliable/manuallySearchActions'
import {TRUCK_IDENTIFIER, SOURCE_TECDOC} from 'constants/defaultConstants'
import Spinner from 'components/Spinner'
import {fetchReliableTruckManufacturers, fetchReliableTruckModels, fetchReliableTruckFuelType, fetchReliableTruckVariant} from 'api/reliable/truck-selector'
import {putReliableVin} from 'api/reliable/search'
import ManufacturerDropdown from '../shared/ManufacturerDropdown'
import ModelDropdown from '../shared/ModelDropdown'
import FuelDropdown from '../shared/FuelDropdown'
import CarVersionDropdown from '../shared/CarVersionDropdown'
import '../shared/selector-styles.scss'

const CancelToken = axios.CancelToken
let cancelModelReq
let cancelFuelReq
let cancelVersionReq

const TruckSelector = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {activeButtonIndex, isManuallySearch, manuallySearch} = props
  const vin = manuallySearch?.vin

  const {resetTruckSelectors} = useSelector(state => state.selectorState)
  const {typeSearch, loading: loadingManuallySearch} = useSelector(state => state.manuallySearchState)

  const [truckState, setTruckState] = useState({
    loadingManufactures: true,
    manufacturers: null,
    currentManufacturer: '',
    loadingModels: false,
    models: null,
    currentModel: '',
    loadingFuels: false,
    fuels: null,
    currentFuel: '',
    loadingTruckVersions: false,
    truckVersions: null,
    currentTruckVersion: '',
  })

  const getAllIds = (items, typeId) => {
    return _.pluck(items, typeId)
  }

  useEffect(() => {
    if (manuallySearch?.models) {
      fetchManufacturers()
    }
  }, [manuallySearch])

  useEffect(() => {
    if (activeButtonIndex === 'truck' && !truckState.manufacturers) {
      fetchManufacturers()
    }
  }, [activeButtonIndex])

  const fetchManufacturers = () => {
    setTruckState(prevState => ({
      ...prevState,
      loadingManufactures: true,
    }))

    let params

    if (isManuallySearch && typeSearch !== 'trucks') {
      params = {
        manufacturers_id: getAllIds(manuallySearch[typeSearch], 'manufacturer_id'),
      }
    }

    fetchReliableTruckManufacturers(params)
      .then(({data}) => {
        setTruckState(prevState => ({
          ...prevState,
          loadingManufactures: false,
          manufacturers: data,
        }))
        if (isManuallySearch && typeSearch === 'models') {
          handleGrabManufacturer(JSON.stringify(data[0]))
        }
      })
  }

  const fetchModels = manufacturer_id => {
    if (cancelModelReq !== undefined) {
      cancelModelReq()
    }

    setTruckState(prevState => ({
      ...prevState,
      loadingModels: true,
    }))
    let params

    if (isManuallySearch && typeSearch === 'models') {
      params = {
        models_id: getAllIds(manuallySearch[typeSearch], 'id'),
      }
    }

    fetchReliableTruckModels(manufacturer_id, params, {cancelToken: new CancelToken((c => {
      cancelModelReq = c
    }))})
      .then(({data}) => {
        setTruckState(prevState => ({
          ...prevState,
          loadingModels: false,
          models: data,
        }))

        if (isManuallySearch && typeSearch === 'models' && data.length === 1) {
          handleGrabModel(JSON.stringify(data[0]))
          dispatch(fetchManuallySearch(false))
        }
      })
  }

  const fetchFuels = selectedModel => {
    if (cancelFuelReq !== undefined) {
      cancelFuelReq()
    }

    setTruckState(prevState => ({
      ...prevState,
      loadingFuels: true,
    }))

    fetchReliableTruckFuelType(selectedModel.id, {cancelToken: new CancelToken((c => {
      cancelFuelReq = c
    }))})
      .then(({data}) => {
        setTruckState(prevState => ({
          ...prevState,
          loadingFuels: false,
          fuels: data,
        }))
      })
  }

  const fetchTruckVersions = selectedModel => {
    if (cancelVersionReq !== undefined) {
      cancelVersionReq()
    }

    setTruckState(prevState => ({
      ...prevState,
      loadingTruckVersions: true,
    }))
    const params = {}

    fetchReliableTruckVariant(selectedModel.id, params, {cancelToken: new CancelToken((c => {
      cancelVersionReq = c
    }))})
      .then(({data}) => {
        setTruckState(prevState => ({
          ...prevState,
          loadingTruckVersions: false,
          truckVersions: data,
        }))
      })
  }

  const handleSubmitTruckSelector = () => {
    dispatch(resetVehicleCategories())
    dispatch(setVehicleId(null))
    dispatch(resetCarSelectors())

    const data = {
      source: SOURCE_TECDOC,
      vehicle_identifier: TRUCK_IDENTIFIER,
      ...(vin && {vin}),
    }

    history.push({
      pathname: `/reliable/rmi/${truckState.currentTruckVersion}/categories`,
      search: `?${converObjectToParams(data)}`,
    })

    if (isManuallySearch) {
      saveCarInDataBase()
    }
  }

  const saveCarInDataBase = () => {
    putReliableVin({
      vin,
      ktypnr: truckState.currentTruckVersion,
    })
      .then(() => {
        setTruckState(prevState => ({
          ...prevState,
          manufacturers: null,
          currentManufacturer: '',
          models: null,
          currentModel: '',
          fuels: null,
          currentFuel: '',
          truckVersions: null,
          currentTruckVersion: '',
        }))

        dispatch(toggleManuallySearch(false))
        dispatch(updateManuallySearch(null, ''))
      })
  }

  const handleGrabManufacturer = data => {
    setTruckState(prevState => ({
      ...prevState,
      currentManufacturer: data,
      models: null,
      currentModel: '',
      fuels: null,
      currentFuel: '',
      truckVersions: null,
      currentTruckVersion: '',
    }))

    const selectedManufacturer = JSON.parse(data)
    fetchModels(selectedManufacturer.id)
  }

  const handleGrabModel = data => {
    setTruckState(prevState => ({
      ...prevState,
      currentModel: data,
      currentFuel: '',
      currentTruckVersion: '',
    }))

    fetchFuels(JSON.parse(data))
    fetchTruckVersions(JSON.parse(data))
  }

  const handleGrabFuel = data => {
    setTruckState(prevState => ({
      ...prevState,
      currentFuel: data,
      currentTruckVersion: '',
    }))
  }

  const handleGrabTruckVersion = data => {
    setTruckState(prevState => ({
      ...prevState,
      currentTruckVersion: data,
    }))
  }

  if (truckState.currentManufacturer && truckState.currentModel && truckState.currentTruckVersion && resetTruckSelectors && activeButtonIndex !== 'truck') {
    setTruckState(prevState => ({
      ...prevState,
      currentManufacturer: '',
      models: null,
      currentModel: '',
      fuels: null,
      currentFuel: '',
      truckVersions: null,
      currentTruckVersion: '',
    }))
  }

  if (isManuallySearch && loadingManuallySearch) {
    return <Spinner class="small" />
  }

  return (
    <div className={activeButtonIndex === 'truck' || isManuallySearch ? 'vehicle-container' : 'd-none'}>
      <div className="vehicle-selector custom-vehicle-selector">
        <div className="dropdowns">
          <ManufacturerDropdown
            id="2"
            disable={Boolean(truckState.manufacturers && truckState.manufacturers.length === 1)}
            currentManufacture={truckState.currentManufacturer}
            manufactures={truckState.manufacturers}
            loading={truckState.loadingManufactures}
            handleGrabManufacturer={handleGrabManufacturer}
          />

          <ModelDropdown
            id="2"
            currentManufacture={truckState.currentManufacturer}
            currentModel={truckState.currentModel}
            models={truckState.models}
            loading={truckState.loadingModels}
            handleGrabModel={handleGrabModel}
          />

          <FuelDropdown
            id="2"
            currentGeneration={truckState.currentModel}
            currentFuel={truckState.currentFuel}
            fuels={truckState.fuels}
            loading={truckState.loadingFuels}
            handleGrabFuel={handleGrabFuel}
          />

          <CarVersionDropdown
            id="2"
            currentGeneration={truckState.currentModel}
            currentVersion={truckState.currentTruckVersion}
            carVersions={truckState.truckVersions}
            loading={truckState.loadingTruckVersions}
            handleGrabCarVersion={handleGrabTruckVersion}
          />
          <div />
          <div className="find-button-container">
            <button
              className={`
                button-style find-button 
                custom-find-button-style
                ${truckState.currentTruckVersion ? 'button-style-hover' : 'button-disabled-style'}
              `}
              type="submit"
              onClick={handleSubmitTruckSelector}
              disabled={!truckState.currentTruckVersion}
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TruckSelector
