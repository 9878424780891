import {ACTION_FETCH_MANUALLY_SEARCH, ACTION_TOGGLE_MANUALLY_SEARCH, ACTION_UPDATE_MANUALLY_SEARCH} from 'constants/actionsTypes'

export function fetchManuallySearch(status) {
  return {
    type: ACTION_FETCH_MANUALLY_SEARCH,
    loading: status,
  }
}

export function toggleManuallySearch(status) {
  return {
    type: ACTION_TOGGLE_MANUALLY_SEARCH,
    showManuallySearch: status,
  }
}

export function updateManuallySearch(data, typeSearch) {
  return {
    type: ACTION_UPDATE_MANUALLY_SEARCH,
    manuallySearch: data,
    typeSearch,
  }
}
