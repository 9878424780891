import React, {CSSProperties} from 'react'
import './style.scss'

interface SpinnerProps {
  type?: string,
  style?: CSSProperties,
  class?: string,
}

const Spinner: React.FC<SpinnerProps> = props => {
  if (props.type && props.type === 'skeleton-line') {
    return (
      <div className="wrapper-skeleton">
        <div className="skeleton-line" />
      </div>
    )
  }

  return (
    <div
      style={props.style}
      className={`spinner ${props.class ?? ''}`}
    >
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  )
}

export default Spinner
