import {
  ACTION_UPDATE_CAR_PART,
  ACTION_FETCH_CAR_PART_EQUIVALENCES,
  ACTION_FETCH_CAR_PART_TAB_CARS,
  ACTION_FETCH_CAR_PART_TAB_TRUCKS,
  ACTION_FETCH_CAR_PART_FAIL, ACTION_FETCH_CAR_PART,
} from 'constants/actionsTypes'

export const initialState = {
  loading: false,
  carPart: null,
  equivalences: null,
  listCars: null,
  listTrucks: null,
}

export default function carPartsReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_FETCH_CAR_PART_FAIL:
      return {
        ...state,
        loading: false,
      }

    case ACTION_FETCH_CAR_PART:
      return {
        ...state,
        loading: true,
      }

    case ACTION_UPDATE_CAR_PART:
      return {
        ...state,
        carPart: action.carPart,
        loading: false,
      }

    case ACTION_FETCH_CAR_PART_EQUIVALENCES:
      return {
        ...state,
        equivalences: action.equivalences,
      }

    case ACTION_FETCH_CAR_PART_TAB_CARS:
      return {
        ...state,
        listCars: action.listCars,
      }

    case ACTION_FETCH_CAR_PART_TAB_TRUCKS:
      return {
        ...state,
        listTrucks: action.listTrucks,
      }

    default:
      return state
  }
}
