import {
  ADMIN_ROLES,
  ALL_ROLES,
  CLIENT_ROLES,
  ROLE_ADV,
  ROLE_CLIENT_AMERIGO,
  ROLE_CLIENT_AUTOMOTOR,
  ROLE_CLIENT_AUTOMOTOR_AMERIGO,
  ROLE_CLIENT_PLATFORM,
  ROLE_CLIENT_PLATFORM_ADMIN,
  ROLE_PROVIDER,
  ROLE_SELLER,
  ROLE_SUPPLIER_AMERIGO,
  SUPPLIER_AMERIGO,
} from './roles'
import {MARKETPLACE_AMERIGO, MARKETPLACE_AUTOMOTOR} from './defaultConstants'
import {IMenuNavLink} from '../interfaces/menu-nav-link.interface'
import {isHostPunchOut} from '../utils'

const toRoute = (route: string, token?: string): string => {
  if (token) {
    return `${route}/?token=${token}`
  }

  return route
}
export const MENU_NAV_LINK: Array<IMenuNavLink> = [
  {
    liClassName: 'user',
    to: '',
    roles: ALL_ROLES,
    title: '',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'person',
  },
  {
    to: '/',
    roles: ALL_ROLES,
    title: 'HOME',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'home',
  },
  {
    to: '/favorite',
    roles: ALL_ROLES,
    title: 'SAVED_LISTS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'favorite',
  },
  {
    to: '/cart',
    roles: ALL_ROLES,
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    title: 'SHOPPING_CART',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'shopping_cart',
  },
  {
    liClassName: 'upload-csv',
    to: '',
    roles: [ROLE_CLIENT_AMERIGO, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SUPPLIER_AMERIGO],
    marketplace_sensible: !isHostPunchOut() ? [MARKETPLACE_AMERIGO] : [],
    title: 'PROFORMA',
    callback_method: 'openUploadAmerigoProformaModal',
    iconClassName: 'icon icon-upload-csv',
    iconName: '',
  },
]
export const TOOLBAR_MENU_NAV: Array<IMenuNavLink> = [
  {
    to: toRoute,
    rel: 'noopener noreferrer',
    target: '_blank',
    roles: [...ADMIN_ROLES, ROLE_CLIENT_PLATFORM_ADMIN, ROLE_SUPPLIER_AMERIGO],
    title: 'ADMIN_PANEL',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'manage_accounts',
  },
  {
    to: '/history',
    roles: ALL_ROLES,
    title: 'HISTORY',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'history',
  },
  {
    to: '/orders',
    roles: ALL_ROLES,
    title: 'ORDER_LIST',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons-outlined',
    iconName: 'ballot',
  },
  {
    to: '/orders-list',
    roles: SUPPLIER_AMERIGO,
    title: 'ORDER_LIST',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons-outlined',
    iconName: 'ballot',
  },
  {
    to: '/orders-clients',
    roles: [ROLE_CLIENT_PLATFORM_ADMIN],
    title: 'ORDER_LIST_CLIENTS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons-outlined',
    iconName: 'ballot',
  },
  {
    to: '/select-client',
    roles: [ROLE_SELLER, ROLE_ADV, ROLE_SUPPLIER_AMERIGO],
    title: 'SELECT_CLIENT',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'work',
  },
  {
    to: '/messaging',
    roles: [ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_ADV, ROLE_CLIENT_PLATFORM],
    title: 'MESSAGING',
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'supervisor_account',
  },
  {
    to: '/tariffs',
    roles: [ROLE_CLIENT_AUTOMOTOR, ROLE_SELLER, ROLE_ADV],
    title: 'TARIFFS',
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'supervisor_account',
  },
  {
    liClassName: 'upload-csv',
    to: '',
    roles: [ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SELLER],
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    title: 'PROFORMA',
    callback_method: 'openUploadProformaModal',
    iconClassName: 'icon icon-upload-csv',
    iconName: '',
  },
  {
    to: '/settings',
    roles: ALL_ROLES,
    title: 'SETTINGS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'settings',
  },
  {
    to: '/financial',
    roles: [ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SELLER],
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    title: 'FINANCIAL_HISTORY',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'euro_symbol',
  },
  {
    to: '/remaining',
    roles: [ROLE_CLIENT_AUTOMOTOR, ROLE_CLIENT_AUTOMOTOR_AMERIGO, ROLE_SELLER],
    marketplace_sensible: [MARKETPLACE_AUTOMOTOR],
    title: 'REMAINDER',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'euro_symbol',
  },
  // {
  //   to: '/proforma-mapping',
  //   roles: [ROLE_SELLER],
  //   title: 'PROFORMA_MAPPING',
  //   callback_method: 'closeMenuSideDrawerClickHandler',
  //   iconClassName: 'material-icons',
  //   iconName: '',
  // },
  {
    to: '/proforma-mapping-basic',
    roles: [ROLE_SELLER],
    title: 'PROFORMA_MAPPING',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: '',
  },
  {
    to: '/',
    rel: 'noopener noreferrer',
    roles: [...CLIENT_ROLES, ...ADMIN_ROLES, ROLE_SELLER, ROLE_PROVIDER, ROLE_ADV, ROLE_SUPPLIER_AMERIGO],
    title: 'LOG_OFF',
    callback_method: 'handlerLogout',
    iconClassName: 'material-icons',
    iconName: 'exit_to_app',
  },
  {
    to: '/',
    rel: 'noopener noreferrer',
    roles: [ROLE_CLIENT_PLATFORM],
    title: 'LOG_OFF',
    callback_method: 'handleLogoutForRoleClientPlatform',
    iconClassName: 'material-icons',
    iconName: 'exit_to_app',
  },
]

export const TOOLBAR_MENU_EINA: Array<IMenuNavLink> = [
  {
    to: '/eina',
    rel: 'noopener noreferrer',
    target: '_blank',
    roles: [...ADMIN_ROLES, ROLE_CLIENT_PLATFORM_ADMIN],
    title: 'EINA_VTS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'manage_accounts',
  },
]

export const TOOLBAR_TARIFFS_SUPPLIER: Array<IMenuNavLink> = [
  {
    to: '/tariffs-supplier',
    roles: [ROLE_SUPPLIER_AMERIGO, ROLE_CLIENT_AMERIGO],
    title: 'TARIFFS',
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'supervisor_account',
  },
]

export const TOOLBAR_MESSAGE_SUPPLIER: Array<IMenuNavLink> = [
  {
    to: '/messaging-supplier',
    roles: [ROLE_SUPPLIER_AMERIGO, ROLE_CLIENT_AMERIGO],
    title: 'MESSAGING',
    marketplace_sensible: [MARKETPLACE_AMERIGO],
    callback_method: 'closeMenuSideDrawerClickHandler',
    iconClassName: 'material-icons',
    iconName: 'supervisor_account',
  },
]
