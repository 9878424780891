import React, {useEffect} from 'react'
import {getCurrentDomain} from 'utils/getData'

const {$} = window

const Ebook = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const categoryName = urlParams.get('name')
  const pdfName = urlParams.get('pdf_name')

  useEffect(() => {
    let marketplace = process.env.REACT_APP_MARKETPLACE

    if (window.location.href.indexOf('automotor') !== -1) {
      marketplace = 'Automotor'
    } else if (window.location.href.indexOf('amerigo') !== -1) {
      marketplace = 'Amerigo'
    }

    document.title = `${categoryName} - ${marketplace}`
  })

  useEffect(() => {
    $(document).ready(() => {
      $('#container').flipBook({
        pdfUrl: `${getCurrentDomain()}${pdfName}`,

        // pdfUrl: 'http://automotor-api.amerigointernational.pro/e_book/pdf/vannes-egr.pdf',
      })
    })
  }, [])

  return (
    <div id="container" />
  )
}

export default Ebook
