import {AxiosResponse} from 'axios'
import {Dispatch} from 'redux'
import {IOil, IOilFilter, IOilSearch, IPagination} from '../store/types/oilTypes'
import {fetchOilFilters,
  fetchOilSearch,
  loadingOilFilters,
  loadingOilSearch} from '../store/actions/reliable/oilSearchActions'
import {fetchReliableOilFilter, fetchReliableOils} from '../api/reliable/oil'
import {converObjectToParams, getURLParamsArray} from './getData'

export const handleOilFullSearch = (data: IOilSearch, oil: IOil | null, dispatch:Dispatch<any>) => {
  handleOilDataSearch(data, oil, dispatch)
  handleOilFilterSearch(data, oil, dispatch)
}

export const handleOilDataSearch = (data: IOilSearch, oil: IOil | null, dispatch:Dispatch<any>) => {
  dispatch(loadingOilSearch(true))
  fetchReliableOils(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<IPagination[]>) => {
      dispatch(fetchOilSearch({oil, data}))
      dispatch(loadingOilSearch(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingOilSearch(false))
    })
}

export const handleOilFilterSearch = (data: IOilSearch, oil: IOil | null, dispatch:Dispatch<any>) => {
  dispatch(loadingOilFilters(true))
  fetchReliableOilFilter(getURLParamsArray(converObjectToParams(data)))
    .then(({data}: AxiosResponse<IOilFilter>) => {
      dispatch(fetchOilFilters(data))
      dispatch(loadingOilFilters(false))
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(loadingOilFilters(false))
    })
}
