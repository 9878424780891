import {ACTION_UPDATE_BREADCRUMBS, ACTION_REMOVE_BREADCRUMBS, GET_BREADCRUMBS, GET_BREADCRUMBS_FAILED} from 'constants/actionsTypes'

export const initialState = {
  breadcrumbs: [],
  isFetching: false,
  requestFailed: false,
}

export default function breadcrumbsReducer(state = initialState, action = '') {
  switch (action.type) {
    case GET_BREADCRUMBS:
      return {
        ...state,
        isFetching: true,
      }

    case ACTION_UPDATE_BREADCRUMBS:
      return {
        ...state,
        isFetching: false,
        breadcrumbs: action.breadcrumbs,
      }

    case ACTION_REMOVE_BREADCRUMBS:
      return {
        ...state,
        isFetching: false,
        breadcrumbs: Object.keys(state.breadcrumbs).reduce((object, key) => {
          if (key !== action.payload) {
            object[key] = state.breadcrumbs[key]
          }
          return object
        }, {}),
      }

    case GET_BREADCRUMBS_FAILED:
      return {
        ...state,
        requestFailed: true,
      }

    default:
      return state
  }
}
