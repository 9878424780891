import React, {useEffect, useRef, useState} from 'react'
import {searchDimensions, resultCount} from 'api/tyres'
import Spinner from 'components/Spinner'
import axios, {Canceler} from 'axios'
import {useTranslation} from 'react-i18next'
import {useOnClickOutside} from 'utils/hooks'
import {useHistory} from 'react-router-dom'
import {converObjectToParams, getURLParamsArray} from 'utils/getData'
import {handleTyresDataSearch} from 'utils/tyres'
import {useDispatch} from 'react-redux'
import TyresQuickFilters from './TyresQuickFilters'
import TyresHelpModal from './TyresHelpModal'
import './styles.scss'

interface ISearch {
    activeButtonIndex: string
}
interface IDimensions {
    width: string,
    height: string,
    diameter: string,
    load_index: string,
    load_index_id: string,
    speed_rating: string,
    speed_rating_id: string,
    'season_ids[]'?: string[],
    'brand_ids[]'?: string[],
    'vehicle_type_ids[]'?: string[],
}
interface ISearchDimensions {
    'widths[]'?: string[],
    'heights[]'?: string[],
    'diameters[]'?: string[],
    'load_indexes[]'?: string[],
    'speed_ratings[]'?: string[],
    'season_ids[]': string[],
    'brand_ids[]': string[],
    'vehicle_type_ids[]': string[],
}

const CancelToken = axios.CancelToken
let cancel: Canceler | undefined

const TyresSearch: React.FC<ISearch> = ({activeButtonIndex}): JSX.Element => {
  const [dimensions, setDimensions] = useState([])
  
  const [isCounting, setIsCounting] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const [dimension, setDimension] = useState<IDimensions | null>(null)
  const [dimensionInput, setDimensionInput] = useState('')
  const [count, setCount] = useState(0)

  const [brandIds, setBrandIds] = useState<string[]>([])
  const [seasonIds, setSeasonIds] = useState<string[]>([])
  const [vehicleTypeIds, setVehicleTypeIds] = useState<string[]>([])

  const inputRef = useRef<HTMLInputElement>(null)
  const refReference = useRef() as React.MutableRefObject<HTMLInputElement>
  const urlParams: any = getURLParamsArray(location.search)

  const {t} = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  let isMounted = true
  useOnClickOutside(refReference, () => setShowSuggestions(false))

  const submitHandler = (event: any) => {
    event.preventDefault()

    const data: ISearchDimensions = {
      ...(dimension && {
        'widths[]': [dimension.width],
        'heights[]': [dimension.height],
        'diameters[]': [dimension.diameter],
        'load_indexes[]': [dimension.load_index_id],
        'speed_ratings[]': [dimension.speed_rating_id],
      }),
      'season_ids[]': seasonIds,
      'vehicle_type_ids[]': vehicleTypeIds,
      'brand_ids[]': brandIds,
    }
    history.push({
      pathname: '/reliable/tyres/search',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      search: `?tab=${urlParams.tab}&${converObjectToParams(data)}`,
    })
   
    handleTyresDataSearch(data, null, dispatch)
  }

  const countResults = (dimension: any) => {
    const data: IDimensions = {
      ...(dimension && {
        width: dimension?.width,
        height: dimension?.height,
        diameter: dimension?.diameter,
        load_index_id: dimension?.load_index_id,
        speed_rating_id: dimension?.speed_rating_id,
      }),
      'season_ids[]': seasonIds,
      'vehicle_type_ids[]': vehicleTypeIds,
      'brand_ids[]': brandIds,
      
    }
    setIsCounting(true)
    resultCount(data).then(res => {
      isMounted && setCount(res?.data?.count)
    }).finally(() => {
      isMounted && setIsCounting(false)
    })
  }

  const clickDimensionHandler = (dimension:IDimensions) => {
    countResults(dimension)
    setDimension(dimension)

    const {width, height, diameter, load_index, speed_rating} = dimension
    const dimensionName = `${width} ${height} ${diameter} ${load_index}${speed_rating}`
    setDimensionInput(dimensionName)
    setShowSuggestions(false)
  }
  
  const getDimensions = async (listQuery: string) => {
    setShowSpinner(true)
    if (cancel !== undefined) {
      cancel()
    }
    await searchDimensions(listQuery, {
      cancelToken: new CancelToken((c => {
        cancel = c
      })),
    }).then(res => {
      setDimensions(res.data)
    })
  }

  const updateSearchValue = (event: any) => {
    setDimensionInput(event.target.value)
    if (event.target.value.length >= 2) {
      setShowSuggestions(true)
      setShowSpinner(true)
      getDimensions(event.target.value).then(() => {
        setShowSpinner(false)
      })
    }
  }
  const clearCarReference = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setDimension(null)
    countResults(null)
    setDimensionInput('')
    setCount(0)
    setShowSuggestions(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }
  const searchOnEnter = (event: any) => {
    if (event.key === 'Enter') {
      setShowSuggestions(true)
      setShowSpinner(true)
      getDimensions(event.target.value).then(() => {
        setShowSpinner(false)
      })
    }
  }
  useEffect(() => {
    countResults(dimension)

    return () => {
      isMounted = false
    }
  }, [brandIds, seasonIds, vehicleTypeIds])

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search)
    setBrandIds(URLParams.getAll('brand_ids[]'))
    setSeasonIds(URLParams.getAll('season_ids[]'))
    setVehicleTypeIds(URLParams.getAll('vehicle_type_ids[]'))
  }, [])
  
  return (
    <div
      className={activeButtonIndex !== 'tyres' ? 'd-none' : 'tyres-reference-container'}
    >
      <div className="tyres-search">
        <div
          className="search-form custom-search-form"
        >

          <div
            className="inner-search-container"
          >
            <div
              className="search-container"
            >
              <input
                ref={inputRef}
                className="search-header form-control"
                type="text"
                placeholder={t('ENTER_TYRE_SIZE')}
                aria-label="Search"
                value={dimensionInput}
                onKeyDown={searchOnEnter}
                onChange={updateSearchValue}
              />
              {dimensionInput && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearCarReference} />}
            </div>
            <div className="how-to-read">
              <button
                className="button-style search-toolbar-button desktop button-style-hover how-to-read-button"
                type="button"
                onClick={openModal}
              >
                <i className="material-icons">help</i>
              </button>
            </div>
            <button
              className="button-style search-toolbar-button desktop button-style-hover search"
              type="submit"
              onClick={submitHandler}
            >
              {t('SHOW')}
              {' '}
              {isCounting ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span> 
                  {count}
                  {' '}
                </span>
              )}
              {' '}
              {t('RESULTS')}
            </button>
          </div>
        </div>
      </div>
      <div
        ref={refReference}
        className={`tyres-result-search desktop ${dimensions.length ? '' : 'no-result'} ${showSuggestions ? 'block' : 'hidden'}`}
      >
        <div className="wrapper">
          <div className="tyres-suggestion-title">{t('RESULTS')}</div>
       
          {showSpinner ? <Spinner type="skeleton-line" /> : (
            <ul className="tyres-suggestions">
              { dimensions.length ? dimensions.map((item:IDimensions, index) => (
                <li
                  key={index}
                  className="tyres-suggestion-list-item"
                >

                  <div className="tyres-suggestion-item-title">
                    <div
                      className="reference-tyre-wrapper"
                    >
                      <span
                        className="reference-tyre"
                        onClick={() => clickDimensionHandler(item)}
                      >
                        {`${item.width} ${item.height} ${item.diameter} ${item.load_index}${item.speed_rating}`}
                      </span>
                    </div>

                  </div>
                </li>
              
              )) : <div className="no-suggestions">{`${t('NO_TYRES_RESULTS')}!`}</div>}
            
            </ul>
          )}
        </div>
      </div>

      <div className="filters">
        <TyresQuickFilters
          activeButtonIndex={activeButtonIndex}
          brandIds={brandIds}
          setBrandIds={setBrandIds}
          seasonIds={seasonIds}
          setSeasonIds={setSeasonIds}
          vehicleTypeIds={vehicleTypeIds}
          setVehicleTypeIds={setVehicleTypeIds}
          disabled={isCounting}
        />
      </div>
      <div className="tyres-help-modal">
        <TyresHelpModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </div>
    </div>
  )
}

export default TyresSearch
