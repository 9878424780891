export default function getGenerationName(data) {
  const {
    startMonth,
    startYear,
    endMonth,
    endYear,
    description,
  } = data
  let name = ''

  if (startMonth && startYear) {
    name = `${description} (${startMonth}/${startYear})`
  }

  if (startMonth && startYear && endMonth && endYear) {
    name = `${description} (${startMonth}/${startYear} - ${endMonth}/${endYear})`
  }

  return name
}
