import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {fileUploaderToggle} from 'store/actions/fileUploaderActions'
import {showModal} from 'store/actions/modalsActions'
import {logout} from 'store/actions/authActions'
import {getCurrentMarketplace} from 'utils/getData'
import './style.scss'
import {TOOLBAR_MENU_NAV_AF} from 'constants/menuNavLinksAF'

const ToolBarNavAF = () => {
  const {quantityToolbar} = useSelector(state => state.cartState)
  const {user} = useSelector(state => state.userState)
  const [hovered, setHovered] = useState(false)
  const marketplace = getCurrentMarketplace()
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handlerLogout = () => {
    dispatch(logout())
  }

  const handleLogoutForRoleClientPlatform = () => {
    dispatch(logout())
    const callbackUrl = localStorage.getItem('callback_url')
    if (callbackUrl) {
      window.location.href = callbackUrl
    } else {
      window.location.href = 'https://www.tyrescope.co.uk'
    }
  }

  const openUploadProformaModal = () => {
    dispatch(showModal({
      open: true,
    }, 'UploadProformaModal'))
  }

  const methods = {
    handlerLogout,
    openUploadProformaModal,
    handleLogoutForRoleClientPlatform,
  }

  return (
    <div className="toolbar-nav">
      <ul>
        <li className="favorite-page-link">
          <NavLink
            to="/favorite"
            className="links"
            title={t('SAVED_LISTS')}
          >
            <i className="material-icons">favorite</i>
          </NavLink>
        </li>
        <li className="upload-csv">
          <NavLink
            to="/cart"
            title={t('UPLOAD_FILE')}
            className="links"
            onClick={() => dispatch(fileUploaderToggle())}
          >
            <i className="icon icon-upload-csv" />
          </NavLink>
        </li>

        <li className="cart">
          <NavLink
            to="/cart"
            title={t('SHOPPING_CART')}
            className="links"
          >
            <i className="material-icons">
              shopping_cart
            </i>
            {
              quantityToolbar
                ? <span className="count">{quantityToolbar > 99 ? '99+' : quantityToolbar}</span>
                : null
            }
          </NavLink>
        </li>

        <li className="user">
          <div
            className={hovered ? 'nav-tooltip active' : 'nav-tooltip'}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div className="username-container">
              <i className="material-icons">person</i>
              {user ? (user.full_name.trim() || user.email?.split('@')?.[0]) : ''}
            </div>
            <div className="tooltiptext">
              {
                TOOLBAR_MENU_NAV_AF.map(({
                  to,
                  rel,
                  target,
                  iconName,
                  title,
                  callback_method,
                }, index) => (
                  marketplace ? (
                    <NavLink
                      key={iconName + index}
                      to={{pathname: typeof to === 'string' ? to : to(`${process.env.REACT_APP_ADMIN_PANEL}`, localStorage.getItem('token') || '')}}
                      className={location.pathname === to ? 'active-path' : ''}
                      {...(rel && {rel})}
                      {...(target && {target})}
                      title={t(title)}
                      onClick={() => (methods[callback_method] ? methods[callback_method]() : null)}
                    >
                      {
                        title === 'LOG_OFF' ? (
                          <div className="logout">
                            <span className="tooltip-item">{t(title)}</span>
                          </div>
                        ) : (
                          <span className="tooltip-item">{t(title)}</span>
                        )
                      }
                    </NavLink>
                  ) : null
                ))
              }
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ToolBarNavAF
