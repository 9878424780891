import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import _ from 'underscore'

import {CAR_IDENTIFIER} from 'constants/defaultConstants'
import {resetVehicleCategories, setVehicleId} from 'store/actions/reliable/vehicleCategoriesActions'
import {converObjectToParams} from 'utils/getData'
import {fetchManuallySearch, toggleManuallySearch, updateManuallySearch} from 'store/actions/reliable/manuallySearchActions'
// eslint-disable-next-line no-unused-vars
import {resetTruckSelectors} from 'store/actions/selectorActions'
import Spinner from 'components/Spinner'
import {fetchReliableCarManufacturers, fetchReliableCarModels, fetchReliableCarGeneration, fetchReliableCarFuelType, fetchReliableCarVersion} from 'api/reliable/car-selector'
import {putReliableVin} from 'api/reliable/search'
import ManufacturerDropdown from '../shared/ManufacturerDropdown'
import ModelDropdown from '../shared/ModelDropdown'
import GenerationDropdown from './GenerationDropdown'
import FuelDropdown from '../shared/FuelDropdown'
import CarVersionDropdown from '../shared/CarVersionDropdown'

import '../shared/selector-styles.scss'

const CancelToken = axios.CancelToken
let cancelModelReq
let cancelGenerationReq
let cancelFuelReq
let cancelVersionReq

const VehicleSelector = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loadingManuallySearch = useSelector(state => state.manuallySearchState.loading)
  const typeManuallySearch = useSelector(state => state.manuallySearchState.typeSearch)
  const resetCarSelectors = useSelector(state => state.selectorState.resetCarSelectors)
  const {vehicleId} = useSelector(state => state.vehicleCategoriesState)

  const {activeButtonIndex, isManuallySearch, manuallySearch} = props

  const [vin] = useState(manuallySearch?.vin)

  // Manufactures
  const [loadingManufactures, setLoadingManufactures] = useState(true)
  const [manufactures, setManufactures] = useState(null)
  const [currentManufacture, setCurrentManufacture] = useState('')

  // Models
  const [loadingModels, setLoadingModels] = useState(true)
  const [models, setModels] = useState(null)
  const [currentModel, setCurrentModel] = useState('')

  // Generations
  const [loadingGenerations, setLoadingGenerations] = useState(true)
  const [generations, setGenerations] = useState(null)
  const [currentGeneration, setCurrentGeneration] = useState('')

  // Fuels
  const [loadingFuels, setLoadingFuels] = useState(true)
  const [fuels, setFuels] = useState(null)
  const [currentFuel, setCurrentFuel] = useState('')

  // Car versions
  const [loadingCarVersions, setLoadingCarVersions] = useState(true)
  const [carVersions, setCarVersions] = useState(null)
  const [currentCarVersion, setCurrentCarVersion] = useState('')

  useEffect(() => {
    // dispatch(setToInitialState())
    if (activeButtonIndex === 'vehicule' && !manufactures) {
      fetchManufacturers()
    }
  }, [activeButtonIndex])

  useEffect(() => {
    // if (!isManuallySearch || typeManuallySearch === 'cars') {
    //   // todo issue: is called twice when page is refreshed
    //   fetchManufacturers()
    // }
  }, [])

  useEffect(() => {
    if (manuallySearch?.manufacturers) {
      fetchManufacturers()
    }

    if (manuallySearch && typeManuallySearch === 'vin-no-suggestions') {
      fetchManufacturers()
    }

    if (manuallySearch?.models) {
      fetchManufacturers()
      fetchModels()
    }
  }, [manuallySearch])

  const getAllIds = (items, typeId) => {
    return _.pluck(items, typeId)
  }

  const fetchManufacturers = () => {
    setLoadingManufactures(true)

    let params

    if (isManuallySearch && typeManuallySearch !== 'cars') {
      let id = 'id'

      if (typeManuallySearch === 'models') {
        id = 'manufacturer_id'
      }

      params = {
        manufacturers_id: getAllIds(manuallySearch[typeManuallySearch], id),
      }
    }

    fetchReliableCarManufacturers(params)
      .then(({data}) => {
        setLoadingManufactures(false)
        setManufactures(data)

        if (isManuallySearch && typeManuallySearch === 'models') {
          setCurrentManufacture(JSON.stringify(data[0]))
        } else if (isManuallySearch && typeManuallySearch === 'manufacturers' && data.length === 1) {
          setCurrentManufacture(JSON.stringify(data[0]))
          fetchModels({}, data[0])
        } else {
          setCurrentManufacture('')
          setModels(null)
          setCurrentModel('')
          setGenerations(null)
          setCurrentGeneration('')
          setFuels(null)
          setCurrentFuel('')
          setCarVersions(null)
          setCurrentCarVersion('')
        }

        dispatch(fetchManuallySearch(false))
        dispatch(updateManuallySearch(null, typeManuallySearch))
      })
  }

  const fetchModels = (queryParams = {}, currentMan = {}) => {
    if (cancelModelReq !== undefined) {
      cancelModelReq()
    }

    setLoadingModels(true)
    let params

    if (isManuallySearch && typeManuallySearch === 'models') {
      params = {
        tecdoc_id: manuallySearch[typeManuallySearch][0].manufacturer_id,
        models_id: getAllIds(manuallySearch[typeManuallySearch], 'id'),
        without_country: 1,
      }
    }

    if (isManuallySearch && typeManuallySearch === 'manufacturers' && !_.isEmpty(currentMan)) {
      params = {
        tecdoc_id: currentMan.tecdoc_id,
        polk_id: currentMan.polk_id,
        without_country: 1,
      }
    }

    if (!_.isEmpty(queryParams)) {
      params = {
        tecdoc_id: queryParams.tecdoc_id,
        polk_id: queryParams.polk_id,
        ...(isManuallySearch && {without_country: 1}),
      }
    }

    fetchReliableCarModels(params, {cancelToken: new CancelToken((c => {
      cancelModelReq = c
    }))})
      .then(({data}) => {
        setLoadingModels(false)
        setModels(data)

        if (isManuallySearch && typeManuallySearch === 'models') {
          handleGrabModel(JSON.stringify(data[0]))
        }
      })
  }

  const fetchGenerations = selectedModel => {
    if (cancelGenerationReq !== undefined) {
      cancelGenerationReq()
    }

    setLoadingGenerations(true)
    const {
      id,
      source,
      country_code,
    } = selectedModel

    fetchReliableCarGeneration({
      model_id: id,
      source,
      country_code,
      ...(isManuallySearch && {without_country: 1}),
    }, {cancelToken: new CancelToken((c => {
      cancelGenerationReq = c
    }))})
      .then(({data}) => {
        setLoadingGenerations(false)
        setGenerations(data)
      })
  }

  const fetchFuels = selectedGeneration => {
    if (cancelFuelReq !== undefined) {
      cancelFuelReq()
    }

    setLoadingFuels(true)
    const {
      id,
      source,
    } = selectedGeneration

    fetchReliableCarFuelType({
      car_generation_id: id,
      source,
    }, {cancelToken: new CancelToken((c => {
      cancelFuelReq = c
    }))})
      .then(({data}) => {
        setLoadingFuels(false)
        setFuels(data)
      })
  }

  const fetchCarVersions = (paramGeneration, paramFuel = '', paramModel = {}) => {
    if (cancelVersionReq !== undefined) {
      cancelVersionReq()
    }

    setLoadingCarVersions(true)
    const {
      id,
      source,
    } = paramGeneration

    fetchReliableCarVersion({
      car_generation_id: id,
      source,
      ...(paramModel && {country_code: paramModel.country_code}),
      ...(paramFuel && {fuel_type: paramFuel}),
    }, {cancelToken: new CancelToken((c => {
      cancelVersionReq = c
    }))})
      .then(({data}) => {
        setLoadingCarVersions(false)
        setCarVersions(data)
      })
  }

  const saveCarInDataBase = () => {
    putReliableVin({
      vin,
      ktypnr: currentCarVersion,
    })
      .then(() => {
        setManufactures(null)
        setCurrentManufacture('')
        setModels(null)
        setCurrentModel('')
        setGenerations(null)
        setCurrentGeneration('')
        setFuels(null)
        setCurrentFuel('')
        setCarVersions(null)
        setCurrentCarVersion('')
        dispatch(toggleManuallySearch(false))
        dispatch(updateManuallySearch(null, ''))
      })
  }

  const handleSubmitVehicleSelector = () => {
    const {
      source,
      country_code,
    } = JSON.parse(currentModel)

    const data = {
      ...(source && {source}),
      ...(country_code && {country_code}),
      ...(vin && {vin}),
      vehicle_identifier: CAR_IDENTIFIER,
    }

    if (isManuallySearch) {
      saveCarInDataBase()
    }

    if (Number(currentCarVersion) !== vehicleId) {
      dispatch(resetVehicleCategories())
      dispatch(setVehicleId(null))
    }

    history.push({
      pathname: `/reliable/rmi/${currentCarVersion}/categories`,
      search: `?${converObjectToParams(data)}`,
    })

    dispatch(resetTruckSelectors())
  }

  const handleGrabManufacturer = selectedBrand => {
    setCurrentManufacture(selectedBrand)
    setModels(null)
    setCurrentModel('')

    setGenerations(null)
    setCurrentGeneration('')
    setFuels(null)
    setCurrentFuel('')
    setCarVersions(null)
    setCurrentCarVersion('')

    fetchModels(JSON.parse(selectedBrand))
  }

  const handleGrabModel = selectedModel => {
    setCurrentModel(selectedModel)
    setGenerations(null)
    setCurrentGeneration('')
    setFuels(null)
    setCurrentFuel('')
    setCarVersions(null)
    setCurrentCarVersion('')

    fetchGenerations(JSON.parse(selectedModel))
  }

  const handleGrabGeneration = selectedGeneration => {
    setCurrentGeneration(selectedGeneration)
    setFuels(null)
    setCurrentFuel('')
    setCarVersions(null)
    setCarVersions('')

    fetchFuels(JSON.parse(selectedGeneration))
    fetchCarVersions(JSON.parse(selectedGeneration), '', JSON.parse(currentModel))
  }

  const handleGrabFuel = selectedFuelOption => {
    setCurrentFuel(selectedFuelOption)
    setCarVersions(null)
    setCurrentCarVersion('')

    fetchCarVersions(JSON.parse(currentGeneration), selectedFuelOption, JSON.parse(currentModel))
  }

  const handleGrabCarVersion = selectedVersion => {
    setCurrentCarVersion(selectedVersion)
  }

  if (isManuallySearch && loadingManuallySearch) {
    return <Spinner class="small" />
  }

  const disableManufacturerDropdown = () => {
    return !!((isManuallySearch && typeManuallySearch === 'models')
      || (isManuallySearch && typeManuallySearch === 'manufacturers' && manufactures && manufactures.length === 1))
  }

  if (currentManufacture && currentModel && currentGeneration && currentCarVersion && resetCarSelectors && activeButtonIndex !== 'vehicule') {
    setCurrentManufacture('')
    setModels(null)
    setCurrentModel('')
    setGenerations(null)
    setCurrentGeneration('')
    setFuels(null)
    setCurrentFuel('')
    setCarVersions(null)
    setCurrentCarVersion('')
  }

  return (
    <div
      className={activeButtonIndex === 'vehicule' || isManuallySearch ? 'vehicle-container' : 'd-none'}
    >
      <div className="vehicle-selector custom-vehicle-selector">
        <div className="dropdowns">
          <ManufacturerDropdown
            id="1"
            disable={disableManufacturerDropdown()}
            currentManufacture={currentManufacture}
            manufactures={manufactures}
            loading={loadingManufactures}
            handleGrabManufacturer={handleGrabManufacturer}
          />

          <ModelDropdown
            id="1"
            currentManufacture={currentManufacture}
            currentModel={currentModel}
            models={models}
            loading={loadingModels}
            handleGrabModel={handleGrabModel}
          />

          <GenerationDropdown
            id="1"
            currentModel={currentModel}
            currentGeneration={currentGeneration}
            generations={generations}
            loading={loadingGenerations}
            handleGrabGeneration={handleGrabGeneration}
          />

          <FuelDropdown
            id="1"
            currentGeneration={currentGeneration}
            currentFuel={currentFuel}
            fuels={fuels}
            loading={loadingFuels}
            handleGrabFuel={handleGrabFuel}
          />

          <CarVersionDropdown
            id="1"
            currentGeneration={currentGeneration}
            currentVersion={currentCarVersion}
            carVersions={carVersions}
            loading={loadingCarVersions}
            handleGrabCarVersion={handleGrabCarVersion}
          />

          <div className="find-button-container">
            <button
              className={`
                button-style find-button 
                custom-find-button-style
                ${currentCarVersion ? 'button-style-hover' : 'button-disabled-style'}
              `}
              type="submit"
              onClick={handleSubmitVehicleSelector}
              disabled={!currentCarVersion}
            >
              <i className="material-icons">search</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleSelector
