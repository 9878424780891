import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import Spinner from './components/Spinner'
import App from './App'
import PublicRoutes from './routes/PublicRoutes'
import {RootState} from './store/reducers/rootReducer'
import {getURLParams, converObjectToParams} from './utils/getData'
import {IUrlParams} from './interfaces/url-params.interface'
import {usePunchOut} from './utils/usePunchOut'

const Public = (): JSX.Element => {
  const location = useLocation()
  const {loading: loadingUser, user} = useSelector((state: RootState) => state.userState)
  const urlParams: IUrlParams = getURLParams(location.search)
  const history = useHistory()

  useEffect(() => {
    if (urlParams?.token) {
      localStorage.setItem('token', urlParams.token)
      delete urlParams.token
      history.push({
        search: `?${converObjectToParams(urlParams)}`,
      })
      // history.replace('/')
    }
  }, [urlParams?.token])

  usePunchOut()

  if (loadingUser && !user) {
    return <Spinner />
  }

  return (
    localStorage.getItem('token') ? <App /> : <PublicRoutes />
  )
}

export default Public
