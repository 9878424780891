import {HIDE_MODAL, SHOW_MODAL} from 'constants/actionsTypes'

export const showModal = (modalProps, modalType) => dispatch => {
  dispatch({
    type: SHOW_MODAL,
    modalProps,
    modalType,
  })
}

export const hideModal = () => dispatch => {
  dispatch({
    type: HIDE_MODAL,
  })
}
