import {ACTION_RESET_CAR_SELECTORS, ACTION_RESET_TRUCK_SELECTORS, ACTION_SET_INIT_STATE_SELECTORS} from 'constants/actionsTypes'

export function resetCarSelectors() {
  return {
    type: ACTION_RESET_CAR_SELECTORS,
  }
}

export function resetTruckSelectors() {
  return {
    type: ACTION_RESET_TRUCK_SELECTORS,
  }
}

export function setToInitialState() {
  return {
    type: ACTION_SET_INIT_STATE_SELECTORS,
  }
}
