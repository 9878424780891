import {ACTION_FETCH_FAVORITE_CATEGORIES, ACTION_SET_FAVORITE_CATEGORIES, ACTION_ADD_FAVORITE_CATEGORY,
  ACTION_EDIT_FAVORITE_CATEGORY, ACTION_RESET_FAVORITE_CATEGORIES} from 'constants/actionsTypes'

export const initialState = {
  favoriteCategories: null,
  isFetchingFavCat: false,
}

export default function favoriteCategoriesReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_SET_FAVORITE_CATEGORIES:
      return {
        ...state,
        isFetchingFavCat: false,
        favoriteCategories: action.payload,
      }

    case ACTION_FETCH_FAVORITE_CATEGORIES:
      return {
        ...state,
        isFetchingFavCat: true,
      }

    case ACTION_ADD_FAVORITE_CATEGORY:
      return {
        favoriteCategories: [
          ...state.favoriteCategories.slice(0, 1),
          action.newCategory,
          ...state.favoriteCategories.slice(1),
        ],
      }

    case ACTION_EDIT_FAVORITE_CATEGORY:
      return {
        ...state,
        favoriteCategories: [
          ...state.favoriteCategories.slice(0, action.index),
          action.editedCategory,
          ...state.favoriteCategories.slice(action.index + 1),
        ],
      }

    case ACTION_RESET_FAVORITE_CATEGORIES:
      return {
        ...state,
        favoriteCategories: action.payload,
      }

    default:
      return state
  }
}
