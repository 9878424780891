import React, {useState, useEffect, useRef} from 'react'
import {withRouter} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import _ from 'underscore'

import Preloader from 'components/Preloader'
import {fetchManuallySearch, updateManuallySearch, toggleManuallySearch} from 'store/actions/reliable/manuallySearchActions'
import {setVehicleId} from 'store/actions/reliable/vehicleCategoriesActions'
import {getCarDescription} from 'utils/getCarDescription'
import {getTruckDescription} from 'utils/getTruckDescription'
import {converObjectToParams} from 'utils/getData'
import {useMobileDetect, useOnClickOutside} from 'utils/hooks'
import {fetchReliableVin, patchReliableVin} from 'api/reliable/search'
import {isHostPublic} from 'utils'
import './style.scss'

const SearchVin = props => {
  const [vin, setVin] = useState('')
  const [errorStatus, setErrorStatus] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const [isCar, setIsCar] = useState(false)
  const [isTruck, setIsTruck] = useState(false)
  const [viewAllModels, setViewAllModels] = useState(false)
  const inputRef = useRef(null)
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const refVin = useRef()
  const isMobile = useMobileDetect(768)

  const [errorMessageStatus, setMessageStatus] = useState('INVALID_SEARCH_BY_VIN')

  useOnClickOutside(refVin, () => setShowSuggestions(false))

  useEffect(() => {
    if (props.activeButtonIndex !== 'vin') {
      setVin('')
      setShowSuggestions(false)
      setSuggestions({})
      setErrorStatus(false)
    }
  }, [props.activeButtonIndex])

  const submitHandler = event => {
    event.preventDefault()

    if (/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{11,17}$/.test(vin)) {
      getVin({vin})
    } else {
      console.log((/^(?=[\S\s]{11,17}$)[\S\s]*/.test(vin)))
      if (!(/^(?=[\S\s]{11,17}$)[\S\s]*/.test(vin))) {
        setMessageStatus('INVALID_SEARCH_BY_VIN_LENGTH')
      } else if (/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(vin)) {
        setMessageStatus('INVALID_SEARCH_BY_VIN_CHARACTERS')
      } else {
        setMessageStatus('INVALID_SEARCH_BY_VIN')
      }

      setErrorStatus(true)
    }
  }

  const getVin = listQuery => {
    setShowSuggestions(true)
    setShowSpinner(true)

    fetchReliableVin(listQuery)
      .then(({data}) => {
        const vehicleObj = {
          models: data.models,
          manufacturers: data.manufacturers,
          is_information_correct: data.is_information_correct,
          search_from_tecalliance: data.search_from_tecalliance,
        }

        const {cars} = data

        if (!cars) {
          setSuggestions(vehicleObj)
        }

        if (cars && cars.cars && cars.cars.length) {
          setIsCar(true)
          vehicleObj.cars = cars.cars
          setSuggestions(vehicleObj)
        }

        if (cars && cars.trucks && cars.trucks.length) {
          setIsTruck(true)
          vehicleObj.trucks = cars.trucks
          setSuggestions(vehicleObj)
        }

        setShowSpinner(false)
      })
  }

  const viewAllModelsTecalliance = () => {
    getVin({vin, request_tecalliance: true})
  }
  const updateSearchValue = event => {
    setVin(event.target.value.trim())
    setShowSuggestions(false)
    setErrorStatus(false)
  }

  const clearVin = event => {
    event.preventDefault()
    inputRef.current.focus()
    setVin('')
    setSuggestions({})
    setShowSuggestions(false)
    setErrorStatus(false)
  }

  const patchSelectedVin = id => {
    patchReliableVin({
      vin,
      ktypnr: id,
    })
  }

  const clickVinHandler = ({id, source, vehicle_identifier}) => {
    const data = {
      vin,
      source,
      vehicle_identifier,
    }
    patchSelectedVin(id)

    setShowSuggestions(false)
    setSuggestions({})

    dispatch(setVehicleId(Number(id)))
    props.history.push({
      pathname: `/reliable/rmi/${id}/categories`,
      search: `?${converObjectToParams(data)}`,
    })
  }

  const handleClickSearchManually = type => {
    setShowSuggestions(false)

    props.history.push({
      pathname: '/reliable',
    })

    dispatch(fetchManuallySearch(true))
    dispatch(toggleManuallySearch(true))
    dispatch(updateManuallySearch({
      ...suggestions,
      vin,
    }, type))
  }

  const showManuallySearch = type => {
    let message = 'VIN_MESSAGE'

    if (suggestions && (!_.isEmpty(suggestions.cars) || !_.isEmpty(suggestions.models) || !_.isEmpty(suggestions.trucks)) && !suggestions.is_information_correct) {
      message = 'VIN_MESSAGE_2'
    }

    return (
      <div className="vin-message-manually">
        <span className="vin-message-manually-part-first">{t(message)}</span>
        <span
          className="vin-message-manually-part-second"
          onClick={() => handleClickSearchManually(type)}
        >
          {t('MANUALLY')}
        </span>
      </div>
    )
  }

  const showAllCars = () => {
    const message = 'VIN_MESSAGE_2'

    return (
      <div className="vin-message-manually">
        <span className="vin-message-manually-part-first">{t(message)}</span>
        <span
          className="vin-message-manually-part-second"
          onClick={() => viewAllModelsTecalliance()}
        >
          {t('MANUALLY')}
        </span>
      </div>
    )
  }

  const vinListComponent = vinClass => {
    if (showSuggestions && vin) {
      const {
        cars,
        trucks,
        manufacturers,
        models,
        is_information_correct,
        search_from_tecalliance,
      } = suggestions

      let data
      let type

      if (cars) {
        data = cars
        type = 'cars'
      }

      if (trucks) {
        data = trucks
        type = 'trucks'
      }

      if (!_.isEmpty(manufacturers)) {
        data = manufacturers
        type = 'manufacturers'
      }

      if (!_.isEmpty(models)) {
        data = models
        type = 'models'
      }

      if (!_.isEmpty(cars) && !_.isEmpty(manufacturers)) {
        data = cars
      }

      if (!_.isEmpty(cars) && !_.isEmpty(models)) {
        data = cars
      }

      if (!_.isEmpty(trucks) && !_.isEmpty(manufacturers)) {
        data = trucks
      }

      if (!_.isEmpty(trucks) && !_.isEmpty(models)) {
        data = trucks
      }

      if (_.isEmpty(data)) {
        type = 'vin-no-suggestions'
      }
      const hasSelectedData = data?.some(item => item.is_selected)
      const multipleVariants = data?.length > 1
      return (
        <div className={`result-search ${data ? '' : 'no-result'} ${vinClass}`}>
          <div className="wrapper">
            <div className="suggestion-title">{t('RESULTS_VIN')}</div>
          </div>
          <ul className={`suggestions ${data && data.length >= 10 ? 'scroll' : ''}`}>
            {showSpinner
              ? <Preloader type="skeleton-line" />
              : data && data.length ? data.map((item, index) => {
                if (!viewAllModels && (hasSelectedData && !item.is_selected)) return null
               
                return (
                  <div key={index}>
                    {item.is_selected ? (
                      <li key={index} onClick={() => clickVinHandler(item)} style={{color: item.is_selected ? 'purple' : '#212529'}}>
                        {isCar ? getCarDescription(item) : null}
                        {isTruck ? getTruckDescription(item) : null}
                      </li>
                    ) : (
                      <li
                        key={index}
                        onClick={() => type === 'manufacturers'
                        || type === 'models'
                        || !is_information_correct
                        || _.isEmpty(data)
                          ? handleClickSearchManually(type)
                          : clickVinHandler(item)}
                      >
                        {getCarDescription(item)}
                      </li>
                    )}
                    {
                      hasSelectedData
                    && multipleVariants
                    && item.is_selected
                    && !viewAllModels
                    && <button className="change-model-btn" type="button" onClick={() => setViewAllModels(true)}>Select another car variant</button>
                    }
                  </div>
                )
              }) : <div className="no-suggestions">{`${t('NO_DATA')}!`}</div>}

          </ul>
          {/* {
            showSpinner
              ? ''
              : type === 'manufacturers' || type === 'models' || !is_information_correct || _.isEmpty(data)
                ? showManuallySearch(type)
                : ''
          } */}

          {
           
            showSpinner
              ? ''
              : search_from_tecalliance 
                ? showAllCars()
                : ''
          }
        </div>
      )
    }

    return null
  }

  return (
    <div
      className={props.activeButtonIndex !== 'vin' ? 'd-none' : 'vin-container'}
      ref={refVin}
    >
      <div className="vin">
        <form
          className="search-form custom-search-form"
          onSubmit={submitHandler}
        >
          <div
            className="inner-search-container"
          >
            <div className="search-container">
              <input
                ref={inputRef}
                className={`search-header form-control ${errorStatus ? 'input-error' : ''}`}
                type="text"
                placeholder={t('SEARCH_BY_VIN')}
                aria-label="Search"
                value={vin}
                onChange={updateSearchValue}
              />
              {vin && <button className="close-icon" aria-label="Reset" type="reset" onClick={clearVin} />}
              {!isMobile && vinListComponent('desktop')}
              {isMobile && isHostPublic() && vinListComponent('mobile')}
              <div className={`${errorStatus ? 'error-container' : 'd-none'}`}>
                {t(errorMessageStatus)}
              </div>
            </div>
            <button
              className={`button-style search-toolbar-button ${vin ? 'button-style-hover' : 'button-disabled-style'}`}
              type="submit"
              onClick={() => setViewAllModels(false)}
            >
              <i className="material-icons">search</i>
            </button>
          </div>
          {isMobile && !isHostPublic() && vinListComponent('mobile')}
        </form>
      </div>
    </div>
  )
}

export default withRouter(SearchVin)
