import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {isHostPunchOut} from 'utils'
import {resetCarParts} from 'store/actions/carPartsActions'
import {getCurrentDomain,
  getCurrentMarketplace,
  getURLParams} from 'utils/getData'
import {SUPPLIER_AMERIGO} from 'constants/roles'
import ToggleButton from '../ToogleButton'
import ToolBarNav from '../ToolBarNav'
import OrderChallenge from './components/OrderChallenge'
import LogoSupplier from './components/LogoSupplier'
import './style.scss'

const Toolbar = ({drawerClickHandler}) => {
  const dispatch = useDispatch()
  const marketplace = getCurrentMarketplace()
  const {catalogInfo, loading} = useSelector(
    state => state.catalogInfoState,
  )
  const {user} = useSelector(state => state.userState)
  const isPunchOut = isHostPunchOut()

  const location = useLocation()
  const urlParams = getURLParams(location.search)
  const handlerResetCarParts = event => {
    if (!event.ctrlKey && !event.metaKey) {
      dispatch(resetCarParts())
    }
  }

  const showLogos = () => (
    <>
      <NavLink
        to="/"
        onClick={event => handlerResetCarParts(event)}
        className={marketplace}
      >
        <img src={`/images/logos/${marketplace}.png`} alt={marketplace} />
      </NavLink>
      <LogoSupplier role={SUPPLIER_AMERIGO} />
    </>
  )

  const getImagePath = item => `${getCurrentDomain()}/images/marketplace/catalogs/desktop/${item.image}`

  const showCatalogImage = () => !loading && catalogInfo && catalogInfo?.id === urlParams.catalog_id ? (
    <div className="toolbar-catalog-logo">
      {window.location.href.indexOf('view') !== -1 ? (
        <NavLink to={urlParams.catalog_id === 'CNT' ? '/' : `/car-parts?catalog_id=${urlParams.catalog_id}`}>
          <img src={getImagePath(catalogInfo)} alt={catalogInfo?.name} />
        </NavLink>
      ) : (
        <img src={getImagePath(catalogInfo)} alt={catalogInfo?.name} />
      )}
    </div>
  ) : null

  const showPunchOutLogo = () => isPunchOut && user?.client_logo ? (
    <div className="toolbar-catalog-logo">
      <img src={user?.client_logo} alt="client_logo" />
    </div>
  ) : null

  const checkCatalog = urlParams.hasOwnProperty('catalog_id')
    && window.location.pathname === '/car-parts'
  const checkCatalogView = urlParams.hasOwnProperty('catalog_id')
    && window.location.pathname.includes('view')
  const checkReliable = window.location.pathname.includes('reliable')
    && window.location.pathname.includes('car-parts')
    && !window.location.pathname.includes('view')

  const filterStatus = checkCatalog || checkReliable

  let type = 'filter'

  if (urlParams.hasOwnProperty('catalog_id')) {
    type = 'catalog-filter'
  }

  return (
    <header className="toolbar">
      {filterStatus ? (
        <div className="filter-toggle-button">
          <ToggleButton toggleDrawer={drawerClickHandler} type={type} />
        </div>
      ) : null}

      <div
        className={`toolbar-logo ${
          checkCatalog || checkCatalogView ? 'catalog' : ''
        }`}
      >
        {showLogos()}
      </div>

      {showCatalogImage()}

      {showPunchOutLogo()}

      {user?.client_name ? (
        <div className="toolbar-client-name">{user?.client_name}</div>
      ) : null}

      <OrderChallenge />

      <div className="toolbar-nav-wrapper">
        <ToolBarNav />
      </div>

      <div className="toolbar-toggle-button">
        <ToggleButton toggleDrawer={drawerClickHandler} type="menu" />
      </div>
    </header>
  )
}

export default Toolbar
