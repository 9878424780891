import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'store/reducers/rootReducer'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {AxiosResponse} from 'axios'
import i18n from 'i18next'
import Notification from '../components/Notification'
import {ICooGroups} from '../interfaces/vehicle-maintenance.interface'
import {IUrlParams} from '../interfaces/url-params.interface'
import {getURLParams} from './getData'
import {fetchUnseenMessages} from '../api/clientList'
import {ACTION_UPDATE_UNSEEN_MESSAGES} from '../constants/actionsTypes'
import {IVehicleManualSubGroups,
  ICategoryBreadCrumbs,
  IVehicleManualList} from '../interfaces/vehicle-manuals.interface'

export const usePrevious = (value: any): any => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

interface IBadRequestNewMessage{
  message: string
  propertyPath: string
}
export const useLocationChange = (action: (location: any, prevLocation: any, historyAction: string) => void): void => {
  const location = useLocation()
  const history = useHistory()
  const prevLocation = usePrevious(location)
  useEffect(() => {
    action(location, prevLocation, history.action)
  }, [location])
}

export const useDocumentTitle = (title: string): void => {
  document.title = title
}

export const useReliableMaintenanceEquipmentFilterIdsURIParams = (): string[] => {
  const url = new URL(window.location.href)
  return url.searchParams.getAll('equipment_filter_ids[]')
}

export const useReliableMaintenanceServiceWorkIdsURIParams = (): string | null => {
  const url = new URL(window.location.href)
  return url.searchParams.get('work_ids')
}

export const useGeFilterParams = (): any => {
  const url = new URL(window.location.href)
  const suppliers = url.searchParams.getAll('sup[]')
  const manufacture = url.searchParams.getAll('man[]')

  return {suppliers, manufacture}
}

export const useMobileDetect = (defaultWidth = 860): boolean => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth <= defaultWidth,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth <= defaultWidth,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return dimensions.width
}

export const useMapTableData = (data: any): any => {
  let affectation = '-'
  let chassis = '-'
  let geographicalPosition = '-'

  if (data) {
    if (data.start_month && data.start_year) {
      affectation = `${String(data.start_month)}/${String(data.start_year)} >`
    }
    if (data.start_month && data.start_year && data.end_month && data.end_year) {
      affectation = `${String(data.start_month)}/${String(data.start_year)} > ${String(data.end_month)}/${String(data.end_year)}`
    }
    if (data.start_frame) {
      chassis = `${String(data.start_frame)} >`
    } else if (data.end_frame) {
      chassis = `> ${String(data.end_frame)}`
    } else {
      chassis = '-'
    }
    if (data.start_frame && data.end_frame) {
      chassis = `${String(data.start_frame)} > ${String(data.end_frame)}`
    }
    if (data.geographical_position) {
      geographicalPosition = data.geographical_position
    }
    if (data.physical_position) {
      geographicalPosition = data.physical_position
    }
    if (data.geographical_position && data.physical_position) {
      geographicalPosition = `${String(data.geographical_position)} - ${String(data.physical_position)}`
    }
  }
  return {geographicalPosition, affectation, chassis}
}

export const useFormFields = (initialState: any): any => {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    (event: any) => {
      if (!event.target) {
        setValues({
          ...event,
        })
      } else {
        setValues({
          ...fields,
          [event.target.name]: event.target.value,
        })
      }
    },
  ]
}

export const useOnClickOutside = (ref: React.MutableRefObject<HTMLDivElement>, handler: (event: any) => void): any => {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler],
  )
}

export const useQuery = (search: string): URLSearchParams => {
  return new URLSearchParams(search)
}

export const usePermission = (roles: string[] = []): boolean => {
  const {user} = useSelector((state: RootState) => state.userState)
  return roles.some((role: string) => user?.roles.includes(role))
}

export const checkPermissionEina = () => {
  const {user} = useSelector((state: RootState) => state.userState)
  return user ? user?.client_settings?.eina_subscription : false
}

export const useCountUnseenMessages = (): void => {
  const {user} = useSelector((state: RootState) => state.userState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && user.client_id) {
      fetchUnseenMessages().then(({data}: AxiosResponse) => {
        dispatch({
          type: ACTION_UPDATE_UNSEEN_MESSAGES,
          unseenMessages: data,
        })
      }).catch((e: Error) => console.error(e))
    }
  }, [user])
}

export const useHandleBadGatewayRequest = (): void => {
  const {badGateway} = useSelector((state: RootState) => state.errorState)
  const {t} = useTranslation()
  useEffect(() => {
    let message = t('BAD_GATEWAY')
    if (badGateway && badGateway === 'object' && 'message' in badGateway) {
      message = t(`${String(badGateway.message)}`)
    }
    if (badGateway) {
      Notification({
        container: 'top-center',
        message,
        type: 'danger',
        title: i18n.t('ERROR'),
        duration: 5000,
      })
    }
  }, [badGateway])
}

export const useHandleBadRequest = (): void => {
  const {badRequest} = useSelector((state: RootState) => state.errorState)
  const {t} = useTranslation()

  useEffect(() => {
    if (badRequest) {
      const [messageFromRes] = Object.keys(badRequest.data).map(key => Array.isArray(badRequest.data[key]) ? `${String(Object.values(badRequest.data[key]))}` : '')
      const newMessage = Array.isArray(badRequest.data) 
      && badRequest?.data.length
        ? badRequest?.data.map((errors: any) => errors.map((error: IBadRequestNewMessage) => `${error.propertyPath}: ${error.message}`)) : t(badRequest.data)
      
      let message = t('SOMETHING_WENT_WRONG')

      if (messageFromRes && messageFromRes.length) {
        message = t(messageFromRes)
      } else if (badRequest?.data?.detail) {
        message = `${String(badRequest?.data?.detail)}`
      } else if (badRequest.status === 404) {
        message = badRequest.statusText
      }
      if (!message && (newMessage && newMessage.length)) {
        message = newMessage ? newMessage : messageFromRes
      }
      
      Notification({
        container: 'top-center',
        message,
        type: 'danger',
        title: t('ERROR'),
        duration: 5000,
      })
    }
  }, [badRequest])
}
export const useHandleUnprocessableEntity = (): void => {
  const {unprocessableEntity} = useSelector((state: RootState) => state.errorState)
  const {t} = useTranslation()

  useEffect(() => {
    if (unprocessableEntity) {
      let message = t('SOMETHING_WENT_WRONG')
      if (unprocessableEntity?.message) {
        message = `${t(String(unprocessableEntity?.message))}`
      } else if (unprocessableEntity.status === 422) {
        message = t(unprocessableEntity.statusText)
      } else if (Object.keys(unprocessableEntity).length) {
        Object.keys(unprocessableEntity).forEach(key => {
          Notification({
            container: 'top-center',
            message: unprocessableEntity[key],
            type: 'danger',
            title: t('ERROR'),
            duration: 5000,
          })
        })

        return
      }

      Notification({
        container: 'top-center',
        message,
        type: 'danger',
        title: t('ERROR'),
        duration: 5000,
      })
    }
  }, [unprocessableEntity])
}

export const useCooGroupsValuesFromUrl = (): ICooGroups => {
  const urlParams: IUrlParams = getURLParams(location.search)
  const mileage = Number(urlParams.mileage) && Number(urlParams.mileage) > 0 ? String(urlParams.mileage) : ''
  const initialRegistrationDate = Number(urlParams.registration_date_month) && Number(urlParams.registration_date_year)
    ? new Date(Number(urlParams.registration_date_year), Number(urlParams.registration_date_month), 1)
    : new Date()
  const transmissionTypeId = Number(urlParams.transmission_type_id) ? Number(urlParams.transmission_type_id) : null
  const cooGroupId = Number(urlParams.coo_group_id) ? Number(urlParams.coo_group_id) : undefined
  const equipmentFilterId = useReliableMaintenanceEquipmentFilterIdsURIParams().map((v: string) => Number(v))
  return {
    initialRegistrationDate,
    transmissionTypeId,
    mileage,
    cooGroupId,
    equipmentFilterId,
    europeanExhaustEmissionStandard: null,
  }
}

export const useSubCategoryBreadCrumbs = (manualList: IVehicleManualSubGroups[]): ICategoryBreadCrumbs => {
  const urlParams: IUrlParams = getURLParams(location.search)
  const breadCrumbs: ICategoryBreadCrumbs = {}
  manualList.map(subGroup => {
    if (subGroup.SubGroupId == urlParams.subgroup_id) {
      breadCrumbs.subGroup = subGroup.SubGroupName
      subGroup.ItemMps.map(item => {
        if (item.ItemMpId == urlParams.item_id) {
          breadCrumbs.itemName = item.ItemMpText
          item.Manuals && item.Manuals.map(manual => {
            if (manual.QualColText && (manual.ManualId == urlParams.manual_id)) {
              breadCrumbs.manualId = manual.QualColText
            }
          })
        }
      })
    }
  })
  return breadCrumbs
}

export const useCategoryBreadCrumbs = (manualList: IVehicleManualList[]): ICategoryBreadCrumbs => {
  const urlParams: IUrlParams = getURLParams(location.search)

  const mainGroup = manualList.find(node => node.MainGroupId == urlParams.group_id)
  const subCategories = mainGroup && useSubCategoryBreadCrumbs(mainGroup.SubGroups)

  const breadCrumbs: ICategoryBreadCrumbs = {
    mainGroup: mainGroup?.MainGroupName,
    subGroup: subCategories?.subGroup,
    itemName: subCategories?.itemName,
    manualId: subCategories?.manualId,
  }

  return breadCrumbs
}
