// User
export const ACTION_UPDATE_USER = 'ACTION_UPDATE_USER'
export const ACTION_FETCH_USER_INFO = 'ACTION_FETCH_USER_INFO'
export const ACTION_UPDATE_ACCOUNT_SETTINGS = 'ACTION_UPDATE_ACCOUNT_SETTINGS'
export const ACTION_FETCH_USER_INFO_FAIL = 'ACTION_FETCH_USER_INFO_FAIL'
export const RUN_UPDATE_SETTINGS = 'RUN_UPDATE_SETTINGS'
export const FAILED_UPDATE_SETTINGS = 'FAILED_UPDATE_SETTINGS'
export const RESET_ACCOUNT_SETTINGS = 'RESET_ACCOUNT_SETTINGS'

// Reliable
export const ACTION_FETCH_MANUALLY_SEARCH = 'ACTION_FETCH_MANUALLY_SEARCH'
export const ACTION_UPDATE_MANUALLY_SEARCH = 'ACTION_UPDATE_MANUALLY_SEARCH'
export const ACTION_TOGGLE_MANUALLY_SEARCH = 'ACTION_TOGGLE_MANUALLY_SEARCH'

export const FETCH_CAR_PARTS_REFERENCES = 'FETCH_CAR_PARTS_REFERENCES'
export const FETCH_CAR_PARTS_FILTERS = 'FETCH_CAR_PARTS_FILTERS'
export const ACTION_CAR_PARTS_FILTERS = 'ACTION_CAR_PARTS_FILTERS'
export const ACTION_CAR_PARTS_REFERENCES = 'ACTION_CAR_PARTS_REFERENCES'
export const ACTION_RESET_CAR_PARTS_REFERENCE = 'ACTION_RESET_CAR_PARTS_REFERENCE'

export const ACTION_FETCH_OIL_ITEM = 'ACTION_FETCH_OIL_ITEM'
export const ACTION_LOADING_OIL_ITEM = 'ACTION_LOADING_OIL_ITEM'
export const ACTION_FETCH_OIL_SEARCH = 'ACTION_FETCH_OIL_SEARCH'
export const ACTION_FETCH_OIL_FILTERS = 'ACTION_FETCH_OIL_FILTERS'
export const ACTION_LOADING_OIL_FILTERS = 'ACTION_LOADING_OIL_FILTERS'
export const ACTION_LOADING_OIL_SEARCH = 'ACTION_LOADING_OIL_SEARCH'
export const ACTION_RESET_OIL_SEARCH = 'ACTION_RESET_OIL_SEARCH'
export const ACTION_RESET_OIL_FILTERS = 'ACTION_RESET_OIL_FILTERS'

export const ACTION_FETCH_CONSUMABLE_ITEM = 'ACTION_FETCH_CONSUMABLE_ITEM'
export const ACTION_LOADING_CONSUMABLE_ITEM = 'ACTION_LOADING_CONSUMABLE_ITEM'
export const ACTION_FETCH_CONSUMABLE_SEARCH = 'ACTION_FETCH_CONSUMABLE_SEARCH'
export const ACTION_FETCH_CONSUMABLE_FILTERS = 'ACTION_FETCH_CONSUMABLE_FILTERS'
export const ACTION_LOADING_CONSUMABLE_FILTERS = 'ACTION_LOADING_CONSUMABLE_FILTERS'
export const ACTION_LOADING_CONSUMABLE_SEARCH = 'ACTION_LOADING_CONSUMABLE_SEARCH'
export const ACTION_RESET_CONSUMABLE_SEARCH = 'ACTION_RESET_CONSUMABLE_SEARCH'
export const ACTION_RESET_CONSUMABLE_FILTERS = 'ACTION_RESET_CONSUMABLE_FILTERS'

export const ACTION_FETCH_TYRE_ITEM = 'ACTION_FETCH_TYRE_ITEM'
export const ACTION_LOADING_TYRE_ITEM = 'ACTION_LOADING_TYRE_ITEM'
export const ACTION_FETCH_TYRE_SEARCH = 'ACTION_FETCH_TYRE_SEARCH'
export const ACTION_FETCH_TYRE_FILTERS = 'ACTION_FETCH_TYRE_FILTERS'
export const ACTION_LOADING_TYRE_FILTERS = 'ACTION_LOADING_TYRE_FILTERS'
export const ACTION_LOADING_TYRE_SEARCH = 'ACTION_LOADING_TYRE_SEARCH'
export const ACTION_RESET_TYRE_SEARCH = 'ACTION_RESET_TYRE_SEARCH'

export const ACTION_UPDATE_VEHICLE_CATEGORIES = 'ACTION_UPDATE_VEHICLE_CATEGORIES'
export const ACTION_UPDATE_VEHICLE_SUBCATEGORIES = 'ACTION_UPDATE_VEHICLE_SUBCATEGORIES'
export const ACTION_UPDATE_VEHICLE_CATEGORIES_BY = 'ACTION_UPDATE_VEHICLE_CATEGORIES_BY'
export const ACTION_GET_VEHICLE_CATEGORIES_BY = 'ACTION_GET_VEHICLE_CATEGORIES_BY'
export const ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED = 'ACTION_GET_VEHICLE_CATEGORIES_BY_FAILED'
export const ACTION_SET_VEHICLE_ID = 'ACTION_SET_VEHICLE_ID'

export const ACTION_UPDATE_BREADCRUMBS = 'ACTION_UPDATE_BREADCRUMBS'
export const ACTION_REMOVE_BREADCRUMBS = 'ACTION_REMOVE_BREADCRUMBS'
export const GET_BREADCRUMBS = 'GET_BREADCRUMBS'
export const GET_BREADCRUMBS_FAILED = 'GET_BREADCRUMBS_FAILED'

export const ACTION_UPDATE_TECRMIT_TYPE_ID = 'ACTION_UPDATE_TECRMIT_TYPE_ID'
export const ACTION_UPDATE_IS_ACTIVE_SERVICE_PLAN = 'ACTION_UPDATE_IS_ACTIVE_SERVICE_PLAN'
export const ACTION_UPDATE_IS_ACTIVE_REPAIR_PLAN = 'ACTION_UPDATE_IS_ACTIVE_REPAIR_PLAN'
export const ACTION_UPDATE_WORK_IDs_ARRAY = 'ACTION_UPDATE_WORK_IDs_ARRAY'
export const ACTION_UPDATE_TECRMI_MILEAGE = 'ACTION_UPDATE_TECRMI_MILEAGE'
export const ACTION_UPDATE_TECRMI_HASTABS = 'ACTION_UPDATE_TECRMI_HASTABS'

// Catalog
export const ACTION_UPDATE_CATEGORIES = 'ACTION_UPDATE_CATEGORIES'
export const FETCH_UPDATE_CATEGORIES = 'FETCH_UPDATE_CATEGORIES'
export const ACTION_UPDATE_CATALOG_DATA = 'ACTION_UPDATE_CATALOG_DATA'
export const ACTION_RESET_CATALOG_DATA = 'ACTION_RESET_CATALOG_DATA'
export const ACTION_UPDATE_LOADING_CATALOG = 'ACTION_UPDATE_LOADING_CATALOG'
export const ACTION_UPDATE_LOADING_PAGE_CATALOG = 'ACTION_UPDATE_LOADING_PAGE_CATALOG'

// Vin
export const ACTION_FETCH_MODELS_VIN = 'ACTION_FETCH_MODELS_VIN'
export const ACTION_FETCH_CAR_PARTS_MODEL = 'ACTION_FETCH_CAR_PARTS_MODEL'
export const ACTION_UPDATE_MODELS_VIN = 'ACTION_UPDATE_MODELS_VIN'
export const ACTION_RESET_MODELS_VIN = 'ACTION_RESET_MODELS_VIN'
export const ACTION_UPDATE_CAR_PARTS_MODEL = 'ACTION_UPDATE_CAR_PARTS_MODEL'

// Catalog Info
export const ACTION_UPDATE_CATALOG_INFO = 'ACTION_UPDATE_CATALOG_INFO'
export const FETCH_CATALOG_INFO = 'FETCH_CATALOG_INFO'

// Car Brands
export const ACTION_UPDATE_CATALOG_BRANDS = 'ACTION_UPDATE_CATALOG_BRANDS'
export const FETCH_CATALOG_BRANDS = 'FETCH_CATALOG_BRANDS'

// Errors
export const ACTION_CLEAR_ERROR_STATUS = 'ACTION_CLEAR_ERROR_STATUS'
export const ACTION_INTERNAL_SERVER_ERROR = 'ACTION_INTERNAL_SERVER_ERROR'
export const ACTION_BAD_REQUEST_ERROR = 'ACTION_BAD_REQUEST_ERROR'
export const ACTION_BAD_GATEWAY_ERROR = 'ACTION_BAD_GATEWAY_ERROR'
export const ACTION_UNPROCESSABLE_ENTITY_ERROR = 'ACTION_UNPROCESSABLE_ENTITY_ERROR'

// car parts
export const ACTION_SET_CAR_PARTS_FILTERS = 'ACTION_SET_CAR_PARTS_FILTERS'
export const ACTION_SET_CAR_PARTS = 'ACTION_SET_CAR_PARTS'
export const ACTION_SET_CAR_PARTS_FILTER_RELIABLE = 'ACTION_SET_CAR_PARTS_FILTER_RELIABLE'
export const ACTION_FETCH_CAR_PARTS = 'ACTION_FETCH_CAR_PARTS'
export const ACTION_FETCH_CAR_PARTS_FAIL = 'ACTION_FETCH_CAR_PARTS_FAIL'
export const ACTION_SET_CAR_PARTS_WITH_REFERENCE = 'ACTION_SET_CAR_PARTS_WITH_REFERENCE'
export const ACTION_FETCH_CAR_PARTS_IN_BACKGROUND = 'ACTION_FETCH_CAR_PARTS_IN_BACKGROUND'
export const ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND = 'ACTION_UPDATE_CAR_PARTS_IN_BACKGROUND'
export const ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST = 'ACTION_UPDATE_CAR_PARTS_ALL_OE_LIST'

export const ACTION_RESET_CAR_PARTS = 'ACTION_RESET_CAR_PARTS'
export const ACTION_FILTER_CAR_PARTS = 'ACTION_FILTER_CAR_PARTS'
export const ACTION_CLEAR_FILTER_CAR_PARTS = 'ACTION_CLEAR_FILTER_CAR_PARTS'
export const ACTION_RESET_CAR_PARTS_FILTER = 'ACTION_RESET_CAR_PARTS_FILTER'

// single car part
export const ACTION_FETCH_CAR_PART = 'ACTION_FETCH_CAR_PART'
export const ACTION_UPDATE_CAR_PART = 'ACTION_UPDATE_CAR_PART'
export const ACTION_FETCH_CAR_PART_EQUIVALENCES = 'ACTION_FETCH_CAR_PART_EQUIVALENCES'
export const ACTION_FETCH_CAR_PART_TAB_CARS = 'ACTION_FETCH_CAR_PART_TAB_CARS'
export const ACTION_FETCH_CAR_PART_TAB_TRUCKS = 'ACTION_FETCH_CAR_PART_TAB_TRUCKS'
export const ACTION_FETCH_CAR_PART_FAIL = 'ACTION_FETCH_CAR_PART_FAIL'

export const ACTION_UPDATE_CART_QUANTITY_TOOLBAR = 'ACTION_UPDATE_CART_QUANTITY_TOOLBAR'
export const ACTION_FETCH_CART_FAILURE = 'ACTION_FETCH_CART_FAILURE'
export const ACTION_FETCH_CART_CHECKOUT_STATUS = 'ACTION_FETCH_CART_CHECKOUT_STATUS'

export const ACTION_TOGGLE_CSV = 'ACTION_TOGGLE_CSV'
export const ACTION_GET_CSV = 'ACTION_GET_CSV'
export const ACTION_UPDATE_CSV = 'ACTION_UPDATE_CSV'
export const ACTION_CLEAR_CSV = 'ACTION_CLEAR_CSV'
export const ACTION_DELETE_ROW_CSV = 'ACTION_DELETE_ROW_CSV'
export const ACTION_UPDATE_ERRORS_ROW_STATUS_CSV = 'ACTION_UPDATE_ERRORS_ROW_STATUS_CSV'

export const ACTION_AMERIGO_TOGGLE_CSV = 'ACTION_AMERIGO_TOGGLE_CSV'
export const ACTION_AMERIGO_GET_CSV = 'ACTION_AMERIGO_GET_CSV'
export const ACTION_AMERIGO_UPDATE_CSV = 'ACTION_AMERIGO_UPDATE_CSV'
export const ACTION_AMERIGO_UPDATE_HEADER = 'ACTION_AMERIGO_UPDATE_HEADER'
export const ACTION_AMERIGO_CLEAR_CSV = 'ACTION_AMERIGO_CLEAR_CSV'
export const ACTION_AMERIGO_DELETE_ROW_CSV = 'ACTION_AMERIGO_DELETE_ROW_CSV'
export const ACTION_AMERIGO_UPDATE_ERRORS_ROW_STATUS_CSV = 'ACTION_AMERIGO_UPDATE_ERRORS_ROW_STATUS_CSV'

// reset selectors
export const ACTION_RESET_CAR_SELECTORS = 'ACTON_RESET_CAR_SELECTORS'
export const ACTION_RESET_TRUCK_SELECTORS = 'ACTON_RESET_TRUCK_SELECTORS'
export const ACTION_SET_INIT_STATE_SELECTORS = 'ACTION_SET_INIT_STATE_SELECTORS'

// modals
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

// favorite categories
export const ACTION_RESET_FAVORITE_CATEGORIES = 'ACTION_RESET_FAVORITE_CATEGORIES'
export const ACTION_FETCH_FAVORITE_CATEGORIES = 'ACTION_FETCH_FAVORITE_CATEGORIES'
export const ACTION_SET_FAVORITE_CATEGORIES = 'ACTION_SET_FAVORITE_CATEGORIES'
export const ACTION_ADD_FAVORITE_CATEGORY = 'ACTION_ADD_FAVORITE_CATEGORY'
export const ACTION_EDIT_FAVORITE_CATEGORY = 'ACTION_EDIT_FAVORITE_CATEGORY'

// proforma order
export const ACTION_UPLOAD_PROFORMA_FILE = 'ACTION_UPLOAD_PROFORMA_FILE'
export const ACTION_UPLOAD_PROFORMA_FILE_SUCCESS = 'ACTION_UPLOAD_PROFORMA_FILE_SUCCESS'
export const ACTION_UPLOAD_PROFORMA_FILE_FAILURE = 'ACTION_UPLOAD_PROFORMA_FILE_FAILURE'
export const ACTION_UPDATE_PROFORMA_ROWS = 'ACTION_UPDATE_PROFORMA_ROWS'
export const ACTION_POST_PROFORMA_ORDER = 'ACTION_POST_PROFORMA_ORDER'
export const ACTION_UPDATE_PROFORMA_DELIVERY_DATE = 'ACTION_POST_PROFORMA_DELIVERY_DATE'
export const ACTION_POST_PROFORMA_FAILURE = 'ACTION_POST_PROFORMA_FAILURE'
export const ACTION_POST_PROFORMA_SUCCESS = 'ACTION_POST_PROFORMA_SUCCESS'
export const ACTION_CLEAR_PROFORMA_CSV = 'ACTION_CLEAR_PROFORMA_CSV'
export const ACTION_CLOSE_SPINER = 'ACTION_CLOSE_SPINER'

// proforma amerigo order
export const ACTION_UPLOAD_PROFORMA_AMERIGO_FILE = 'ACTION_UPLOAD_PROFORMA_AMERIGO_FILE'
export const ACTION_UPLOAD_PROFORMA_AMERIGO_FILE_SUCCESS = 'ACTION_UPLOAD_PROFORMA_AMERIGO_FILE_SUCCESS'
export const ACTION_UPLOAD_PROFORMA_AMERIGO_FILE_FAILURE = 'ACTION_UPLOAD_PROFORMA_AMERIGO_FILE_FAILURE'
export const ACTION_UPDATE_PROFORMA_AMERIGO_ROWS = 'ACTION_UPDATE_PROFORMA_AMERIGO_ROWS'
export const ACTION_POST_PROFORMA_AMERIGO_ORDER = 'ACTION_POST_PROFORMA_AMERIGO_ORDER'
export const ACTION_POST_PROFORMA_AMERIGO_FAILURE = 'ACTION_POST_PROFORMA_AMERIGO_FAILURE'
export const ACTION_POST_PROFORMA_AMERIGO_SUCCESS = 'ACTION_POST_PROFORMA_AMERIGO_SUCCESS'
export const ACTION_UPDATE_PROFORMA_AMERIGO_DELIVERY_DATE = 'ACTION_UPDATE_PROFORMA_AMERIGO_DELIVERY_DATE'
export const ACTION_CLEAR_PROFORMA_AMERIGO_CSV = 'ACTION_CLEAR_PROFORMA_AMERIGO_CSV'
export const ACTION_CLOSE_AMERIGO_SPINER = 'ACTION_CLOSE_AMERIGO_SPINER'

// messenger
export const ACTION_UPDATE_UNSEEN_MESSAGES = 'ACTION_UPDATE_UNSEEN_MESSAGES'
export const ACTION_UPDATE_HAS_CHALLENGE = 'ACTION_UPDATE_HAS_CHALLENGE'
