import {
  ACTION_UPDATE_TECRMIT_TYPE_ID,
  ACTION_UPDATE_IS_ACTIVE_SERVICE_PLAN, ACTION_UPDATE_WORK_IDs_ARRAY,
  ACTION_UPDATE_TECRMI_MILEAGE, ACTION_UPDATE_IS_ACTIVE_REPAIR_PLAN, ACTION_UPDATE_TECRMI_HASTABS,
} from 'constants/actionsTypes'
import {TecRMIActionTypes, ITecRMI} from 'store/types/tecRMI'

const initialState: ITecRMI = {
  typeId: null,
  mileage: null,
  isActiveServicePlan: false,
  isActiveRepairPlan: false,
  workIDs: [],
  hasTab: {},
}

export default function tecRMIReduceReducer(state = initialState, action: TecRMIActionTypes = {type: 'ACTION_UPDATE_TECRMIT_TYPE_ID', payload: 0}): ITecRMI {
  switch (action.type) {
    case ACTION_UPDATE_TECRMIT_TYPE_ID:
      return {
        ...state,
        typeId: Number(action.payload),
      }

    case ACTION_UPDATE_IS_ACTIVE_SERVICE_PLAN:
      return {
        ...state,
        isActiveServicePlan: action.payload,
      }

    case ACTION_UPDATE_IS_ACTIVE_REPAIR_PLAN:
      return {
        ...state,
        isActiveRepairPlan: action.payload,
      }

    case ACTION_UPDATE_TECRMI_MILEAGE:
      return {
        ...state,
        mileage: Number(action.payload),
      }

    case ACTION_UPDATE_WORK_IDs_ARRAY:
      return {
        ...state,
        workIDs: action.payload,
      }
    case ACTION_UPDATE_TECRMI_HASTABS:
      return {
        ...state,
        hasTab: action.payload,
      }
    default:
      return state
  }
}
