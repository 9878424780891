import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import './style.scss'

const Preloader: React.FC = (): JSX.Element => {
  const [loadingStep, setLoadingStep] = useState(0)
  const {t} = useTranslation()

  useEffect(() => {
    const intervalID = setInterval(() => {
      setLoadingStep(1)
    }, 5000)
    return () => clearInterval(intervalID)
  })

  return (
    <div className="wrapper-preloader">
      <div className="preloader" />
      { loadingStep === 0 ? (
        <h6>{t('The results are on the way')}</h6>
      ) : (
        <h6>{t('Just a few seconds and we\'re there')}</h6>
      )}
      <span>{t('We are checking the manufacturer\'s database')}</span>
    </div>
  )
}

export default Preloader
