import {ACTION_FETCH_CART_FAILURE, ACTION_FETCH_CART_CHECKOUT_STATUS, ACTION_UPDATE_CART_QUANTITY_TOOLBAR} from 'constants/actionsTypes'

export const initialState = {
  checkoutErrorStatus: null,
  checkoutRequestStatus: false,
  quantityToolbar: null,
}

export default function cartReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_CART_QUANTITY_TOOLBAR:
      return {
        ...state,
        quantityToolbar: action.quantityToolbar,
      }

    case ACTION_FETCH_CART_FAILURE:
      return {
        ...state,
        checkoutErrorStatus: action.checkoutErrorStatus,
      }

    case ACTION_FETCH_CART_CHECKOUT_STATUS:
      return {
        ...state,
        checkoutRequestStatus: action.checkoutRequestStatus,
      }

    default:
      return state
  }
}
