import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import $ from 'jquery'

import Notification from 'components/Notification'
import {fetchFeedback, createFeedback} from 'api/feedback'

import './style.scss'

const FeedBackButton = () => {
  const {t} = useTranslation()
  const [carData, setCarData] = useState({})
  const [category, setCategory] = useState({})
  const [part, setPart] = useState({})

  const [isFeedbackBtnDisabled, toggleFeedbackBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sendFeedbackLoading, setSendFeedbackLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [modalClasses, setModalClasses] = useState(['modal', 'fade'])

  const renderCarName = car => {
    if (car && car.version) {
      return (
        <>
          {`${car?.version?.manufacturer_name} `}
          {`${car?.version?.model_name} `}
          {`${car?.version?.engineCapacity}; `}
          {new Date(car?.version?.startYear, car?.version?.startMonth).toLocaleDateString()}
        </>
      )
    }

    if (car && car.manufacturer) {
      return (
        <>
          {car?.manufacturer?.description }
          {}
        </>
      )
    }

    if (car && car.model) {
      return (
        <>
          {`${car?.model?.manufacturerName} `}
          {`${car?.model?.modelName} `}
        </>
      )
    }
  }

  const renderPart = () => {
    if (part && part.is_oe_part) {
      return `${part.manufacturer_group_name} ${part.oe_reference}`
    }

    if (part && !part.is_oe_part) {
      return `${part.data_supplier_name} ${part.data_supplier_reference}`
    }

    return ''
  }

  const getFeedbackApi = () => {
    setLoading(true)
    toggleFeedbackBtn(!isFeedbackBtnDisabled)
    fetchFeedback()
      .then(({data}) => {
        setCarData(data.car)
        setCategory(data.category)
        setPart(data.part)
        toggleModal()
      })
      .catch(() => {
        Notification({
          container: 'top-right',
          message: t('SOMETHING_WENT_WRONG'),
          type: 'danger',
          title: t('ERROR'),
        })

        toggleFeedbackBtn(false)
      }).finally(() => setLoading(false))
  }

  const sendFeedback = () => {
    setSendFeedbackLoading(true)

    createFeedback({
      car: carData,
      category,
      part,
      message,
    })
      .then(() => {
        Notification({
          container: 'top-right',
          message: t('MESSAGE_SUCCESS'),
          title: t('SUCCESS'),
        })
      })
      .catch(() => {
        Notification({
          container: 'top-right',
          message: t('SOMETHING_WENT_WRONG'),
          type: 'danger',
          title: t('ERROR'),
        })
      }).finally(() => {
        setSendFeedbackLoading(false)
        toggleModal()
        setMessage('')
      })
  }

  const toggleModal = () => {
    const currentModalClasses = [...modalClasses]

    if (currentModalClasses.indexOf('show') > -1) {
      document.querySelector('.feedback-container').style.position = 'sticky'
      currentModalClasses.pop()
      toggleFeedbackBtn(!isFeedbackBtnDisabled)

      $('#popupCreateFeedback').hide()
    } else {
      document.querySelector('.feedback-container').style.position = 'inherit'
      currentModalClasses.push('show')

      $('#popupCreateFeedback').modal()
    }

    setModalClasses(currentModalClasses)
  }

  return (
    <div className="feedback-container">
      <button
        type="button"
        disabled={isFeedbackBtnDisabled || loading}
        className="btn btn-feedback-outline rolling"
        onClick={() => getFeedbackApi()}
      >
        {
          loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>
              <span className="feedback-text">{t('FEEDBACK')}</span>
              <img src="/images/icons/feedback.svg" alt="feedback" className="feedback-img" />
            </>
          )
        }
      </button>

      <div
        className={modalClasses.join(' ')}
        id="popupCreateFeedback"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="font-weight-normal">{t('SEND_FEEDBACK')}</h2>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {carData ? (
                <div className="d-flex flex-column">
                  <h6 className="mb-0">{t('CAR')}</h6>
                  <div className="mb-3 small">
                    {carData ? renderCarName(carData) : null}
                  </div>
                </div>
              ) : null}
              {category ? (
                <div className="d-flex flex-column">
                  <h6 className="mb-0">{t('CATEGORY')}</h6>
                  <div className="mb-3 small">
                    {`${category?.category_name} > `}
                    {`${category?.subcategory_name}`}
                  </div>
                </div>
              ) : null}
              {part ? (
                <div className="d-flex flex-column">
                  <h6 className="mb-0">{t('CAR_PART')}</h6>
                  <div className="mb-3 small">
                    {renderPart()}
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label htmlFor="feedback-textarea">{t('MESSAGE')}</label>
                <textarea className="form-control" id="feedback-textarea" rows="4" value={message} onChange={e => setMessage(e.target.value)} />
              </div>
              <button
                type="button"
                disabled={!message.length}
                className="btn btn-feedback-send"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => sendFeedback()}
              >
                {
                  sendFeedbackLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>{t('SEND')}</span>
                  )
                }
              </button>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedBackButton
