import React, {useEffect, useState, lazy, Suspense, JSXElementConstructor} from 'react'
import {connect, useDispatch} from 'react-redux'
import ReactModal from 'react-modal'
import classNames from 'classnames'
import Spinner from 'components/Spinner'
import {hideModal} from '../../store/actions/modalsActions'
import './style.scss'

const AddToFavoriteModal = lazy(() => import('./Modals/AddToFavoriteModal'))
const DeleteFavoriteCategoryModal = lazy(() => import('./Modals/DeleteFavoriteCategoryModal'))
const CreateFavoriteCategoryModal = lazy(() => import('./Modals/CreateFavoriteCategoryModal'))
const RemoveItemModal = lazy(() => import('./Modals/RemoveItemModal'))
const DeleteAllPartsFromCategoryModal = lazy(() => import('./Modals/DeleteAllPartsFromCategoryModal'))
const DeleteAllPartsFromCartModal = lazy(() => import('./Modals/DeleteAllPartsFromCartModal'))
const DeleteAllPartsFromCartAmerigoModal = lazy(() => import('./Modals/DeleteAllPartsFromCartAmerigoModal'))
const ServicePlanModal = lazy(() => import('./Modals/ServicePlanModal'))
const MessageModal = lazy(() => import('./Modals/MessageModal'))
const MessageSupplierModal = lazy(() => import('./Modals/MessageSupplierModal'))
const TariffModal = lazy(() => import('./Modals/TariffModal'))
const TariffModalSupplier = lazy(() => import('./Modals/TariffModalSupplier'))

const UploadProformaModal = lazy(() => import('./Modals/UploadProformaModal'))
const UploadAmerigoProformaModal = lazy(() => import('./Modals/UploadAmerigoProformaModal'))
const PublicFormularModal = lazy(() => import('./Modals/PublicFormularModal'))
const FusesBoxDetailListModal = lazy(() => import('./Modals/FusesBoxDetailListModal'))
const VehicleRepairItemsInfoModal = lazy(() => import('./Modals/VehicleRepairItemsInfoModal'))
const TecCompPositionLinkedElementsModal = lazy(() => import('./Modals/TecCompPositionLinkedElementsModal'))
const DiagnosticsForTypeModal = lazy(() => import('./Modals/DiagnosticsForTypeModal'))
const CartMerigoForTypeModal = lazy(() => import('./Modals/CartMerigoForTypeModal'))
const LinkedElectronicModulesModal = lazy(() => import('./Modals/LinkedElectronicModulesModal'))
const WorkingTimesModal = lazy(() => import('./Modals/WorkingTimesModal'))
const GraphicsComponentInformationModal = lazy(() => import('./Modals/GraphicsComponentInformationModal'))
const ShippingModal = lazy(() => import('./Modals/ShippingModal'))
const OrderAmerigoModal = lazy(() => import('./Modals/OrderAmerigoModal'))

const AUR_MODALS: { [index: string]: JSXElementConstructor<any> } = {
  AddToFavoriteModal,
  DeleteFavoriteCategoryModal,
  CreateFavoriteCategoryModal,
  RemoveItemModal,
  DeleteAllPartsFromCategoryModal,
  DeleteAllPartsFromCartModal,
  ServicePlanModal,
  MessageModal,
  MessageSupplierModal,
  TariffModal,
  TariffModalSupplier,
  UploadProformaModal,
  UploadAmerigoProformaModal,
  FusesBoxDetailListModal,
  VehicleRepairItemsInfoModal,
  LinkedElectronicModulesModal,
  TecCompPositionLinkedElementsModal,
  DiagnosticsForTypeModal,
  WorkingTimesModal,
  GraphicsComponentInformationModal,
  ShippingModal,
  CartMerigoForTypeModal,
  OrderAmerigoModal,
  DeleteAllPartsFromCartAmerigoModal,
  PublicFormularModal,
}

interface IModalCloseModal {
  closeModal: () => void;
}

interface IModalRootProps {
  modalType: string;
  modalProps: {
    open: boolean;
    modalSmallWidth: string;
    modalFullWidth: string;
    modalMiddleWidth: string;
    shouldCloseOnOverlayClick:boolean
  };
}

const mapStateToProps = (state: {modal: JSXElementConstructor<IModalCloseModal>}) => ({
  ...state.modal,
})

const ModalRoot: React.FC<IModalRootProps> = (props: IModalRootProps): JSX.Element | null => {
  const dispatch = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [onOverlayClick, set0nOverlayClick] = useState<boolean>(true)

  useEffect(() => {
    window.onpopstate = () => {
      closeModal()
    }
  }, [])

  useEffect(() => {
    setModalIsOpen(props?.modalProps?.open)
  }, [props?.modalProps?.open])

  useEffect(() => {
    set0nOverlayClick(props?.modalProps?.shouldCloseOnOverlayClick)
  }, [props?.modalProps?.shouldCloseOnOverlayClick])

  const modalClasses = (): string => {
    return classNames({
      'modal-sm': props?.modalProps?.modalSmallWidth,
      'modal-full-width': props?.modalProps?.modalFullWidth,
      'modal-middle-width': props?.modalProps?.modalMiddleWidth,
    })
  }

  const closeModal = () => dispatch(hideModal())

  if (!props?.modalType) {
    return null
  }

  const SpecifiedModal: JSXElementConstructor<IModalCloseModal> = AUR_MODALS[props?.modalType]
  return (
    <div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal()}
        contentLabel="Automotor Modal"
        ariaHideApp={false}
        overlayClassName="modal fade show"
        bodyOpenClassName="modal-open"
        shouldCloseOnOverlayClick={onOverlayClick}
        className={`modal-dialog modal-dialog-centered ${modalClasses()}`}
        shouldCloseOnEsc={false}
      >
        <Suspense fallback={<Spinner class="small" />}>
          <SpecifiedModal
            closeModal={closeModal}
            {...props?.modalProps}
          />
        </Suspense>

      </ReactModal>
    </div>
  )
}

export default connect(mapStateToProps, null)(ModalRoot)
