import {ACTION_UPDATE_CATEGORIES, FETCH_UPDATE_CATEGORIES} from 'constants/actionsTypes'

export const initialState = {
  categories: null,
  loading: false,
}

export default function categoriesReducer(state = initialState, action = '') {
  switch (action.type) {
    case ACTION_UPDATE_CATEGORIES:
      return {
        ...state,
        loading: false,
        categories: action.categories,
      }
    case FETCH_UPDATE_CATEGORIES:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}
